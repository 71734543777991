export const Electric = [
    {
        id: 1,
        image: 'Rtron-1.png',
        title: 'R-Tron 620',
        subhead:'Climate Control',
        mainclimate:'We have a wide variety of climate control panels for poultry wards, green houses and Silos.For more products and inquiries please contact us',

    },
    {
        id: 2,
        image: 'TP80-1.png',
        title: 'TP - 80 ',
        subhead:'Climate Control',
        mainclimate:'We have a wide variety of climate control panels for poultry wards, green houses and Silos.For more products and inquiries please contact us',
    },
    {
        id: 3,
        image: 'TP54-1.png',
        title: ' TP - 54',
        subhead:'Climate Control',
        mainclimate:'We have a wide variety of climate control panels for poultry wards, green houses and Silos.For more products and inquiries please contact us',
    },
    {
        id: 4,
        image: 'Rtron612-1.png',
        title: 'R-Tron 612',
        subhead:'Climate Control',
        mainclimate:'We have a wide variety of climate control panels for poultry wards, green houses and Silos.For more products and inquiries please contact us',
    },
    {
        id:5,
        image:'Rtron313-1.png',
        title:'R-Tron 313',
        subhead:'Climate Control',
        mainclimate:'We have a wide variety of climate control panels for poultry wards, green houses and Silos.For more products and inquiries please contact us',
    },
    {
        id: 6,
        image: 'Chikatron20-1.png',
        title: 'Chickatron 20',
        subhead:'Climate Control',
        mainclimate:'We have a wide variety of climate control panels for poultry wards, green houses and Silos.For more products and inquiries please contact us',
    },
    {
        id: 7,
        image: 'Rtron207-1.png',
        title: 'R-Tron 207',
        subhead:'Climate Control',
        mainclimate:'We have a wide variety of climate control panels for poultry wards, green houses and Silos.For more products and inquiries please contact us',
    },
    {
        id: 8,
        image: 'Rtron-1.png',
        title: 'R-Tron',
        subhead:'Climate Control',
        mainclimate:'We have a wide variety of climate control panels for poultry wards, green houses and Silos.For more products and inquiries please contact us',
    },
    {
        id: 9,
        image: 'smartlink.png',
        title: 'Smart-Link2',
        subhead:'Smart Link',
        mainclimate:'By connecting intelligently and using a private app and connecting the control panel to the smart link, you can monitor and control all the advantages of the panel from anywhere.',
    },
    {
        id: 10,
        image: 'vaccine_temp.png',
        title: 'Vaccine Temp Monitor',
        subhead:'Temperature Monitor',
        mainclimate:'This Electric Equipment shows the level of Vaccine Temperature.',
    },
    
    {
        id: 11,
        image: 'temp_sensor.png',
        title: 'Temperature Sensor',
        subhead:'Sensors',
        mainclimate:'Temperature Sensor Calculate the temperature of Environment.',
    },
    {
        id: 12,
        image: 'humidity_sensor.png',
        title: 'Humidity Sensor',
        subhead:'Sensors',
        mainclimate:'This Humidity sensor calculate the concentration of water vapor present in the air.',
    },
    {
        id: 13,
        image: 'co2_sensor.png',
        title: 'CO2 Sensor',
        subhead:'Sensors',
        mainclimate:'This Sensor calculate the value of CO2 level in the air.',
    },
    {
        id: 14,
        image: 'amonia_sensor.png',
        title: 'Amonia Sensor',
        subhead:'Sensors',
        mainclimate:'This Sensor calculate the level of amonia present in the air',
    },
    {
        id: 15,
        image: 'silo_control.png',
        title: 'Silo Control',
        subhead:'Silo Control',
        mainclimate:'',
    },


]
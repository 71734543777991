export const Falcon_Arabic_Data =[
    {
        id:1,
        image: 'ViTonic.png',
        title: 'فالكون فيتونيك',
        subtitle:'ﻣﻜﻤﻞ ﻣﺘﻌﺪد اﻟﻔﻴﺘﺎﻣﻴﻨﺎت',
        maincontent:'يشار إلى هذا المنتج كمكمل متعدد الفيتامينات في حالة نقص الفيتامينات والإجهاد والنقل وتغير المناخ.',
        composition:'ﻛﻴﻠﻮﻏﺮام ١ ﻟﻜﻞ المﻜﻮʭت ',
        c1:'د.و ١٥٠٠٠٠٠ :أ ﻓﻴﺘﺎﻣين   :ب٥ ﻓﻴﺘﺎﻣين ، مجﻢ ١٤٠٠ :(رﻳﺒﻮﻓﻼﻓين) ٢ ب ﻓﻴﺘﺎﻣين مجﻢ، ٦٠٠ :(اﻟﺜﻴﺎﻣين)١ ب ﻓﻴﺘﺎﻣين',
        indication:'يشار إلى هذا المنتج كمكمل متعدد الفيتامينات في حالة نقص الفيتامينات والإجهاد والنقل وتغير المناخ.',
        dosage:'الجرعة المعتادة هي ملعقة واحدة (1 جرام) لكل 1 كجم من وزن الجسم مرة واحدة يوميًا حسب الحاجة',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'100 جرام'
    },
    {
        id:2,
        image: 'super_moult.png',
        title: 'فالكون سوبر مولت',
        subtitle:'تكملة دعم نمو الريش في موسم الريش',
        maincontent:'يشار إلى هذا المنتج كمكمل متعدد الفيتامينات والمعادن والأحماض الأمينية لتعزيز وتسريع عملية طرح الريش في الصقور.',
        composition:'ﻛﻴﻠﻮﻏﺮام ١ ﻟﻜﻞ المﻜﻮʭت ',
        c1:'فيتامين أ: 1,500,000 وحدة دولية، فيتامين ب1 (ثيامين): 800 ملغ، فيتامين ب2 (ريبوفلافين): 1500 ملغ، فيتامين ب3 (نياسيناميد): 11000 ملغ، فيتامين ب5: 3200 ملغ، فيتامين ب6: 800 ملغ، فيتامين ب12: 10 ملغ، فيتامين ج. :16000 مجم، فيتامين د3: 15000 وحدة دولية، فيتامين هـ: 12000 وحدة دولية، فيتامين ك3: 500 مجم، ليسين: 2 مجم، ميثيونين: 5 مجم، كلوريد الكولين: 3.5 مجم، زنك: 300 مجم، منجنيز: 90 مجم، نحاس: 60 مجم، يود: 90 مجم حديد: 1.5 ملغ، هيستيدين: 1.5 ملغ، بوتاسيوم: 20 ملغ، ليوسين: 2.5 ملغ، فالين: 1.6 ملغ، سيستين: 2 ملغ، أرجينين: 5 ملغ، آيزوليوسين: 1 ملغ، ثريونين: 2.5 ملغ: فينيل ألانين: 1.6 ملغ، تريبتوفان: 1.2 ملغ، حمض الفوليك: 400 ملغ، البيوتين: 50 ميكروغرام، كربونات الكالسيوم: 7 ملغ',
        indication:'يشار إلى هذا المنتج كمكمل متعدد الفيتامينات والمعادن والأحماض الأمينية لتعزيز وتسريع عملية طرح الريش في الصقور. إنه يعزز نمو الريش وصحته مما يؤدي إلى ريش أقوى وريش أفضل.',
        dosage:'الجرعة المعتادة هي ملعقة واحدة (1 جم) لكل 1 كجم من وزن الجسم مرة واحدة يوميًا خلال فترة طرح الريش.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'100 جرام'
    },
    {
        id:3,
        image: 'Rehydro.png',
        title: 'فالكون ريهيدرو',
        subtitle:'ملحق بالكهرباء لمنع الإرهاق والجفاف',
        maincontent:'يشار إلى هذا المنتج كمكمل إلكتروليت عن طريق الفم في حالة نقص الإلكتروليت والجفاف.', 
        composition:'تكوين لكل 1 كجم مسحوق:',
        c1:'كلوريد البوتاسيوم: 15 جم/كجم، كلوريد الصوديوم: 26 جم/كجم، حامض الستريك: 29 جم/كجم، لاكتات الكالسيوم: 10 جم/كجم، كبريتات المغنيسيوم: 7 جم/كجم، الجلوكوز: 135 جم/كجم',
        indication:'يشار إلى هذا المنتج كمكمل إلكتروليت عن طريق الفم في حالة نقص الإلكتروليت والجفاف. دعم الصقر أثناء التدريب والصيد في المناخ الحار والضغط والنقل.',
        dosage:'الجرعة المعتادة هي ملعقة واحدة (1 جرام) لكل 1 كجم من وزن الجسم مرة واحدة يوميًا حسب الحاجة',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'100 جرام'
    },
    { 
        id:4,
        image: 'Super_Aid_Plus.png',
        title: 'فالكون سوبر ايد بلس',
        subtitle:'يوفر جميع العناصر الغذائية أثناء علاج الصقور المريضة',
        maincontent:'يُشار إلى هذا المنتج باعتباره مكملاً للفيتامينات والمعادن والأحماض الأمينية لدعم الصقور في أوقات المرض وحتى علاج العناية المركزة.',
        composition:'تكوين لكل 1 كجم مسحوق:',
        c1:'فيتامين أ: 20000 وحدة دولية، فيتامين ب1: 1 ملغ، فيتامين ب2 (ريبوفلافين): 3 ملغ، فيتامين ب3 (نياسيناميد): 1 ملغ، فيتامين ب5: 2 ملغ، فيتامين ب6 (البيريدوكسين): 5 ملغ، فيتامين ب7 (البيوتين): 30 ميكروغرام، فيتامين ب9 (حمض الفوليك): 100 ميكروجرام، فيتامين ب12: 2 ميكروجرام، فيتامين ج: 15 ملجم، فيتامين د3: 20 وحدة دولية، فيتامين هـ: 10 وحدة دولية، فيتامين ك3: 0.1 ملجم، الألبومين: 2 جم، جلوكونات الكالسيوم: 60 ملجم، كلوريد الكولين: 10 ملجم، كبريتات المغنيسيوم: 50 ملغ، كلوريد البوتاسيوم: 80 ملغ، كلوريد الصوديوم: 115 ملغ، ليسين: 3 ملغ، ميثيونين: 2 ملغ، منغنيز: 500 ملغ: هيستيدين: 1 ملغ، ثريونين: 2 ملغ، ليوسين: 4 ملغ: تريبتوفان: 1 ملغ، نشاء الذرة (متحلل): 7 جم',
        indication:'يُشار إلى هذا المنتج باعتباره مكملاً للفيتامينات والمعادن والأحماض الأمينية لدعم الصقور في أوقات المرض وحتى علاج العناية المركزة. إنه يعزز التعافي بشكل أسرع من المرض عن طريق إضافة الطاقة وتعزيز التمثيل الغذائي. إنها تركيبة متوازنة للغاية لتوفير جميع المكملات الغذائية اللازمة للصقور حتى في أوقات التوتر',
        dosage:'الجرعة المعتادة هي ملعقة واحدة (1 جم) لكل 1 كجم من وزن الجسم مرة واحدة يوميًا حسب الحاجة.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'100 جرام'
    },
    {
        id:5,
        image: 'Super_Hunt.png',
        title: 'فالكون سوبر هانت',
        subtitle:'معزز للطاقة لأداء عالي',
        maincontent:'يشار إلى هذا المنتج باعتباره مكملاً للفيتامينات والمعادن والأحماض الأمينية لتعزيز وتسريع أداء الصيد للصقور',
        composition:'تكوين لكل 1L السائل:',
        c1:'ريبوز: 200 جرام/لتر، ل-كارنيتين: 300 جرام/لتر، فوسفات الكرياتين: 120 جرام/لتر، فيتامين ب1 (ثيامين): 800 ملجم، فيتامين ب5: 300 ملجم، فيتامين ب12: 10 ملجم، فيتامين ج: 200 ملجم، فيتامين هـ: 120 ملجم، كالسيوم. غلوكونات: 8 جم، أكسيد المغنيسيوم: 5 جم، ليسين: 4 مجم، زنك: 350 مجم، نحاس: 120 مجم، كوبالت: 80 مجم، آيزوليوسين: 3 مجم، ليوسين: 5 مجم، فالين: 4 مجم، أرجينين: 6 مجم، فينيل ألانين: 3 مجم، حمض الفوليك: 500 مجم',
        indication:'يشار إلى هذا المنتج باعتباره مكملاً للفيتامينات والمعادن والأحماض الأمينية لتعزيز وتسريع أداء الصيد للصقور. إنه يعزز اكتساب العضلات وقوتها مما يؤدي إلى تعزيز قوة العضلات وأداء الطيران المتميز والسرعة والقدرة على التحمل والتحمل.',
        dosage:'1 مل لكل كيلو جرام من وزن الجسم لمدة 3-5 أيام.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'زجاجة 100 مل'
    },
    {
        id:6,
        image: 'Super_Muscle_Plus.png',
        title: 'فالكون سوبر ماسل بلس',
        subtitle:'ملحق للسرعة والطاقة والطاقة',
        maincontent:'يشار إلى هذا المنتج باعتباره مكملاً للفيتامينات والمعادن والأحماض الأمينية لتعزيز وتسريع عملية التدريب لدى الصقور.',
        composition:'تكوين لكل 1L هلام:',
        c1:'ل-كارنتين: 150 جم/لتر، فوسفات الكرياتين: 250 جم/لتر، فيتامين ب1 (ثيامين): 800 مجم، فيتامين ب5: 300 مجم، فيتامين ب12: 10 مجم، فيتامين ج: 12.000 مجم، فيتامين هـ: 8.000 مجم، ليسين: 3 مجم، زنك. : 300 مجم، النحاس: 40 مجم، كوبالت: 50 مجم، آيزوليوسين: 2 مجم، ليوسين: 4 مجم، فالين: 3 مجم، أرجينين: 6 مجم، فينيل ألانين: 3 مجم، حمض الفوليك: 500 مجم',
        indication:'يشار إلى هذا المنتج باعتباره مكملاً للفيتامينات والمعادن والأحماض الأمينية لتعزيز وتسريع عملية التدريب لدى الصقور. إنه يبني نمو العضلات، ويزيد من اكتساب العضلات والأداء والقدرة على التحمل والقدرة على التحمل مما يؤدي إلى تعزيز قوة العضلات وأداء الطيران بأعلى درجة.',
        dosage:'مل لكل كيلو جرام من وزن الجسم لمدة 3-5 أيام.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'زجاجة 100 مل'
    },
    {
        id:7,
        image: 'Recovery_Plus.png',
        title: 'فالكون ريكفري بلس',
        subtitle:'ملحق يدعم الشفاء السريع من المرض والصدمات',
        maincontent:'يُشار إلى هذا المنتج باعتباره مكملاً للفيتامينات والمعادن والأحماض الأمينية لدعم عملية التعافي بعد المرض والمرض والصدمات التي تصيب الصقور.',
        composition:'ﻛﻴﻠﻮﻏﺮام ١ ﻟﻜﻞ المﻜﻮʭت',
        c1:'فيتامين أ: 1000000 وحدة دولية، فيتامين ب1 (ثيامين): 400 ملغ، فيتامين ب2 (ريبوفلافين): 1000 ملغ، فيتامين ب5: 3.2 ملغ، فيتامين ب12: 4 ملغ، فيتامين د3: 20000 وحدة دولية، فيتامين هـ: 5 ملغ، ليسين: 30 ملغ، ميثيونين: 8 ملغ، زنك: 500 ملغ',
        indication:'يُشار إلى هذا المنتج باعتباره مكملاً للفيتامينات والمعادن والأحماض الأمينية لدعم عملية التعافي بعد المرض والمرض والصدمات التي تصيب الصقور. يساعد في إعادة تأهيل الجهاز المناعي للصقر، ويزيل السموم ويقوي صحة الصقر بوتيرة سريعة.',
        dosage:'الجرعة المعتادة هي ملعقة واحدة (1 جم) لكل 1 كجم من وزن الجسم مرة واحدة يوميًا حسب الحاجة.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'100 جرام'
    },
    {
        id:8,
        image: 'Calci_Plus.png',
        title: 'فالكون كالسي بلس',
        subtitle:'مكملات الكالسيوم للصقور',
        maincontent:'يُشار إلى أن هذا المنتج يدعم تربية الصقور في أوقات زيادة تناول الكالسيوم المطلوب لتحسين قشر البيض.',
        composition:'تكوين كل لتر :',
        c1:'الكالسيوم (Ca++) في صورة جلوكونات الكالسيوم: 40.0 جم، فيتامين د3: 30.000 وحدة دولية، الفوسفور: 20.0 جم، المغنيسيوم (++Mg) في صورة كبريتات المغنيسيوم: 2.0 جم',
        indication:'يُشار إلى أن هذا المنتج يدعم تربية الصقور في أوقات زيادة تناول الكالسيوم المطلوب لتحسين قشر البيض. يدعم Falcon Calci Plus أيضًا صغار الصقور في نمو عظامهم من أجل تكوين هياكل عظمية أقوى وأكثر صحة وعضلات جيدة. علاوة على ذلك، يمكن استخدام هذا المنتج بشكل جيد جدًا كمكمل للصقور المصابة بالكسور لأنه يدعم تكوين مسامير القدم بشكل أسرع وأقوى.',
        dosage:'الجرعة المعتادة هي 1 مل لكل 1 كجم من وزن الجسم مرة واحدة يوميًا حسب الحاجة.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'زجاجة 100 مل'
    },
    {
        id:9,
        image: 'Vitonic_Plus.png',
        title: 'فالكون فيتونيك بلس',
        subtitle:'مكملات الفيتامينات',
        maincontent:'مكملات الفيتامينات المتعددة في حالة نقص الفيتامينات والإجهاد والنقل وتغيير البيئة',
        composition:'ﻛﻴﻠﻮﻏﺮام ١ ﻟﻜﻞ المﻜﻮʭت',
        c1:'فيتامين أ: 2000 وحدة دولية، فيتامين ب1: 2 ملغ، فيتامين ب2: 4 ملغ، فيتامين ب5: 5 ملغ، فيتامين ب6: 1 ملغ، فيتامين: ب12 0.1 ملغ، فيتامين ج: 100 ملغ، فيتامين د3: 70 وحدة دولية، فيتامين هـ: 300 وحدة دولية، فيتامين K: 20 ميكروجرام، النياسين: 8 ملجم، حمض الفوليك: 1 ملجم، البيوتين: 30 ملجم، اليود: 0.35 ملجم، كلوريد الكولين: 0.08 ملجم، إشنسا: 10 ملجم.',
        indication:'مكملات الفيتامينات المتعددة في حالة نقص الفيتامينات والإجهاد والنقل وتغيير البيئة',
        dosage:'الجرعة المعتادة هي قرص واحد لكل 1 كجم مرة واحدة يوميًا لمدة 5 أيام.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'30 حبة'
    },
    {
        id:10,
        image: 'Hemo_Plus.png',
        title: 'فالكون هيمو بلس',
        subtitle:'ملحق تعزيز الدم',
        maincontent:'مكملات تعزيز خلايا الدم الحمراء عندما تكون هناك حاجة إلى زيادة في خلايا الدم الحمراء كما هو الحال أثناء فترة التدريب والصيد. زيادة في وظيفة خلايا الدم الحمراء.',
        composition:'تركيبة كل قرص:',
        c1:'ليسين: 1.5 ملغ، زنك: 450 ميكروغرام، حديد: 390 ميكروغرام، نحاس: 72 ميكروغرام، فيتامين ب12: 0.5 ميكروغرام، حمض الفوليك: 0.01 ملغ، فيتامين: هـ 0.1 ميكروغرام، فيتامين سي: 1.5 ملغ',
        indication:'مكملات تعزيز خلايا الدم الحمراء عندما تكون هناك حاجة إلى زيادة في خلايا الدم الحمراء كما هو الحال أثناء فترة التدريب والصيد. تؤدي الزيادة في وظيفة خلايا الدم الحمراء إلى تعزيز إنتاج الأكسجين. وبالتالي، يمكن للصقر أن يحقق أداءً محسنًا خلال فترات الطيران الطويلة كما هو الحال في التدريب والصيد.',
        dosage:'قرص واحد لكل 1 كجم من وزن الجسم مرة واحدة يوميًا لمدة 5-7 أيام أو أكثر إذا لزم الأمر.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'50 حبة'
    },
    {
        id:11,
        image: 'Probiotic.png',
        title: 'فالكون بروبيوتيك',
        subtitle:'مسحوق مكمل للجهاز الهضمي للإعطاء عن طريق الفم',
        maincontent:'البروبيوتيك هي بكتيريا جيدة تدعم الجهاز الهضمي وصحة الأمعاء عن طريق التحكم في نمو البكتيريا الضارة.',
        composition:'تكوين في 1G:',
        c1:'200 مليون وحدة تشكيل مستعمرة لاكتوباكيللوس أسيدوفيلوس، المكورات المعوية البرازية، العقدية اللعابية.',
        indication:'البروبيوتيك هي بكتيريا جيدة تدعم الجهاز الهضمي وصحة الأمعاء عن طريق التحكم في نمو البكتيريا الضارة. تعمل البروبيوتيك على تجديد ميكروبيوم الأمعاء أثناء وبعد تناول المضادات الحيوية. الجرعة: 1 جرام لكل كجم من وزن الجسم يوميًا لمدة 5-7 أيام.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'30 جرام'
    },
    {
        id:12,
        image: 'Liver_Care.png',
        title: 'فالكون للعناية بالكبد',
        subtitle:'ملحق لدعم الكبد',
        maincontent:'وظائف الكبد. يستخدم السيليمارين كدعم فعال للكبد في اللاصق أثناء المرض. إشنسا هو منبه عشبي قوي منتج لهذا الغرض', 
        composition:'تركيبة كل قرص:',
        c1:'سيليمارين: 90 ملغ إشنسا: 30 ملغ',
        indication:'يحسن وظائف الكبد. يستخدم السيليمارين كدعم فعال للكبد في الصقور أثناء المرض. إشنسا هو منبه عشبي قوي يعزز جهاز المناعة',
        dosage:'1 قرص 2 مرات في الأسبوع',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'30 حبة'
    },
    {
        id:13,
        image: 'Wormnill.png',
        title: 'فالكون ورمنيل',
        subtitle:'لعلاج التهابات الطفيليات الداخلية',
        maincontent:'هذا المنتج عبارة عن مزيل عام للديدان للصقور ويستهدف أنواع العدوى الرئيسية بالطفيليات الداخلية',
        composition:'تركيبة كل قرص:',
        c1:'فنبندازول: 20 ملغ، برازيكوانتيل: 8 ملغ', 
        indication:'فينبندازول: 20 ملجم، هذا المنتج هو مبيد عام للصقور للديدان ويستهدف العدوى الطفيلية الرئيسية. يستخدم لعلاج مجموعة متنوعة من الطفيليات الداخلية، وخاصة الأنواع المدقعة، والسيستودا، والديدان الخيطية مثل Ascaridia sp. والشعيرات الدموية س. وهي الطفيليات الداخلية الأكثر شيوعًا في الطيور الجارحة وبالتالي بشكل رئيسي الصقريات. وتعتبر أحد الأسباب الرئيسية للمشاكل المعوية لدى الصقور. برازيكوانتيل: 8 ملغ',
        dosage:'قرص واحد لكل 1 كجم من وزن الجسم مرة واحدة، ويكرر مرة أخرى بعد 7 أيام.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'30 حبة'
    },
    {
        id:14,
        image: 'Wormnill_Plus.png',
        title: 'فالكون ورمنيل بلس',
        subtitle:'لعلاج الطفيليات الداخلية والكوكسيديا',
        maincontent:'هذا المنتج عبارة عن مزيل عام للديدان للصقور ويستهدف العدوى الطفيلية الرئيسية.',
        composition:'تركيبة كل قرص:',
        c1:'فنبندازول: 20 ملغ، ديكلازوريل: 10 ملغ، برازيكوانتيل: 8 ملغ',
        indication:'هذا المنتج عبارة عن مزيل عام للديدان للصقور ويستهدف العدوى الطفيلية الرئيسية. يستخدم لعلاج الطفيليات الداخلية، وخاصة الأنواع تريماتودا، سيستودا والديدان الخيطية مثل Ascaridia sp. والشعيرات الدموية س. كما أنه يستهدف حالات العدوى الطفيلية الداخلية المختلطة بالكوكسيديا، وهي الطفيليات الداخلية الأكثر شيوعًا في الطيور الجارحة وبالتالي بشكل رئيسي الصقريات.',
        dosage:'قرص واحد لكل 1 كجم/وزن الجسم مرة واحدة ويكرر مرة أخرى بعد 7 أيام". في حالة وجود حمل ثقيل من الأسكارديدا، قم بزيادة الجرعة إلى قرص واحد لكل 1 كجم/وزن الجسم لمدة 3 أيام.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'30 حبة'
    },
    {
        id:15,
        image: 'Coccikill.png',
        title: 'فالكون كوكسيكيل',
        subtitle:'لعلاج الكوكسيديا في الصقور',
        maincontent:'يستخدم هذا المنتج لعلاج طفيليات الكوكسيديا الطفيلية الأوالي، وخاصة الأنواع Caryospora sp.',
        composition:'تركيبة كل قرص:',
        c1:'ديكلازوريل: 5 ملغ، أمبروليوم هيدروكلوريد: 20 ملغ',
        indication:'يستخدم هذا المنتج لعلاج طفيليات الكوكسيديا الطفيلية الأوالي، وخاصة الأنواع Caryospora sp. تم وصف أكثر من 7 أنواع من Caryospora في الصقور.',
        dosage:'الجرعة المعتادة هي قرص واحد لكل 1 كجم من وزن الجسم مرة واحدة يوميًا لمدة 3 أيام متتالية.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'30 حبة'
    },
    {
        id:16,
        image: 'Trichonill_Plus.png',
        title: 'فالكون تريكونيل بلس',
        subtitle:'لعلاج الالتهابات الطفيلية',
        maincontent:'هذا المنتج هو دواء مضاد للأوالي للصقور ويستهدف الالتهابات الأولية المتقدمة مثل الالتهابات الأولية المعوية وداء الجيارديات.',
        composition:'تركيبة كل قرص:',
        c1:'ميترونيدازول: 50 ملغ، سيكنيدازول: 25 ملغ',
        indication:'هذا المنتج هو دواء مضاد للأوالي للصقور ويستهدف الالتهابات الأولية المتقدمة مثل الالتهابات الأولية المعوية وداء الجيارديات. كما أنه يدعم علاج داء المشعرات، وهو كائن أولي سوطي يؤدي إلى التهابات متكررة في محصول الصقور.',
        dosage:'قرص واحد لكل 1 كجم من وزن الجسم لمدة 3-5 أيام. في حالة آفات المشعرة الكبيرة تكون الجرعة 2 قرص لكل 1 كجم من وزن الجسم لمدة 5-7 أيام.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'30 حبة'
    },
    {
        id:17,
        image: 'Moxictin_Super.png',
        title: 'فالكون موكسيكتين سوبر',
        subtitle:'لعلاج الطفيليات الداخلية والخارجية',
        maincontent:'مكافحة الطفيليات الخارجية والداخلية في صقور الصيد بما في ذلك الديدان الرئوية (serratospiculum)',
        composition:'تركيبة كل قرص:',
        c1:'موكسيدكتين: 1000 ميكروغرام',
        indication:'مكافحة الطفيليات الخارجية والداخلية في صقور الصيد بما في ذلك الديدان الرئوية (serratospiculum)',
        dosage:'قرص واحد لكل كيلو جرام من وزن الجسم ويكرر بعد 7 أيام.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'30 حبة'
    },

    // insecta plus

    {
        id:18,
        image: 'Insecta_Plus.png',
        title: 'فالكون إنسيكتا بلس',
        subtitle:'مكافحة القراد والعث والحشرات في الصقور',
        maincontent:'لمكافحة القراد والعث والقمل والبعوض وغيرها من الحشرات القارضة في الصقور وفي أقفاصها.',
        composition:'تكوين كل لتر:',
        c1:'بيبيرونيل بوتوكسيد: 6 جرام، بيرميثرين: 1.3 جرام، مواد حافظة، مستحلب',
        indication:'لمكافحة القراد والعث والقمل والبعوض وغيرها من الحشرات القارضة في الصقور وفي أقفاصها.',
        dosage:'رش الصقر الذي يحمل الجسم على بعد 25-30 سم. رش مرة واحدة تحت الأجنحة أيضًا.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'رذاذ 100 مل'
    },
    {
        id:19,
        image: 'Falcon_3_in_One.png',
        title: 'فالكون 3 في واحد',
        subtitle:'لعلاج الالتهابات البكتيرية والأوالي',
        maincontent:'يشار إلى هذا المنتج كمضاد حيوي واسع النطاق مفضل للعدوى البكتيرية سالبة الجرام وإيجابية الجرام في الطيور الجارحة ',
        composition:'تركيبة كل قرص:',
        c1:'ماربوفلوكساسين: 10 ملغ، ميترونيدازول: 30 ملغ، ديكلازوريل: 5 ملغ.',
        indication:'يشار إلى هذا المنتج كمضاد حيوي واسع النطاق مفضل لعلاج الالتهابات البكتيرية سالبة الجرام وإيجابية الجرام في الطيور الجارحة والصقور وداء المشعرات والكوكسيديا.',
        dosage:'قرص واحد لكل 1 كجم من وزن الجسم مرة واحدة يوميًا لمدة 5-7 أيام.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'30 حبة'
    },
    {
        id:20,
        image: 'Trichonill.png',
        title: 'فالكون تريكونيل',
        subtitle:'لعلاج داء المشعرات',
        maincontent:'هذا المنتج هو دواء مضاد للأوالي للصقور ويستهدف العدوى بالأوالي مثل المشعرة والجيارديا.',
        composition:'تركيبة كل قرص:',
        c1:'ميترونيدازول: 100 ملغ',
        indication:'هذا المنتج هو دواء مضاد للأوالي للصقور ويستهدف العدوى بالأوالي مثل المشعرة والجيارديا. تعد المشعرة أو ما يسمى بـ "founce" شائعة جدًا في الصقور حيث تنتقل الأوليات من الحمام إلى الصقور. كما يدعم الميترونيدازول علاج التهاب الزوائد البكتيرية القوية في الصقور بالاشتراك مع المضادات الحيوية المناسبة.',
        dosage:' قرص واحد لكل 1 كجم / وزن الجسم لمدة 3-5 أيام و5-7 أيام كدعم لالتهاب النزلة الجرثومية والتهاب الأمعاء.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'30 حبة'
    },
    {
        id:21,
        image: 'Asper_Plus.png',
        title: 'فالكون اسبر بلس',
        subtitle:'لعلاج داء الرشاشيات',
        maincontent:'هذا المنتج هو عامل مضاد للفطريات لداء الرشاشيات في الصقور. تم تعزيز هذا المنتج بمستخلص السيليمارين كدعم قوي للكبد بالإضافة إلى إشنسا كمعزز لجهاز المناعة.',
        composition:'ﻛﻴﻠﻮﻏﺮام ١ ﻟﻜﻞ المﻜﻮʭت',
        c1:'فوريكونازول: 12.5 ملغ، سيليمارين: 70 ملغ، إشنسا: 20 ملغ',
        indication:'هذا المنتج هو عامل مضاد للفطريات لداء الرشاشيات في الصقور. تم تعزيز هذا المنتج بمستخلص السيليمارين كدعم قوي للكبد بالإضافة إلى نبات إشنسا كمقوي لجهاز المناعة.',
        dosage:'الجرعة المعتادة هي قرص واحد لكل 1 كجم من وزن الجسم مرة أو مرتين يوميًا لمدة 2-6 أسابيع.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'50 حبة'
    },
    {
        id:22,
        image: 'Cefta.png',
        title: 'فالكون سيفتا',
        subtitle:'لعلاج الالتهابات البكتيرية في الصقور',
        maincontent:'يشار إلى هذا المنتج لعلاج داء الزائفة كمضاد حيوي مفضل، والتهابات المفاصل، والجهاز التنفسي السفلي، والتهابات الجلد، ومجرى الدم.',
        composition:'تركيبة كل قرص:',
        c1:'سيفتازيديم: 100 ملغ',
        indication:'يُوصف هذا المنتج لعلاج داء الزائفة كمضاد حيوي مفضل، والتهابات المفاصل، والتهابات الجهاز التنفسي السفلي، والتهابات الجلد، والتهابات مجرى الدم والبطن التي تسببها سلالات حساسة مثل الأمعائية، والإشريكية القولونية، والكليبسيلا، والمتقلبة، والزائفة، والمكورات العقدية مثل العقدية. الرئوية، والمكورات العقدية المقيحة والباكتيرويدس.',
        dosage:'الجرعة المعتادة هي قرص واحد لكل 1 كجم من وزن الجسم مرة واحدة يوميًا لمدة 7 أيام أو أكثر إذا لزم الأمر',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف.',
        packing:'30 حبة'
    },
    {
        id:23,
        image: 'PIPRa.png',
        title: 'فالكون بيبرا',
        subtitle:'لعلاج الالتهابات البكتيرية',
        maincontent:'علاج الالتهابات الخطيرة على سبيل المثال. تسمم الدم، والالتهاب الرئوي، والتهابات الجهاز التنفسي السفلي، والتهابات الكلى',
        composition:'تركيبة كل قرص:',
        c1:'بيبيراسيلين: 100 ملغ',
        indication:'علاج الالتهابات الخطيرة على سبيل المثال. تسمم الدم، الالتهاب الرئوي، التهابات الجهاز التنفسي السفلي، التهابات الكلى، التهابات الجلد والأنسجة الرخوة الناجمة عن السلالات الحساسة مثل: ',
        dosage:'قرص واحد لكل 1 كجم من وزن الجسم يوميًا لمدة 7 أيام أو أكثر إذا لزم الأمر.',
        storage:'يحفظ في درجة حرارة أقل من 28 درجة مئوية في مكان بارد وجاف',
        packing:'30 حبة'
    },

]
import { Link } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

function SubNavbar() {
  const [isNavVisible, setNavVisibility] = useState(false);

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  // Function to close the navigation menu
  const closeNav = () => {
    setNavVisibility(false);
  };
  // useeffect for screen change
  useEffect(() => {
    const handleResize = () => {
      const breakpoint = 768;
      if (window.innerWidth >= breakpoint) {
        closeNav();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isActive, setIsActive] = useState(false);
  const toggleRef = useRef(null);
  const barRef = useRef(null);

  const handleToggleClick = () => {
    setIsActive(!isActive);
  };

  const handleDocumentClick = (e) => {
    if (
      isActive &&
      toggleRef.current &&
      !toggleRef.current.contains(e.target)
    ) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isActive]);

  const navigate = useNavigate();
  const navigateToContact = () => {
    navigate("/");
    setTimeout(() => {
      const contactSection = document.getElementById("contact");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay for smoother scroll
  }

  const navigateToAbout = () => {
    navigate("/");
    setTimeout(() => {
      const aboutSection = document.getElementById("about");
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay for smoother scroll
  }

  const navigateToProducts = () => {
    navigate("/");
    setTimeout(() => {
      const productsSection = document.getElementById("product");
      if (productsSection) {
        productsSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay for smoother scroll
  }
  const navigateToPartners = () => {
    navigate("/");
    setTimeout(() => {
      const partnersSection = document.getElementById("partners");
      if (partnersSection) {
        partnersSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay for smoother scroll
  }

  return (
    <div class="top-0 left-0 right-0 ">
      <nav class="">
        {/* <div class="pl-2">
          <a href="/" class="">
            <img
              src="https://ik.imagekit.io/4tk3lgwqnz/Pics/Pics/main_logo.png?updatedAt=1686494885218"
              alt=""
              class="lg:w-[190px] xl:w-[275px] md:w-[220px] w-[180px] xl:h-[96px]"
            />
          </a>
        </div>
        <div class=" mt-5  hidden lg:block">
          <ul class="xl:text-[14px] lg:text-[13px] font-normal list-style-none flex flex-col pl-0 lg:flex-row text-[#263238]">
            <li class="my-1 lg:my-0 lg:pr-2 hover:text-[#E9A655] ml-5">
              <a class=" lg:px-2 " href="/">
                Home
              </a>
            </li>
            <li class="my-1 lg:my-0 lg:pr-2 hover:text-[#E9A655] ml-5">
              <a class=" lg:px-2 cursor-pointer" onClick={navigateToProducts}>
                Products
              </a>
            </li>
            <li class="my-1 lg:my-0 lg:pr-2 hover:text-[#E9A655] ml-5">
              <a class=" lg:px-2 cursor-pointer" onClick={navigateToPartners}>
                Partners
              </a>
            </li>
            <li class="my-1 lg:my-0 lg:pr-2 hover:text-[#E9A655] ml-5">
              <a class=" lg:px-2 cursor-pointer" onClick={navigateToAbout}>
                About
              </a>
            </li>
            <li class="my-1 lg:my-0 lg:pr-2 hover:text-[#E9A655] ml-5">
              <a class=" lg:px-2 cursor-pointer" onClick={navigateToContact}>
                Contact
              </a>
            </li>
          </ul>
        </div> */}
        <div className="xl:ml-auto mt-5 lg:mt-[60px] hidden lg:block absolute lg:right-[153px] xl:right-[155px] z-50">
          <div className="flex justify-between lg:h-[25px] lg:w-[250px] w-[300px] h-[35px] cursor-pointer">
            <a href="https://www.facebook.com" target="_blank"><img src="../assets/facebook.png" alt="" className="h-7" /></a>
            <a href="https://instagram.com/mevs.gcc?igshid=MzRlODBiNWFlZA==" target="_blank">   <img src="../assets/instagram2.png" alt="" className="h-7" /></a>
            <img src="../assets/google.png" alt="" className="h-7" />           
              <Link to={"/arabic"}>
              <div className="flex h-8 items-center">
                <img src="../assets/lang.png" alt="" className="h-7" />
                <p className="pl-2 pt1 text-[16px] font-[600] text-[#1A1A1A]">AR</p>
              </div>
              </Link>
          </div>
        </div>
        {/* <div
          ref={toggleRef}
          id="toggle"
          className={`toggle ${isActive ? "active" : ""}`}
          onClick={handleToggleClick}
        >
          <button
            class="block bg-fixed top-[20px] right-[10px] absolute border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
            type="button"
            data-te-collapse-init
            data-te-target="#navbarSupportedContent3"
            aria-controls="navbarSupportedContent3"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="[&>svg]:w-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="h-7 w-7"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </button>
        </div> */}
      </nav>
      {/* <div
        id="bar"
        className={`bar ${isActive ? "active" : ""} lg:hidden`}
        ref={barRef}
      >
        <ul class="md:pl-[40px] pl-[30px]">
          <li class="my-2">
            <a href="/">Home</a>
          </li>
          <li class="my-2">
            <a href="#about">About</a>
          </li>
          <li class="my-2">
            <a href="#partners">Our Partners</a>
          </li>
          <li class="my-2">
            <a href="#products">Our Products</a>
          </li>
          <li class="my-2">
            <a href="#contact">Contact Us</a>
          </li>
        </ul>
        <div className="flex justify-between ml-8 h-[20px] w-[120px] md:ml-10 md:w-[200px] md:h-[25px]">
            <img src="./assets/facebook.png" alt="" />
            <img src="./assets/twitter.png" alt="" />
            <img src="./assets/google.png" alt="" />
            <img src="./assets/lang.png" className="" alt="" />
          </div>
        </div> */}
    </div>
  );
}
export default SubNavbar;

import React from 'react';
import { BrowserRouter, Route, Routes, } from 'react-router-dom';
import Home from './components/home/Home';
import Disinfectant_product from './components/pages/disinfectants';
import Electric_product from './components/pages/electric_equipment';
import Equine_product from './components/pages/equine';
import FalconProduct from './components/pages/falcon';
import LargeAnimal from './components/pages/large_animal';
import Poultry from './components/pages/poultry';
import ProductScreen from './components/product/[slug]';
import Footer from './components/footer/Footer';
import Contact from './components/contact/Contact';
import Navbar from './components/navbar/Navbar';
import Gototop from './components/gototop';
import Arabic from './components/arabic/Arabic';
import Falcon_Arabic from './components/pages_arabic/falcon_arabic/Falcon_Arabic';
import Poultry_Arabic from './components/pages_arabic/poultry_arabic/Poultry_Arabic';
import Large_Arabic from './components/pages_arabic/large_arabic/Large_Arabic';
import Equine_Arabic from './components/pages_arabic/equine_arabic/Equine_Arabic';
import Dis_Arabic from './components/pages_arabic/dis_arabic/Dis_Arabic';
import Electric_Arabic from './components/pages_arabic/electric_arabic/Electric_Arabic';
import All from './components/pages/all/All';
import All_Arabic from './components/pages_arabic/all_arabic/All_Arabic';
function App() {
  return (
    <BrowserRouter>
    <Gototop/>
    
          <Routes>
          <Route path='/' Component={Home}/>
          <Route path='/disinfectants' Component={Disinfectant_product}/>
          <Route path='/electric_equipment' Component={Electric_product}/>
          <Route path='/equine' Component={Equine_product}/>
          <Route path='/falcon' Component={FalconProduct}/>
          <Route path='/large_animal' Component={LargeAnimal}/>
          <Route path='/poultry' Component={Poultry}/>
          <Route path="/product/:slug" Component={ProductScreen} />
          <Route path='/arabic' Component={Arabic}/>
          <Route path='/falcon_arabic' Component={Falcon_Arabic}/>
          <Route path='poultry_arabic' Component={Poultry_Arabic}/>
          <Route path='large_arabic' Component={Large_Arabic}/>
          <Route path='equine_arabic' Component={Equine_Arabic}/>
          <Route path='dis_arabic' Component={Dis_Arabic}/>
          <Route path='electric_arabic' Component={Electric_Arabic}/>
          <Route path='all' Component={All}/>
          <Route path='all_arabic' Component={All_Arabic}/>
          {/* <Route path="/contact" Component={Contact}/> */}
          </Routes>
          {/* <Footer/> */}
    </BrowserRouter>

  )
}

export default App
export const Poultry_Arabic_Data = [
    {
        id:1,
        slug:'aa1',
        title:'APSA POLIVIT AD3EKC',
        image:'APSA Polivit.png',
        maincontent:'مكمل غذائي يحتوي على فيتامينات قابلة للذوبان في الدهون ، يشار إليها للتعويض عن أوجه القصور الناتجة عن متلازمة سوء الامتصاص وفي الحالات الشديدة من الكوكسيديا.',
        h1:'مؤشرات:',
        c1:'مكمل غذائي يحتوي على فيتامينات قابلة للذوبان في الدهون ، يشار إليها للتعويض عن أوجه القصور الناتجة عن متلازمة سوء الامتصاص وفي الحالات الشديدة من الكوكسيديا.',
        h2:'الأنواع المستهدفة والجرعة: ',
        c2:[
            {
                h1:'الدواجن ، باستثناء الأوز والحمام:',
                l1:'60 مل / 1000 لتر من مياه الشرب خلال الأسبوعين الأولين من الحياة.',
                h2:'',
                l2:'',
                h3:'',
                l3:'',
                h4:'',
                l4:'',
            }
        ],
        h3:'الشكل والتعبئة والتغليف:',
        c3:' سائل. 1 لتر',
        b1:'فوائد:',
        b2:[
            {
                l1:'',
                l1:'',
                l1:'',
                l1:'',
                l1:'',
                l1:'',
            }
        ],

    },
    {
        id:2,
        slug:'aa1',
        title:'APSA POLIVITESEL',
        image:'APSA Polivitsel.png',
        maincontent:'المكملات الغذائية القائمة على فيتامين E والسيلينيوم ، المشار إليها لتحسين نوعية اللحوم ، وتجنب التهاب الدماغ وأمراض العضلات البيضاء وتحسين الخصوبة لدى المربين.',
        h1:'مؤشرات:',
        c1:'المكملات الغذائية القائمة على فيتامين E والسيلينيوم ، المشار إليها لتحسين نوعية اللحوم ، وتجنب التهاب الدماغ وأمراض العضلات البيضاء وتحسين الخصوبة لدى المربين.',
        h2:'الأنواع المستهدفة والجرعة:  ',
        c2:[
            {
                h1:'تربية الدواجن:',
                l1:'1 مل / لتر من مياه الشرب لمدة 3 - 5 أيام. ',
                h2:'خنانيص:',
                l2:' 1 مل / لتر من مياه الشرب لمدة 5 أيام.',
                h3:'تربية الخنازير والخنازير للتسمين: ',
                l3:'0.5 مل / لتر من مياه الشرب لمدة 5 - 7 أيام. ',
                h4:' الماشية للتسمين:',
                l4:'مل / لتر من مياه الشرب لمدة 5 أيام.',
                h5:'الأغنام / الماعز:',
                l5:' 1 مل / لتر من مياه الشرب لمدة 5 أيام.'
            }
        ],
        h3:'الشكل والتعبئة والتغليف:',
        c3:' سائل. 1 لتر',
        b1:'فوائد:',
        b2:[
            {
                l1:'',
                l1:'',
                l1:'',
                l1:'',
                l1:'',
                l1:'',
            }
        ],
    },
    {
        id:3,
        slug:'aa1',
        title:'APSA B COMPLEX + K3 + CHOLINE',
        image:'APSA B.png',
        maincontent:'مكمل غذائي يعتمد على فيتامينات المجموعة B و K3 والكولين ، المشار إليه لاستعادة وظائف الكبد في حالة التأثير الخطير.',
        h1:'مؤشرات:',
        c1:'مكمل غذائي يعتمد على فيتامينات المجموعة B و K3 والكولين ، المشار إليه لاستعادة وظائف الكبد في حالة التأثير الخطير.',
        h2:'الأنواع المستهدفة والجرعة:  ',
        c2:[
            {
                h1:' تربية الدواجن والخنازير والأرانب:',
                l1:'1 مل / لتر من مياه الشرب لمدة 5 - 7 أيام. ',
                h2:'الخنازير ووضع الدواجن:',
                l2:' 2 مل / لتر من مياه الشرب لمدة 5 - 7 أيام. ',
                h3:'المجترات:',
                l3:'1 - 2 مل / لتر من مياه الشرب لمدة 5 - 7 أيام ',
                h4:' ',
                l4:'',
                h5:'',
                l5:''
            }
        ],
        h3:'الشكل والتعبئة والتغليف:',
        c3:' سائل. 1 لتر',
        b1:'فوائد:',
        b2:[
            {
                l1:'',
                l1:'',
                l1:'',
                l1:'',
                l1:'',
                l1:'',
            }
        ],
    },
    {
        id:4,
        slug:'a1',
        title:'AminoFIX',
        image:'Aminofix.png',
        maincontent:'يساعد الطبيب البيطري AminoFIX في تكوين البروتين. وهو بمثابة عامل مانع للبناء لتطوير إنتاج اللحوم. كما أنها تستخدم لزيادة إنتاج البيض والحليب. ',
        h1:'',
        c1:'يساعد الطبيب البيطري AminoFIX في تكوين البروتين. وهو بمثابة عامل مانع للبناء لتطوير إنتاج اللحوم. كما أنها تستخدم لزيادة إنتاج البيض والحليب. دعم العلاج في العملية وبعد العملية ، النقاهة ، الجفاف ، الضعف ، القيء ، الإسهال ، عدم توازن الشوارد ، الكيتوزيه ، الحساسية المفرطة ، الحماض ونقص بروتين الدم',
        h2:'الجرعة والإعطاء',
        c2:[
            {
                h1:'دواجن:',
                l1:'أضف 1 مل لكل 1 لتر ماء يستمر لمدة 3-5 أيام. للماشية والجاموس والحصان والماعز والأغنام والكلاب والحيوانات الأخرى',
                h2:'الجرعة العادية :',
                l2:'2-3 مل لكل 10 كجم من وزن الجسم في مياه الشرب. ',
                h3:'جرعة تسمين اللحم البقري : ',
                l3:'4-6 مل لكل 10 كجم من وزن الجسم في مياه الشرب',
                h4:'جرعة زيادة الحليب : ',
                l4:'4-6 مل لكل 10 كجم من وزن الجسم في مياه الشرب. أو كما ينصح الطبيب البيطري المسجل',
                h5:'',
                l5:'',
            }
        ],
        h3:'تقديم:',
        c3:'1 لتر',
        b1:'فوائد:',
        b2:[
            {
                l1:'',
                l1:'',
                l1:'',
                l1:'',
                l1:'',
                l1:'',
            }
        ],
    },
    {
        id:5,
        slug:'a1',
        title:'Cal-D-Phos',
        image:'Cal D.png',
        maincontent:'تحضير الكالسيوم السائل والفوسفور لتحسين صحة الحيوان وإنتاجيته',
        h1:'',
        c1:'',
        h2:'الجرعة : المواشي',
        c2:[
            {
                h1:'الأبقار والجاموس / الخيول :',
                l1:'100 مل / يوم / 7-10 أيام في الشهر.',
                h2:'عجول: ',
                l2:'40 مل / يوم / 7 أيام في الشهر.',
                h3:'الكلاب : ',
                l3:'5 مل / يوم.',
                h4:'الأغنام والماعز :',
                l4:'10 مل / يوم – 7 أيام في الشهر. (أو) كما نصح الطبيب البيطري',
                h5:'تقديم:',
                l5:'500 مل ، 1 لتر. عبوات بلاستيكية',
            }
        ],
        b1:'فوائد:',
        b2:[
            {
                l1:'· يحسن إنتاج الحليب إلى أقصى حد ممكن.',
                l2:'· يساعد على تحسين قوة العظام والهيكل العظمي.',
                l3:'· يمنع نقص كلس الدم وحمى الحليب.',
                l4:'· يمنع المضاعفات مثل عسر الولادة ، احتباس المشيمة ، التهاب الميت ، التهاب الضرع ، إلخ.',
                l5:'· يزيد من نمو وأوزان الجسم من العجول.',
                l6:'· يحسن الصحة والحيوية والشهية وقوة العظام ونمو الكلاب',
            }

        ],
        h3:'',
        c3:''
    }
]
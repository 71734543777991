export const Disinfectant_Arabic_Data = [
    {
        id: 1,
        image: 'Deskol.png',
        title: 'ديسكول-ES',
        subtitle: 'الجيل الجديد من العلاج الكيميائي - المعقم الغازي',
        subtitle2: '',
        c: [
            {
                l1: '· DISKOL-ES هو معقم كيميائي غازي ذو فعالية مثبتة ضد جميع أنواع الكائنات الحية الدقيقة بما في ذلك الجراثيم البكتيرية والفيروسات والفطريات والكلاميديا والخميرة والعفن.',
                l2: '· لديها نظام تآزر ثلاثي فريد من نوعه يجعل المنتج أكثر قوة ويقلل من وقت الاتصال.',
                l3: '· لها فعالية لا مثيل لها حتى في وجود المواد العضوية والظروف المعاكسة للمياه مثل الصلابة ودرجة الحموضة وأيونات المعادن الذائبة.',
                l4: '·  DISKOL-ES مدعم بالجيل الرابع من الكوات (DDAC) ويتم ضبط درجة الحموضة للمنتج للحفاظ على فعالية جميع المكونات',
                l5: '',
            }
        ]

    },
    {
        id: 2,
        image: 'VOX.png',
        title: 'V-OX',
        subtitle: 'المطهر النهائي',
        subtitle2: 'مطهر مبيد للفيروسات ومبيد حيوي في نهاية المطاف ',
        c: [
            {
                l1: '· V-OX هو المطهر النهائي واسع الطيف.',
                l2: '· وهو مزيج من الملح الثلاثي من كبريتات البوتاسيوم أحادية (KMPS) وكلوريد الصوديوم والسطحي والأحماض العضوية والمخازن المؤقتة غير العضوية.',
                l3: '· يوصى باستخدام V-OX لمجموعة واسعة من التطبيقات مثل تطهير مزارع الماشية وإزالة التلوث السطحي والصرف الصحي للمياه.',
                l4: '· يقاوم V-OX أحمال التلوث العضوية الثقيلة وصلابة المياه العالية. ...',
                L5: '· يطلق محلول مائي من V-OX بطارية من 6 مبيدات حيوية تدمر مسببات الأمراض الفطرية والبكتيرية والفيروسية.'
            }
        ]
    }



]
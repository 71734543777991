export const Equine = [
    {
        id: 1,
        image: 'NostrilVet.png',
        title: 'Nostrilvet',
        mainc1: 'Nostrilvet is a powder nasal spray designed to help protect the upper airway of a horse from irritating aeroallergens.',
        h: 'Horse Breath Matter',
        h1:'Inside more will be the photo of the product Nostrilvet when inter it will show the info Below:',
        c1: [
            {
                l1: '· Nostrilvet is a powder nasal spray designed to help protect the upper airway of a horse from irritating aeroallergens.',
                l2: '· The unique grade of cellulose used in Nostrilvet provides a protective barrier in the upper respiratory system, helping to prevent the interaction of inhaled allergens with receptors, thereby reducing the allergic response.',
                l3: '· The bottle comes with a specially designed applicator which screws to the top of the bottle and helps the powder be distributed around the horses nasal tract',
            }
        ],
        h2:'Nostrilvet identity:',
        c2:[
            {
                l1:'· Worldwide brand name: Nostrilvet',
                l2:'· Regulatory classification: Non veterinary pharmaceutica',
                l3:'· Form: Either one 4000mg bottle one month supply or one 2000mg bottle two weeks supply.',
                l4:'· Composition: Uniquegrade of cellulose powder.',
                l5:'· Drug status: Doping test free.'
            }
        ],
        h3:'Nostrilvet indications:',
        c3:[
            {
                l1:'Nostrilvet protects the upper respiratory system',
                l2:'Nostrilvet can reduce coughing due to dust induced inflammation',
                l3:'Nostrilvet can help to reduce the impact of Inflammatory Airway Disease (IAD) by reducing allergen exposure',
                l4:'Nostrilvet can reduce head shaking due to allergen irritation',
            }
        ]
    },

]
export const Large_Arabic_Data = [
    {
        id: 1,
        slug: 'aa1',
        image: 'asap.png',
        title: 'APSA POLIVITESEL',
        berife: 'المكملات الغذائية القائمة على فيتامين E والسيلينيوم ، المشار إليها لتحسين نوعية اللحوم ، وتجنب التهاب الدماغ وأمراض العضلات البيضاء وتحسين الخصوبة لدى المربين.',
        viewmore: 'View Products Details',
        c1: 'تحضير الكالسيوم السائل والفوسفور لتحسين صحة الحيوان وإنتاجيته',
        h2: 'مؤشرات:',
        c2: [
            {
                l1: 'يحسن إنتاج الحليب إلى أقصى حد ممكن.'
            }
        ],
        h3:'الأنواع المستهدفة والجرعة:',
        subhead:'',
        c3:[
            {
                h1:' تربية الدواجن:',
                l1:' 1 مل / لتر من مياه الشرب لمدة 3 - 5 أيام.',
                h2:' خنانيص: ',
                l2:'1 مل / لتر من مياه الشرب لمدة 5 أيام.',
                h3:'تربية الخنازير والخنازير للتسمين:',
                l3:'0.5 مل / لتر من مياه الشرب لمدة 5 - 7 أيام. ',
                h4:'الماشية للتسمين:',
                l4:'0.1 مل / لتر من مياه الشرب لمدة 5 أيام.',
                h5:' الأغنام / الماعز:',
                l5:'1 مل / لتر من مياه الشرب لمدة 5 أيام.',
                h6:'',
                l6:''
            }
        ],
        h4:'الشكل والتعبئة والتغليف:',
        c4:'سائل. 1 لتر.'
    },
    {
        id: 2,
        slug: 'aa2',
        image: 'asap_b.png',
        title: 'APSA B COMPLEX + K3 + CHOLINE',
        berife: 'مكمل غذائي يعتمد على فيتامينات المجموعة B و K3 والكولين ، المشار إليه لاستعادة وظائف الكبد في حالة التأثير الخطير.',
        viewmore: 'View Products Details',
        c1: 'مركز سائل مزيج فريد من المكونات العشبية للاستخدام في الماشية والدواجن ...',
        h2: 'مؤشرات:',
        c2: [
            {
                l1: 'مكمل غذائي يعتمد على فيتامينات المجموعة B و K3 والكولين ، المشار إليه لاستعادة وظائف الكبد في حالة التأثير الخطير.'
            }
        ],
        h3:'الأنواع المستهدفة والجرعة :',
        subhead:'',
        c3:[
            {
                h1:'تربية الدواجن والخنازير والأرانب: ',
                l1:'1 مل / لتر من مياه الشرب لمدة 5 - 7 أيام. ',
                h2:'الخنازير ووضع الدواجن: ',
                l2:' 2 مل / لتر من مياه الشرب لمدة 5 - 7 أيام.',
                h3:' المجترات: ',
                l3:'1 - 2 مل / لتر من مياه الشرب لمدة 5 - 7 أيام',
                h4:'',
                l4:'',
                h5:'',
                l5:'',
                h6:'',
                l6:''
            }
        ],
        h4:'الشكل والتعبئة والتغليف:',
        c4:'سائل. 1 لتر.'
    },
    {
        id: 3,
        slug: 'aa3',
        image: 'aminofix.png',
        title: 'AMINOFIX',
        berife: 'يساعد الطبيب البيطري AminoFIX في تكوين البروتين. وهو بمثابة عامل مانع للبناء لتطوير إنتاج اللحوم.',
        viewmore: 'View Products Details',
        c1: 'يساعد الطبيب البيطري AminoFIX في تكوين البروتين. وهو بمثابة عامل مانع للبناء لتطوير إنتاج اللحوم. كما أنها تستخدم لزيادة إنتاج البيض والحليب. دعم العلاج في العملية وبعد العملية ، النقاهة ، الجفاف ، الضعف ، القيء ، الإسهال ، اختلال توازن الشوارد ، الكيتوزيه ، الحساسية المفرطة ، الحماض ونقص بروتين الدم.',
        h2: '',
        c2: [
            {
                l1: ''
            }
        ],
        h3:'الجرعة وطريقة الاستعمال :',
        subhead:'',
        c3:[
            {
                h1:'دواجن:',
                l1:'أضف 1 مل لكل 1 لتر ماء يستمر لمدة 3-5 أيام. للماشية والجاموس والحصان والماعز والأغنام والكلاب والحيوانات الأخرى',
                h2:'الجرعة العادية :',
                l2:'2-3 مل لكل 10 كجم من وزن الجسم في مياه الشرب. ',
                h3:' جرعة تسمين اللحم البقري : ',
                l3:'4-6 مل لكل 10 كجم من وزن الجسم في مياه الشرب.',
                h4:'جرعة زيادة الحليب : ',
                l4:' 4-6 مل لكل 10 كجم من وزن الجسم في مياه الشرب. أو كما نصح الطبيب البيطري المسجل.',
                h5:'',
                l5:'',
                h6:'',
                l6:''
            }
        ],
        h4:'تقديم:',
        c4:'1 لتر'
    },
    {
        id: 4,
        slug: 'aa4',
        image: 'pvs.png',
        title: 'PVS Long Liv',
        berife: 'مركز سائل مزيج فريد من المكونات العشبية للاستخدام في الماشية والدواجن.',
        viewmore: 'View Products Details',
        h2: 'فوائد:',
        c2: [
            {
                l1: '· يحافظ على وظائف الكبد المثلى.',
                l2: '· يحمي من الأفلاتوكسينات والسموم الأخرى.',
                l3: '· يساعد على تحسين الهضم وامتصاص العناصر الغذائية.',
                l4: '· يزيد من مقاومة الأمراض والصحة والنمو وأوزان الجسم'
            }
        ],
        h3:'جرعة:',
        subhead:'الثروة الحيوانية : من خلال مياه الشرب',
        c3:[
            {
                h1:'· الأبقار والجاموس والخيول والجمال :',
                l1:' 50 مل مرتين يوميا',
                h2:'· العجول والمهرات :',
                l2:' 20-25 مل. مرتين يوميا.',
                h3:'· الأغنام والماعز :',
                l3:'15-20 مل. مرتين يوميا يمكن مضاعفة الجرعة في وقت الظروف القاسية (أو) كما ينصح الطبيب البيطري.',
                h4:'تعليمات الاستخدام :',
                l4:'(يفضل أن تعطى في ماء الصباح). لاستعادة انخفاض تناول الأعلاف وتحفيز وظائف الكبد المصابة وتحسينها.',
                h5:'',
                l5:'لتحسين نسبة تحويل الأعلاف والنمو والإنتاج وقابلية العيش. لمواجهة الآثار الضارة للأفلاتوكسينات. يستخدم لمدة 7 أيام أو حتى تستمر الحالة',
                h6:'استخدم بانتظام :',
                l6:'يوصى بمضاعفة الجرعة لمدة 5 إلى 7 أيام.'
            }
        ],
        h4:'تقديم:',
        c4:'1 لتر'
    },
    {
        id: 5,
        slug: 'aasss4',
        image: 'cal_d.png',
        title: 'Cal D Phos',
        berife: 'تحضير الكالسيوم السائل والفوسفور لتحسين صحة الحيوان وإنتاجيته',
        viewmore: 'View Products Details',
        c1: '',
        h2: 'فوائد',
        c2: [
            {
                l1: 'يحسن إنتاج الحليب إلى أقصى حد ممكن.',
                l2: 'يساعد على تحسين قوة العظام والهيكل العظمي.',
                l3: 'يمنع نقص كلس الدم وحمى الحليب.',
                l4: 'يمنع المضاعفات مثل عسر الولادة ، احتباس المشيمة ، التهاب الميت ، التهاب الضرع ، إلخ.',
                l5: 'يزيد من نمو وأوزان الجسم من العجول.',
                l6: 'يحسن الصحة والحيوية والشهية وقوة العظام ونمو الكلاب.'
            }
        ],
        h3:'جرعة:',
        subhead:'الماشيه',
        c3:[
            {
                h1:'الأبقار والجاموس / الخيول :',
                l1:'100 مل / يوم / 7-10 أيام في الشهر.',
                h2:'عجول:',
                l2:'40 مل / يوم / 7 أيام في الشهر.',
                h3:'الكلاب :',
                l3:'5 مل / يوم.',
                h4:'الأغنام والماعز :',
                l4:'10 مل / يوم – 7 أيام في الشهر. (أو) كما نصح الطبيب البيطري.',
                h5:'',
                l5:'',
                h6:'',
                l6:''
            }
        ],
        h4:'تقديم:',
        c4:'500 مل ، 1 لتر. حاوية بلاستيكية'
    },
    {
        id: 6,
        slug: 'aasss4',
        image: 'super_formula.png',
        title: 'Super Formula',
        berife: 'مزيج من الفيتامينات والمعادن والأحماض الأمينية الضرورية للتغذية والنمو الطبيعي مما يؤدي إلى زيادة الوزن والمساعدة في الخصوبة.',
        viewmore: 'View Products Details',
        c1: 'مزيج من الفيتامينات والمعادن والأحماض الأمينية الضرورية للتغذية والنمو الطبيعي مما يؤدي إلى زيادة الوزن والمساعدة في الخصوبة',
        h2: 'ثقل:',
        c2: [
            {
                l1: '10 كجم.',
            }
        ],
        h3:'',
        subhead:'',
        c3:[
            {
                h1:'',
                l1:'',
                h2:'',
                l2:'',
                h3:'',
                l3:'',
                h4:'',
                l4:'', 
                h5:'',
                l5:'',
                h6:'',
                l6:''
            }
        ],
        h4:'',
        c4:''

    },
    {
        id: 7,
        slug: 'aasss4',
        image: 'power_formula.png',
        title: 'Power Formula',
        berife: 'مزيج من المعادن الضرورية والفيتامينات والبروبيوتيك للحفاظ على صحة حيوانك ومحصنا.',
        viewmore: 'View Products Details',
        c1: 'مزيج من المعادن الضرورية والفيتامينات والبروبيوتيك للحفاظ على صحة حيوانك ومحصنا.',
        h2: 'ثقل:',
        c2: [
            {
                l1: '10 كجم.',
            }
        ],
        h3:'',
        subhead:'',
        c3:[
            {
                h1:'',
                l1:'',
                h2:'',
                l2:'',
                h3:'',
                l3:'',
                h4:'',
                l4:'',
                h5:'',
                l5:'',
                h6:'',
                l6:''
            }
        ],
        h4:'',
        c4:''
    },
]
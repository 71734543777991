export const Electric_Arabic_Data = [
    {
        id: 1,
        image: 'Rtron-1.png',
        title: 'آر-ترون 620',
        subhead:'التحكم في المناخ',
        mainclimate:'لدينا مجموعة واسعة من لوحات التحكم في المناخ لأجنحة الدواجن والبيوت المحمية والصوامع. لمزيد من المنتجات والاستفسارات يرجى التواصل معنا',

    },
    {
        id: 2,
        image: 'TP80-1.png',
        title: 'تي بي - 80 ',
        subhead:'التحكم في المناخ',
        mainclimate:'لدينا مجموعة واسعة من لوحات التحكم في المناخ لأجنحة الدواجن والبيوت المحمية والصوامع. لمزيد من المنتجات والاستفسارات يرجى التواصل معنا',
    },
    {
        id: 3,
        image: 'TP54-1.png',
        title: ' تي بي - 54',
        subhead:'التحكم في المناخ',
        mainclimate:'لدينا مجموعة واسعة من لوحات التحكم في المناخ لأجنحة الدواجن والبيوت المحمية والصوامع. لمزيد من المنتجات والاستفسارات يرجى التواصل معنا',
    },
    {
        id: 4,
        image: 'Rtron612-1.png',
        title: 'آر-ترون 612',
        subhead:'التحكم في المناخ',
        mainclimate:'لدينا مجموعة واسعة من لوحات التحكم في المناخ لأجنحة الدواجن والبيوت المحمية والصوامع. لمزيد من المنتجات والاستفسارات يرجى التواصل معنا',
    },
    {
        id:5,
        image:'Rtron313-1.png',
        title:'آر-ترون 313',
        subhead:'التحكم في المناخ',
        mainclimate:'لدينا مجموعة واسعة من لوحات التحكم في المناخ لأجنحة الدواجن والبيوت المحمية والصوامع. لمزيد من المنتجات والاستفسارات يرجى التواصل معنا',
    },
    {
        id: 6,
        image: 'Chikatron20-1.png',
        title: 'تشيكاترون 20',
        subhead:'التحكم في المناخ',
        mainclimate:'لدينا مجموعة واسعة من لوحات التحكم في المناخ لأجنحة الدواجن والبيوت المحمية والصوامع. لمزيد من المنتجات والاستفسارات يرجى التواصل معنا',
    },
    {
        id: 7,
        image: 'Rtron207-1.png',
        title: 'آر-ترون 207',
        subhead:'التحكم في المناخ',
        mainclimate:'لدينا مجموعة واسعة من لوحات التحكم في المناخ لأجنحة الدواجن والبيوت المحمية والصوامع. لمزيد من المنتجات والاستفسارات يرجى التواصل معنا',
    },
    {
        id: 8,
        image: 'Rtron-1.png',
        title: 'آر ترون',
        subhead:'التحكم في المناخ',
        mainclimate:'لدينا مجموعة واسعة من لوحات التحكم في المناخ لأجنحة الدواجن والبيوت المحمية والصوامع. لمزيد من المنتجات والاستفسارات يرجى التواصل معنا',
    },
    {
        id: 9,
        image: 'smartlink.png',
        title: 'سمارت لينك2',
        subhead:'سمارت لينك',
        mainclimate:'من خلال الاتصال بذكاء واستخدام تطبيق خاص وتوصيل لوحة التحكم بالرابط الذكي ، يمكنك مراقبة جميع مزايا اللوحة والتحكم فيها من أي مكان.',
    },
    {
        id: 10,
        image: 'vaccine_temp.png',
        title: 'مراقب درجة حرارة اللقاح',
        subhead:'مراقبة درجة الحرارة',
        mainclimate:'يوضح هذا الجهاز الكهربائي مستوى درجة حرارة اللقاح.',
    },
    
    {
        id: 11,
        image: 'temp_sensor.png',
        title: 'مستشعر درجة الحرارة',
        subhead:'اجهزه الاستشعار',
        mainclimate:'مستشعر درجة الحرارة حساب درجة حرارة البيئة.',
    },
    {
        id: 12,
        image: 'humidity_sensor.png',
        title: 'مستشعر الرطوبة',
        subhead:'اجهزه الاستشعار',
        mainclimate:'يحسب مستشعر الرطوبة هذا تركيز بخار الماء الموجود في الهواء.',
    },
    {
        id: 13,
        image: 'co2_sensor.png',
        title: 'CO2 Sensor',
        subhead:'اجهزه الاستشعار',
        mainclimate:'يحسب هذا المستشعر قيمة مستوى CO2 في الهواء. ...',
    },
    {
        id: 14,
        image: 'amonia_sensor.png',
        title: 'Amonia Sensor',
        subhead:'اجهزه الاستشعار',
        mainclimate:'يحسب هذا المستشعر مستوى الأمونيا الموجودة في الهواء.',
    },
    {
        id: 15,
        image: 'silo_control.png',
        title: 'التحكم في الصوامع',
        subhead:'التحكم في الصوامع',
        mainclimate:'',
    },


]
export const Equine_Arabic_Data = [
    {
        id: 1,
        image: 'NostrilVet.png',
        title: 'منخر',
        mainc1: 'طبيب بيطري في فتحة الأنف عبارة عن مسحوق بخاخ للأنف مصمم للمساعدة في حماية مجرى الهواء العلوي للحصان من مسببات الحساسية الهوائية المهيجة.',
        h: 'نفس الحصان المسألة',
        h1:'داخل المزيد ستكون صورة المنتج الطبيب البيطري Nostril عندما بين أنها سوف تظهر المعلومات أدناه:',
        c1: [
            {
                l1: '· طبيب بيطري في فتحة الأنف عبارة عن مسحوق بخاخ للأنف مصمم للمساعدة في حماية مجرى الهواء العلوي للحصان من مسببات الحساسية الهوائية المهيجة.',
                l2: '· توفر الدرجة الفريدة من السليلوز المستخدمة في الطبيب البيطري في فتحة الأنف حاجزا وقائيا في الجهاز التنفسي العلوي ، مما يساعد على منع تفاعل مسببات الحساسية المستنشقة مع المستقبلات ، وبالتالي تقليل الاستجابة التحسسية.',
                l3: '· تأتي الزجاجة مع قضيب مصمم خصيصا يثبت الجزء العلوي من الزجاجة ويساعد على توزيع المسحوق حول القناة الأنفية للحصان',
            }
        ],
        h2:'هوية فتحة الأنف:',
        c2:[
            {
                l1:'· اسم العلامة التجارية في جميع أنحاء العالم: نوستريلفيت',
                l2:'· التصنيف التنظيمي: صيدلانية غير بيطرية',
                l3:'· شكل: إما زجاجة واحدة 4000mg العرض شهر واحد أو زجاجة واحدة 2000mg أسبوعين العرض.',
                l4:'· التركيب: درجة فريدة من مسحوق السليلوز.',
                l5:'· حالة المخدرات: اختبار المنشطات مجانا.'
            }
        ],
        h3:'مؤشرات الأنف والحنجرة:',
        c3:[
            {
                l1:'فتحة الأنف تحمي الجهاز التنفسي العلوي',
                l2:'يمكن أن تقلل فتحة الأنف من السعال بسبب الالتهاب الناجم عن الغبار.',
                l3:'يمكن أن تساعد فتحة الأنف في تقليل تأثير مرض التهاب مجرى الهواء (IAD) عن طريق تقليل التعرض لمسببات الحساسية ...',
                l4:'يمكن أن تقلل فتحة الأنف من اهتزاز الرأس بسبب تهيج مسببات الحساسية',
            }
        ]
    },

]   
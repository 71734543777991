import React from "react";
import { Link } from "react-router-dom";

function Footer_Arabic() {
  return (
    <div className="relative bg-[#F0F6FA] md:h-[290px] mt-20">
      <div className="grid justify-items-center grid-cols-1  md:grid-cols-3  md:pt-[60px] pt-[50px] xl:px-[120px] px-[20px] justify-between">
        <div className="justify-items-center w-ful grid md:block">
          <Link to={"/arabic"}><img src="../assets/footer-logo.png" alt="" className="w-[200px]" /></Link>
          <p className="text-[#88A398] text-[20px] py-1 mt-2">الخصوصيه</p>
          <p className="text-[#88A398] text-[20px] py-1">حيث</p>
          <p className="text-[#88A398] text-[20px] py-1">تعليمات</p>
        </div>
        <div className="text-[20px] block mt-2 leading-8 text-[#88A398] grid justify-items-center md:block">
          <p className="font-semibold py-1">دكان</p>
          <p> <Link to={"/falcon_arabic"}  className="py-1">صقر</Link> </p>
          <p> <Link to={"/poultry_arabic"}  className="py-1">دواجن</Link> </p>
          <p> <Link to={"/large_arabic"} className="py-1">الحيوانات الكبيرة</Link> </p>
          <p> <Link to={"/dis_arabic" } className="py-1">المطهرات</Link> </p>
        </div>
        <div className="text-[20px] mt-2 text-[#88A398] grid justify-items-center md:block">
          <p className="font-semibold">تابعنا</p>
          <div className="flex w-[140px] justify-between mt-3">
            <a href="https://www.facebook.com" target="_blank"><img src="./assets/facebook.png" alt="" className="h-7" /></a>
            <a href="https://instagram.com/mevs.gcc?igshid=MzRlODBiNWFlZA==" target="_blank"><img src="./assets/instagram2.png" alt="" className="h-7" /></a>
            <a href="https://www.google.com/" target="_blank"> <img src="./assets/google.png" alt="" className="h-7" /></a>
          </div>
        </div>
      </div>
      <div className="flex justify-center text-center  mx-5 mt-3 md:mx-28 md:w-[600px] lg:mx-auto ">
        <p className="text-[#88A398] font-medium md:text-[14px] text-[10px] md:w-full w-[300px] ">
          CopyRight @ MEV {new Date().getFullYear()} -  <a href="https://meerana.ae/">Designed & Developed By
            Meerana Technologies LLP
          </a> </p>
      </div>
      <div className="w-full md:h-[130px] h-[90px] rounded-[60%] bg-[#F0F6FA] absolute md:-top-[70px] -top-[40px]"></div>

    </div>
  );
}

export default Footer_Arabic
  ;

import React, { useState } from "react";
import SubNavbar from "../../subnavbar";
import { Large } from "../../../data/largeanimal";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function LargeAnimal() {
  const [popup, setPopup] = useState([]);
  const [popuptoggle, setPopuptoggle] = useState(false);
  const changeContent = (large) => {
    setPopup([large]);
    setPopuptoggle(!popuptoggle);
  };

  const navigate = useNavigate();
  const navigateToContact = () => {
    navigate("/");
    setTimeout(() => {
      const contactSection = document.getElementById("contact");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 800); // Delay for smoother scroll
  }
  const navigateToProducts = () => {
    navigate("/");
    setTimeout(() => {
      const productsSection = document.getElementById("product");
      if (productsSection) {
        productsSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay for smoother scroll
  }

  const navigateToAll = () => {
    navigate("/");
    setTimeout(() => {
      const allSection = document.getElementById("allpro");
      if (allSection) {
        allSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 45); // Delay for smoother scroll
  }

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 550);
  }, [pathname]);


  return (
    <>
      <Navbar />
      <SubNavbar />
      <div className="xl:px-[120px] px-[20px] lg:h-[85vh] h-[90vh] lg:flex items-center justify-between">
        <div className="xl:w-[700px] lg:w-[600px] grid content-center">
          <div className="lg:text-[40px] text-[20px] md:text-[24px] font-bold text-[#88A398] lg:mt-20 mt-[100px]">
            <p className="">Welcome to Middle East </p>
            <p className="">Veterinary Supply Co. L.L.C</p>
          </div>
          <p className="lg:text-[18px] md:text-[16px] text-[14px] text-[#5F726F] lg:pt-10 pt-5 content-en">
            where we are committed to [keep healthy environment through serving
            animal health]. We are a Veterinary Medicines supply in United Arab
            Emirates, Dubai that has been serving Large Animal Farms, Horse
            stables, Veterinary Clinics, Large Animal Farms, Poultry Farms and
            Falconers for more than 20 years
          </p>
        </div>
        <div className="lg:mt-20 h-60 md:h-80 grid justify-items-center content-center">
          <img src="../assets/product-bg.png" alt="" className="lg:h-[400px] h-[200px] md:h-[250px]" />
        </div>
      </div>

      {/* <div className="px-[120px] pb-20 grid-cols-3 grid w-full" >
      {
        Large.map(larges => {
          return (
           
              <div className="xl:min-h-[600px] relative flex flex-col h-auto min-w-[300px] w-auto mx-10 drop-shadow-2xl shadow-2 bg-white mb-10" key={larges.id}>
                <div className="flex justify-center py-7">
                  <img src="https://ik.imagekit.io/4tk3lgwqnz/Pics/large_animals/APSA_Polivetsel_crop.png?updatedAt=1686547232773" alt="" className="h-[200px]" />
                </div>
                <div className="px-5">
                  <p className="text-[22px] font-[700] text-[#000000] h-[55px]">{larges.title}</p>
                  <p className="text-[18px] text-[#647794] pt-2">Nutritional supplement based on vitamin E and selenium, indicated to improve the quality of meat, avoid encephalomalacia and white muscle disease and improve fertility in breeders.</p>
                </div>
                <div className=" absolute bottom-0 duration-700 flex  justify-center w-full cursor-pointer hover:bg-[#E9A655] text-[#E9A655] hover:text-white transition-all">

                  <p className="border-t-2 w-full p-2 grid place-content-center text-[22px] ">View Products Details</p>
                </div>
              </div>
           
          )
        })
        
      }
       </div> */}

      {/* 
<div className="px-[120px] pb-20 grid-cols-3 grid w-full" >
        {data.products.map((product)=>(
          <ProductItem product={product} key={product.slug}></ProductItem>
        ))}
       </div> */}

      {/* navigator */}

      <div className="flex gap-10 mt-10 lg:mt-0 text-center text-[12px] md:px-[30px] lg:px-[50px] xl:px-[120px] whitespace-nowrap overflow-auto scrollbar-hide">
        <Link to="/all" className='cursor-pointer'>
          <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white" onClick={navigateToAll}>
            All
          </p>
        </Link>
        <Link to="/large_animal">
          <p className="border-2 border-[#88A398] text-[#88A398] font-semibold rounded-2xl uppercase px-3 py-1  hover:bg-[#FFFFFF]">
            Large Animal
          </p>
        </Link>
        <Link to="/equine">
          <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
            Equine
          </p>
        </Link>
        <Link to="/electric_equipment">
          <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
            Vet. Equipment
          </p>
        </Link>
        <Link to="/poultry">
          <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
            Poultry
          </p>
        </Link>
        <Link to="/falcon">
          <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
            Falcon
          </p>
        </Link>
        <Link to="/disinfectants">
          <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
            Disinfectants
          </p>
        </Link>
      </div>

      <div className="xl:px-[120px] px-[20px] pb-20 lg:grid-cols-3 md:grid-cols-2 grid w-full mt-10">
        {Large.map((large) => {
          return (
            <div
              className="lg:min-h-[600px] min-h-[550px] xl:h-[600px] relative flex flex-col  w-auto xl:mx-10 lg:mx-2 md:mx-4 drop-shadow-2xl shadow-2 bg-white mb-10"
              key={large.id}
            >
              <div className="flex justify-center lg:py-7 py-3">
                <img
                  src={`../large/${large.image}`}
                  alt=""
                  className="h-[200px]"
                />
              </div>
              <div className="px-5">
                <p className="lg:text-[22px] text-[18px] font-[700] text-[#000000] lg:h-[55px] h-[45px]">
                  {large.title}
                </p>

                <p className="lg:text-[18px] text-[16px] text-[#647794] pt-2">
                  {large.berife}
                </p>
              </div>
              <button onClick={() => changeContent(large)}>
                <div className=" absolute bottom-0 duration-700 flex  justify-center w-full cursor-pointer hover:bg-[#E9A655] text-[#E9A655] hover:text-white transition-all">
                  <p className="border-t-[1px] w-full p-2 grid place-content-center border-[#A8B3C4] text-[18px] lg:text-[22px]">
                    View Products Details
                  </p>
                </div>
              </button>
            </div>
          );
        })}
      </div>

      {/* popup  */}

      {popuptoggle && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-50">
          {popup.map((pop) => {
            return (
              <>
                <div className="lg:w-[510px] md:w-[450px] bg-white pb-10 opacity-100 float-right h-full lg:p-10 p-4 m-4 md:m-0 overflow-y-scroll">
                  <div className="flex justify-between">
                    <p className="lg:text-[32px] text-[18px] font-[600] text-[#88A398] lg:w-[350px] w-[200px] md:w-[300px]">
                      {pop.title}
                    </p>
                    <button onClick={changeContent} className="cursor-pointer">
                      {/* <img src="./assets/exit.png" alt="" className="lg:h-10 h-7" /> */}
                      <p className="text-[30px] font-[500] border rounded-[50%] w-[50px] text-[#88A398] border-[#88A398]">x</p>
                    </button>
                  </div>
                  <div className="text-[#88A398]">
                    <p className="lg:text-[16px] text-[12px] underline underline-offset-4">
                      Target species & dose
                    </p>
                    <p className="lg:text-[20px] text-[14px]">
                      Dosage & Administration Large Animals:
                    </p>
                  </div>
                  <hr className="border-[#88A398] opacity-[40%]" />
                  <div className="flex justify-center py-3 bg-[#DBE5EB] my-5">
                    <img
                      src={`../large/${pop.image}`}
                      alt=""
                      className="h-[180px]"
                    />
                  </div>
                  <div className="lg:text-[16px] text-[14px] text-[#7088A0]">
                    <p className="pb-2">
                      {pop.c1}
                    </p>
                    <div className="pb-5">
                      <p className="font-[600]">{pop.h2}</p>
                      {pop.c2.map((list1) => (
                        <ul key={list1.id}>
                          <li>{list1.l1}</li>
                          <li>{list1.l2}</li>
                          <li>{list1.l3}</li>
                          <li>{list1.l4}</li>
                        </ul>
                      ))}

                    </div>
                    <div className="pb-5">
                      <p className="font-[600]">{pop.h3}</p>
                      <p className="font-[600]">{pop.subhead}</p>
                    </div>
                    {pop.c3.map((list2) => (
                      <div className="pb-5" key={list2.id}>
                        <ul>
                          <li className="font-[600]">{list2.h1}</li>
                          <li className="">{list2.l1}</li>
                          <li className="font-[600]">{list2.h2}</li>
                          <li className="">{list2.l2}</li>
                          <li className="font-[600]">{list2.h3}</li>
                          <li className="">{list2.l3}</li>
                          <li className="font-[600]">{list2.h4}</li>
                          <li className="">{list2.l4}</li>
                          <li className="font-[600]">{list2.h5}</li>
                          <li className="">{list2.l5}</li>
                          <li className="font-[600]">{list2.h6}</li>
                          <li className="">{list2.l6}</li>
                        </ul>
                      </div>
                    ))}
                    <div className="pb-5">
                      <p className="font-[600]">{pop.h4}</p>
                      <p className="">{pop.c4}</p>
                    </div>
                    <div className="flex justify-center">

                      <button className="bg-[#88A398] w-[160px] h-10 rounded-[10px] text-white text-center p-2" onClick={navigateToContact}>
                        Contact Us
                      </button>

                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}
      <Footer />
    </>
  );
}
export default LargeAnimal;

import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

function Navbar_Arabic() {
  const [isNavVisible, setNavVisibility] = useState(false);

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  // Function to close the navigation menu
  const closeNav = () => {
    setNavVisibility(false);
  };
  // useeffect for screen change
  useEffect(() => {
    const handleResize = () => {
      const breakpoint = 768;
      if (window.innerWidth >= breakpoint) {
        closeNav();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isActive, setIsActive] = useState(false);
  const toggleRef = useRef(null);
  const bar_arabicRef = useRef(null);

  const handleToggleClick = () => {
    setIsActive(!isActive);
  };

  const handleDocumentClick = (e) => {
    if (
      isActive &&
      toggleRef.current &&
      !toggleRef.current.contains(e.target)
    ) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isActive]);



  const navigate = useNavigate();
  const navigateToContact = () => {
    navigate("/arabic");
    setTimeout(() => {
      const contactSection = document.getElementById("contact_arabic");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay for smoother scroll
  }

  const navigateToAbout = () => {
    navigate("/arabic");
    setTimeout(() => {
      const aboutSection = document.getElementById("about_arabic");
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay for smoother scroll
  }

  const navigateToProducts = () => {
    navigate("/arabic");
    setTimeout(() => {
      const productsSection = document.getElementById("product_arabic");
      if (productsSection) {
        productsSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay for smoother scroll
  }
  const navigateToPartners = () => {
    navigate("/arabic");
    setTimeout(() => {
      const partnersSection = document.getElementById("partners_arabic");
      if (partnersSection) {
        partnersSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay for smoother scroll
  }


  // const [showSideMenu, setShowSideMenu] = useState(false);
  // const [navbar, setNavbar] = useState(false);

  // const handleSideMenu = () => {
  //   setShowSideMenu(!showSideMenu);
  // };

  // const changeBackground = () => {
  //   if (window.scrollY >= 80) {
  //     setNavbar(true);
  //   } else {
  //     setNavbar(false);
  //   }
  // };
  // window.addEventListener('scroll', changeBackground);

  // const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  // const handleSubMenuClick = () => {
  //   setIsSubMenuOpen(!isSubMenuOpen);
  // };


  return (
    <div className=''>
      <nav class="absolute top-0 flex w-full z-20 lg:pt-10 pt-2 pb-5 xl:px-[120px] lg:pb-0 bg-white lg:bg-transparent">
        <div class="pl-2">
          <a href="./arabic" class="">
            <img
              src="https://ik.imagekit.io/4tk3lgwqnz/Pics/Pics/main_logo.png?updatedAt=1686494885218"
              alt=""
              class="lg:w-[190px] xl:w-[275px] md:w-[220px] w-[180px] xl:h-[96px]"
            />
          </a>
        </div>
        <div class=" mt-5  hidden lg:block">
          <ul class="xl:text-[26px] lg:text-[22px] font-[500] list-style-none flex flex-col pl-0 lg:flex-row text-[#263238]">
            <li class="my-1 lg:my-0 lg:pr-2 hover:text-[#E9A655] xl:ml-5">
              <a class=" lg:px-2 " href="/arabic">
                وطن
              </a>
            </li>
            <li class="my-1 lg:my-0 lg:pr-0 xl:pr-2 hover:text-[#E9A655] xl:ml-5 ">
              <Link to={"/all_arabic"}>
                <a class=" lg:px-2 cursor-pointer" >
                  منتجات
                </a></Link>
            </li>
            <li class="my-1 lg:my-0 lg:pr-2 hover:text-[#E9A655] xl:ml-5">
              <a class=" lg:px-2 cursor-pointer" onClick={navigateToPartners}>
                شركاء
              </a>
            </li>
            <li class="my-1 lg:my-0 lg:pr-2 hover:text-[#E9A655] xl:ml-5">
              <a class=" lg:px-2 cursor-pointer" onClick={navigateToAbout}>
                عن
              </a>
            </li>
            <li class="my-1 lg:my-0 lg:pr-2 hover:text-[#E9A655] ml-5">
              <a class=" lg:px-2 cursor-pointer" onClick={navigateToContact}>
                الاتصال
              </a>
            </li>
          </ul>
        </div>
        <div className=" lg:left-[154px] mt-5 hidden lg:block absolute">
          <div className="flex justify-between lg:h-[25px] lg:w-[250px] w-[300px] h-[35px]">
            <a href="https://www.facebook.com" target="_blank"><img src="./assets/facebook1.png" alt="" className="h-7" /></a>
            <a href="https://instagram.com/mevs.gcc?igshid=MzRlODBiNWFlZA==" target="_blank"><img src="./assets/instagram.png" alt="" className="h-7" /></a>
            <a href="https://www.google.com/" target="_blank"> <img src="./assets/google1.png" alt="" className="h-7" /></a>

            <Link to={"/"}>
              <div className="flex h-7 items-center">
                <img src="../assets/uk.png" alt="" className="h-7 rounded-[50%]" />
                <p className="pr-2 text-[16px] font-[500] text-white">EN</p>
              </div>
            </Link>
          </div>
        </div>
        <div
          ref={toggleRef}
          id="toggle"
          className={`toggle ${isActive ? "active" : ""}`}
          onClick={handleToggleClick}
        >
          <button
            class="block bg-fixed top-[20px] left-[10px] absolute border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
            type="button"
            data-te-collapse-init
            data-te-target="#navbarSupportedContent3"
            aria-controls="navbarSupportedContent3"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="[&>svg]:w-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="h-7 w-7"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </button>
        </div>
      </nav>
      <div
        id="bar_arabic"
        className={`bar_arabic ${isActive ? "active h-full z-40" : ""} lg:hidden `}
        ref={bar_arabicRef}
      >
        <ul class="md:pr-[40px] pr-[30px]">
          <li class="my-2 cursor-pointer">
            <a href="/arabic">وطن</a>
          </li>
          <li class="my-2 cursor-pointer">
          <Link to={"/all_arabic"}> <a>منتجات</a></Link>
          </li>
          <li class="my-2 cursor-pointer">
            <a onClick={navigateToPartners}>شركاء</a>
          </li>
          <li class="my-2 cursor-pointer">
            <a onClick={navigateToAbout}>عن</a>
          </li>
          <li class="my-2 cursor-pointer">
            <a onClick={navigateToContact}>الاتصال</a>
          </li>
        </ul>
        <div className=" flex justify-between mr-8 h-[20px] w-[180px] md:mr-10 md:w-[200px] md:h-[25px] mt-5">
          <a href="" target="_blank"><img src="./assets/facebook.png" alt="" className="h-7" /></a>
          <a href="https://instagram.com/mevs.gcc?igshid=MzRlODBiNWFlZA==" target="_blank">   <img src="./assets/instagram2.png" alt="" className="h-7" /></a>
          <a href="https://www.google.com/" target="_blank"> <img src="./assets/google.png" alt="" className="h-7" /></a>
          <Link to={"/"}>
            <div className="flex h-7 items-center">
              <img src="../assets/uk.png" alt="" className="h-7" />
              <p className="pr-2 pt1 text-[16px] font-[500]">EN</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Navbar_Arabic;
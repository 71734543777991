import React from 'react';
import data from '../utilis/data';
import { useParams } from 'react-router-dom';

export default function ProductScreen() {
    const { slug } = useParams(); // Extract the slug directly using useParams

    // Find the product with the matching slug
    const product = data.products.find((x) => x.slug === slug);

    return (
        <div>
            {product ? (
               <>
                <p className="">{product.title}</p>
                <p className="">{product.slug}</p>
                </>
            ) : (
                <p>Product not found</p>
            )}
        </div>
    );
}

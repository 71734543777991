export const Falcon =[
    {
        id:1,
        image: 'ViTonic.png',
        title: 'Falcon ViTonic',
        subtitle:'Multivitamin supplement',
        maincontent:'This product is indicated as multivitamin supplement incase of Vitamin deficiency, stress, transport and change in climate.',
        composition:'Composition per 1 kg powder: ',
        c1:'Vitamin A: 1,500,000 IU, Vitamin B1 (thiamine): 600mg, Vitamin B2 (riboflavin): 1,400mg, Vitamin B5:3,500mg, Vitamin B6: 500mg, Vitamin B12: 5mg, Vitamin C: 12,000mg,Vitamin D3: 50,000 IU, Vitamin E: 10,000 IU, Vitamin K: 1.5mg, Niacin:3,000mg, Folic Acid: 300mg, Iodine: 100mg, Sodium Chloride: 25mg,Magnesium Sulfate:10mg, Sodium Citrate: 30mg, Choline Chloride: 4mg, Lysine: 3mg, Methionine: 2mg, Echinacea: 20mg',
        indication:'This product is indicated as multivitamin supplement incase of Vitamin deficiency, stress, transport and change in climate.',
        dosage:'The usual dosage is 1 spoon (of 1g) per 1 kg bodyweight once per day as required',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'100G'
    },
    {
        id:2,
        image: 'super_moult.png',
        title: 'Falcon Super Moult ',
        subtitle:'Supplement supporting feather growth in moulting season',
        maincontent:'This product is indicated as multivitamin, mineral and amino acid supplement to promote and accelerate the molting process in falcons.',
        composition:'Composition per 1 kg powder: ',
        c1:'Vitamin A: 1,500,000 IU, Vitamin B1 (thiamine):800mg, Vitamin B2 (riboflavin): 1,500mg, Vitamin B3 (niacinamide): 11,000mg,Vitamin B5: 3,200mg, Vitamin B6: 800mg, Vitamin B12: 10mg, Vitamin C:16,000mg, Vitamin D3: 15,000 IU, Vitamin E: 12,000 IU, Vitamin K3: 500mg,Lysine: 2mg, Methionine: 5mg, Choline Chloride: 3.5mg, Zinc: 300mg,Manganese: 90mg, Copper: 60mg, Iodine: 90mg, Iron: 1.5mg, Histidine: 1.5mg,Potassium: 20mg, Leucine: 2.5mg, Valine: 1.6mg, Cysteine: 2mg, Arginine: 5mg,Isoleucine: 1mg, Threonine: 2.5mg: Phenylalanine: 1.6mg, Tryptophan: 1.2mg,Folic acid: 400 mg, Biotin: 50μg, Calcium carbonate: 7mg',
        indication:' This product is indicated as multivitamin, mineral and amino acid supplement to promote and accelerate the molting process in falcons. It enhances feather growth and health which leads to stronger feathers and better plumage.',
        dosage:'The usual dosage is 1 spoon (of 1g) per 1 kg bodyweight once per day during the molting period.',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'100G'
    },
    {
        id:3,
        image: 'Rehydro.png',
        title: ' Falcon Rehydro',
        subtitle:'Electrolyte supplement to prevent exhaustion and dehydration',
        maincontent:'This product is indicated as electrolyte oral supplement in case of electrolyte deficiency and dehydration.', 
        composition:'Composition per 1kg powder:',
        c1:'Potassium chloride: 15g/kg, Sodium chloride: 26g/kg, Citric acid: 29g/kg, Calcium lactate: 10g/kg, Magnesium sulphate: 7g/kg, Glucose: 135g/kg',
        indication:'This product is indicated as electrolyte oral supplement in case of electrolyte deficiency and dehydration. Support falcon during training and hunting in hot climate, stress and transport.',
        dosage:'The usual dosage is 1 spoon (of 1g) per 1 kg bodyweight once per day as required',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'100G'
    },
    {
        id:4,
        image: 'Super_Aid_Plus.png',
        title: 'Falcon Super Aid Plus',
        subtitle:'Provides all nutrients during treatment of sick falcons',
        maincontent:'This product is indicated as vitamin, mineral and amino acid supplement to support falcons in times of sickness and disease and even intensive care treatment. ',
        composition:'Composition per 1kg powder:',
        c1:'Vitamin A: 20,000 IU, Vitamin B1: 1mg, Vitamin B2 (riboflavin): 3mg, Vitamin B3 (niacinamide): 1mg, Vitamin B5: 2mg, Vitamin B6 (pyridoxine): 5mg, Vitamin B7 (biotin): 30μg, Vitamin B9 (folic acid): 100μg, Vitamin B12: 2μg, Vitamin C: 15mg, Vitamin D3: 20IU, Vitamin E: 10IU, Vitamin K3: 0.1mg, Albumin: 2g, Calcium Gluconate: 60mg, Choline Chloride: 10mg,Magnesium Sulphate: 50mg, Potassium Chloride: 80mg, Sodium Chloride:115mg, Lysine: 3mg, Methionine: 2mg, Manganese: 500mg: Histidine: 1mg,Threonine: 2mg, Leucine: 4mg: Tryptophan: 1mg, Starch-maize (Hydrolysed): 7g',
        indication:'This product is indicated as vitamin, mineral and amino acid supplement to support falcons in times of sickness and disease and even intensive care treatment. It promotes faster disease recovery by adding energy and enhanced metabolism. It is a very balanced formula to provide all necessary supplements for falcons even in times of stress',
        dosage:' The usual dosage is 1 spoon (of 1 g) per 1 kg bodyweight once per day as required.',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'100G'
    },
    {
        id:5,
        image: 'Super_Hunt.png',
        title: 'Falcon Super Hunt',
        subtitle:'Energy booster for high performance',
        maincontent:'This product is indicated as vitamin, mineral and amino acid supplement to promote and accelerate the hunting performance in falcons',
        composition:'Composition per 1L liquid:',
        c1:'Ribose: 200g/L, L-Carnitine: 300g/L, Creatine Phosphate: 120g/L, Vitamin B1 (thiamine): 800mg, Vitamin B5: 300mg, Vitamin B12: 10mg, Vitamin C: 200mg, Vitamin E: 120mg, Calcium Gluconate: 8g, Magnesium oxide: 5g, Lysine: 4mg, Zinc: 350mg, Copper: 120mg, Cobalt: 80mg, Isoleucine: 3mg, Leucine: 5mg, Valine: 4mg, Arginine: 6mg, Phenylalanine: 3mg, Folic acid: 500 mg',
        indication:'This product is indicated as vitamin, mineral and amino acid supplement to promote and accelerate the hunting performance in falcons. It enhances muscle gain and strength which leads to enhanced muscle power and outstanding flight performance, speed, stamina and endurance.',
        dosage:'1 ml per kg body weight for 3-5 days.',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'Bottle 100ml'
    },
    {
        id:6,
        image: 'Super_Muscle_Plus.png',
        title: 'Falcon Super Muscle Plus',
        subtitle:'Supplement for Speed-Power-Energy',
        maincontent:'This product is indicated as vitamin, mineral and amino acid supplement to promote and accelerate the training process in falcons. ',
        composition:'Composition per 1L gel:',
        c1:'L-Carnitine: 150g/L, Creatine Phosphate: 250g/L, Vitamin B1 (thiamine): 800mg, Vitamin B5: 300mg, Vitamin B12: 10mg, Vitamin C: 12.000mg, Vitamin E: 8.000mg, Lysine: 3mg, Zinc: 300mg, Copper: 40mg, Cobalt: 50mg, Isoleucine: 2mg, Leucine: 4mg, Valine: 3mg, Arginine: 6mg, Phenylalanine: 3mg, Folic acid: 500 mg',
        indication:'This product is indicated as vitamin, mineral and amino acid supplement to promote and accelerate the training process in falcons. It builds muscle growth, increases muscle gain, performance, stamina and endurance which leads to enhanced muscle strength and highest grade flight performance.',
        dosage:'T ml per kg body weight for 3-5 days.',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'Bottle 100ml'
    },
    {
        id:7,
        image: 'Recovery_Plus.png',
        title: 'Falcon Recovery Plus',
        subtitle:'Supplement supporting fast recovery from illness and trauma',
        maincontent:'This product is indicated as vitamin, mineral and amino acid supplement to support the recovery process after disease, illness and trauma in falcons.',
        composition:'Composition per 1 kg powder:',
        c1:'Vitamin A: 1 000 000 IU, Vitamin B1 (thiamine): 400mg, Vitamin B2 (riboflavin): 1,000mg, Vitamin B5: 3.2mg, Vitamin B12: 4mg, Vitamin D3: 20 000 IU, Vitamin E: 5mg, Lysine: 30mg, Methionine: 8mg, Zinc: 500mg',
        indication:'This product is indicated as vitamin, mineral and amino acid supplement to support the recovery process after disease, illness and trauma in falcons. It helps in rehabilitating the falcon’s immune system, detoxifies and strengthens the falcon’s health at a fast pace.',
        dosage:'The usual dosage is 1 spoon (of 1 g) per 1 kg bodyweight once per day as required.',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'100G'
    },
    {
        id:8,
        image: 'Calci_Plus.png',
        title: 'Falcon Calci Plus',
        subtitle:'Calcium supplement for falcons',
        maincontent:'This product is indicated to support breeding falcons in times of higher required calcium intake to improve egg shells.',
        composition:'Composition of each liter:',
        c1:'Calcium (Ca++) as Calcium gluconate: 40.0g, Vitamin D3: 30,000i.u, Phosporus: 20.0g, Magnesium (Mg++) as Magnesiumsulphate: 2.0g',
        indication:'This product is indicated to support breeding falcons in times of higher required calcium intake to improve egg shells. Falcon Calci Plus also supports young falcon chicks in their bones development in order to create stronger and healthier bone structures and good muscles. Moreover, this product can be used very well as supplement for falcons with fractures as it supports faster and stronger kallus formation.',
        dosage:'The usual dosage is 1 ml per 1 kg bodyweight once per day as required.',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'Bottle 100ml'
    },
    {
        id:9,
        image: 'Vitonic_Plus.png',
        title: 'Falcon Vitonic Plus',
        subtitle:'Multivitamin supplement',
        maincontent:'Multivitamin supplement in case of vitamin deficiencies, stress, transport and change of the environment',
        composition:'Composition per 1 kg powder:',
        c1:'Vitamin A: 2,000 IU, Vitamin B1: 2mg, Vitamin B2: 4mg, Vitamin B5: 5mg, Vitamin B6: 1mg, Vitamin: B12 0.1mg, Vitamin C: 100mg, Vitamin D3: 70 IU, Vitamin E: 300 IU, Vitamin K: 20μg, Niacin: 8mg, Folic Acid: 1mg, Biotin: 30μg, Iodine: 0.35mg, Choline Chloride: 0.08mg, Echinacea: 10mg.',
        indication:'Multivitamin supplement in case of vitamin deficiencies, stress, transport and change of the environment',
        dosage:'The usual dosage is 1 tablet per 1 kg once per day, for 5 days.',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'30 tablets'
    },
    {
        id:10,
        image: 'Hemo_Plus.png',
        title: 'Falcon Hemo Plus',
        subtitle:'Blood enhancing supplement',
        maincontent:'Red Blood Cells Enhancing Supplement when an increase in red blood cells is required like during the training and hunting period.an increase in red blood cell function.',
        composition:'Composition of each tablet:',
        c1:'Lysine: 1.5mg, Zinc: 450μg, Iron: 390μg, Copper: 72μg, Vit.B12: 0.5μg, Folic Acid: 0.01mg, Vitamin: E 0.1μg,Vitamin C: 1.5mg',
        indication:'Red Blood Cells Enhancing Supplement when an increase in red blood cells is required like during the training and hunting period.an increase in red blood cell function results in enhanced oxygen production. Subsequently the falcon can bring enhanced performance during extended flight times like in training and hunting.',
        dosage:'1 tablet per 1 kg bodyweight once per day for 5-7 days or longer if required.',
        storage:' Store below 28⁰C in cool dry place.',
        packing:'50 tablets'
    },
    {
        id:11,
        image: 'Probiotic.png',
        title: 'Falcon Probiotic',
        subtitle:'Digestive system supplement Powder for oral administration',
        maincontent:'Probiotics are good bacteria supporting the digestive system and gut health by controlling the growth of harmful bacteria.',
        composition:'Composition in 1g:',
        c1:'200 million CFU Lactobacillus acidophilus, Enterococcus faecium, Streptococcus salivarius.',
        indication:'Probiotics are good bacteria supporting the digestive system and gut health by controlling the growth of harmful bacteria. Probiotics replenish the gut microbiome during and after a course of antibiotics.', dosage:'1g per kg body weight per day for 5-7 days.',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'30G'
    },
    {
        id:12,
        image: 'Liver_Care.png',
        title: 'Falcon Liver Care',
        subtitle:'Supplement for liver support',
        maincontent:'Improves liver function. Silymarin is used as effective liver support in falcons during diseases. Echinacea is a powerful herbal immunostimulant enhancing the immune system', 
        composition:'Composition of each tablet:',
        c1:'Silymarin: 90mg Echinacea: 30mg',
        indication:'Improves liver function. Silymarin is used as effective liver support in falcons during diseases. Echinacea is a powerful herbal immunostimulant enhancing the immune system',
        dosage:'1 tablet 2 times per week',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'30 tablets'
    },
    {
        id:13,
        image: 'Wormnill.png',
        title: ' Falcon Wormnill',
        subtitle:'For treatment of endoparasitic infections',
        maincontent:'This product is a general dewormer for falcons which targets the main endoparasite infections',
        composition:'Composition of each tablet:',
        c1:'Fenbendazole: 20mg, Praziquantel: 8mg', 
        indication:'This product is a general dewormer for falcons which targets the main endoparasite infections. It is indicated for the treatment of a variety of endoparasites, especially of the speciestrematoda, cestoda and nematoda like Ascaridia sp. and Capillaria sp. which is the most common endoparasites in raptors and hereby mainly Falconiformes. They are regarded as one of the main causes for enteric problems in falcons.',
        dosage:'1 tablet per 1 kg bodyweight once, repeated once again after 7 days.',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'30 tablets'
    },
    {
        id:14,
        image: 'Wormnill_Plus.png',
        title: ' Falcon Wormnill Plus',
        subtitle:'For treatment of endoparasites and coccidia',
        maincontent:'This product is a general dewormer for falcons which targets the main endoparasitic infections.',
        composition:'Composition of each tablet:',
        c1:'Fenbendazole: 20mg, Diclazuril: 10mg, Praziquantel: 8mg',
        indication:'This product is a general dewormer for falcons which targets the main endoparasitic infections. It is indicated for the treatment of endoparasites, especially of the species trematoda, cestoda and nematoda like Ascaridia sp. and Capillaria sp. It also targets mixed endoparasitic infections with Coccidia, which is the most common endoparasites in raptors and hereby mainly Falconiformes',
        dosage:'1 tablet per 1 kg /BW once which is repeated once again after 7 days". In case of heavy Ascardida burden, increase to 1 tablet per 1 kg/ BW for 3 days.',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'30 tablets'
    },
    {
        id:15,
        image: 'Coccikill.png',
        title: 'Falcon Coccikill',
        subtitle:'For treatment of coccidiosis in falcons',
        maincontent:'This product is indicated for the treatment of the protozoal parasite coccidia, especially of the species Caryospora sp.',
        composition:'Composition of each tablet:',
        c1:'Diclazuril: 5mg, Amproliumhydrochloride: 20mg',
        indication:'This product is indicated for the treatment of the protozoal parasite coccidia, especially of the species Caryospora sp. More than 7 Caryospora species have been described in falcons.',
        dosage:'The usual dosage is 1 tablet per 1kg bodyweight once per day for 3 consecutive days.',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'30 tablets'
    },
    {
        id:16,
        image: 'Trichonill_Plus.png',
        title: ' Falcon Trichonill Plus',
        subtitle:'For treatment of protozoal infections',
        maincontent:'This product is an antiprotozoal medicine for falcons which targets advanced protozoal infections like intestinal protozoal infections and giardiasis',
        composition:'Composition of each tablet:',
        c1:'Metronidazole: 50mg, Secnidzole: 25mg',
        indication:'This product is an antiprotozoal medicine for falcons which targets advanced protozoal infections like intestinal protozoal infections and giardiasis. It also supports treating trichomonas, a flagellated protozoan which leads to frequent infections in the crop of falcons.',
        dosage:'1 tablet per 1 kg bodyweight for 3-5 days. In case of large trichomonas lesions, dosage is 2 tablets per 1 kg BW for 5-7 days.',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'30 tablets'
    },
    {
        id:17,
        image: 'Moxictin_Super.png',
        title: ' Falcon Moxictin Super',
        subtitle:'For treatment of internal and external parasites',
        maincontent:'To control external and internal parasites in hunting falcon including the lungworms (serratospiculum)',
        composition:'Composition of each tablet:',
        c1:'Moxidectin: 1000 μg',
        indication:'To control external and internal parasites in hunting falcon including the lungworms (serratospiculum)',
        dosage:'1 tablet per kg body weight and repeated after 7 days.',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'30 tablets'
    },

    // insecta plus

    {
        id:18,
        image: 'Insecta_Plus.png',
        title: ' Falcon Insecta Plus',
        subtitle:'Control of ticks, mites and insects in falcons',
        maincontent:'To control ticks, mites, lice, mosquitos and other biting insects in falcons and in their cages.',
        composition:'Composition each liter:',
        c1:'Piperonyl butoxide: 6g, Permethrin: 1.3g, Preservatives, Emulsifier',
        indication:'To control ticks, mites, lice, mosquitos and other biting insects in falcons and in their cages.',
        dosage:'Spray the falcon holding the body 25-30cm away. Spray one time under the wings too.',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'100ml spray'
    },
    {
        id:19,
        image: 'Falcon_3_in_One.png',
        title: ' Falcon 3 in One',
        subtitle:'For treatment of bacterial and protozoal infections',
        maincontent:'This product is indicated as a broad-spectrum antibiotic of choice for gram negative and gram positive bacterial infections in raptors ',
        composition:'Composition of each tablet:',
        c1:'Marbofloxacin: 10mg, Metronidazole: 30mg, Diclazuril: 5mg',
        indication:'This product is indicated as a broad-spectrum antibiotic of choice for gram negative and gram positive bacterial infections in raptors and falcons, Trichomoniasis and coccidiosis',
        dosage:'1 tablet per 1 kg bodyweight once per day for 5-7 days.',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'30 tablets'
    },
    {
        id:20,
        image: 'Trichonill.png',
        title: ' Falcon Trichonill',
        subtitle:'For treatment of trichomoniasis',
        maincontent:'This product is an antiprotozoal medicine for falcons which targets protozoal infections like trichomonas and giardia infections.',
        composition:'Composition of each tablet:',
        c1:'Metronidazole: 100 mg',
        indication:'This product is an antiprotozoal medicine for falcons which targets protozoal infections like trichomonas and giardia infections. Trichomonas or the so-called “frounce” is very common in falcons as the protozoa get transmitted from pigeons to the falcons. Metronidazole also supports treating strong bacterial ingluvitis in falcons in combination with suitable antibiotics.',
        dosage:' 1 tablet per 1 kg /BW for 3-5 days and 5-7 days as support for bacterial ingluvitis and enteritis.',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'30 tablets'
    },
    {
        id:21,
        image: 'Asper_Plus.png',
        title: ' Falcon Asper Plus',
        subtitle:'For treatment of aspergillosis',
        maincontent:'This product is an antifungal agent for Aspergillosis in falcons. This product has been enhanced with silymarin extract as strong liver support as well as Echinacea as immune system booster.',
        composition:'Composition per 1 kg powder:',
        c1:'Voriconazole: 12.5mg, Silymarin: 70mg, Echinacea: 20mg',
        indication:'This product is an antifungal agent for Aspergillosis in falcons. This product has been enhanced with silymarin extract as strong liver support as well as Echinacea as immune system booster',
        dosage:'The usual dosage is 1 tablet per 1 kg bodyweight once or twice per day for 2-6 weeks.',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'50 tablets'
    },
    {
        id:22,
        image: 'Cefta.png',
        title: ' Falcon Cefta',
        subtitle:'For treatment of bacterial infections in falcons',
        maincontent:'This product is indicated for the treatment of Pseudomoniasis as antibiotic of choice, joint infections, lower respiratory tract, skin infections, blood-stream',
        composition:'Composition of each tablet:',
        c1:'Ceftazidime: 100 mg',
        indication:'This product is indicated for the treatment of Pseudomoniasis as antibiotic of choice, joint infections, lower respiratory tract, skin infections, blood- stream and abdominal infections caused by susceptible strains like Enterobacter, E. coli, Klebsiella, Proteus, Pseudomonas, streptococci like Streptococcus pneumoniae, and Streptococcus pyogenes and Bacteroides.',
        dosage:'The usual dosage is 1 tablet per 1 kg bodyweight once per day for 7 days or longer if required',
        storage:'Store below 28⁰C in cool dry place.',
        packing:'30 tablets'
    },
    {
        id:23,
        image: 'PIPRa.png',
        title: ' Falcon PIPRa',
        subtitle:'For treatment of bacterial infections',
        maincontent:'Treatment of serious infections of e.g. septicemia, pneumonia, lower respiratory tract infections, kidney infections',
        composition:'Composition of each tablet:',
        c1:'Piperacillin: 100 mg',
        indication:'Treatment of serious infections of e.g. septicemia, pneumonia, lower respiratory tract infections, kidney infections, skin and soft tissue infections caused by susceptible strains like: E. coli, Pseudomonas aeruginosa, Clostridium spp., Klebsiella spp.',
        dosage:'1 tablet per 1 kg bodyweight once per day for 7 days or longer if required.',
        storage:'Store below 28⁰C in cool dry place',
        packing:'30 tablets'
    },

]
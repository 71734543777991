// import { Link } from "react-router-dom";

import React, { useState } from 'react'
import Navbar from "../../navbar/Navbar";
import SubNavbar from "../../subnavbar";
import Footer from "../../footer/Footer";
// import SubNavbar from "../../subnavbar";
import { Large } from "../../../data/largeanimal";
import { Link, useNavigate } from "react-router-dom";
import { Equine } from '../../../data/equine';
import { Electric } from '../../../data/electric';
import { PoultryData } from '../../../data/poultry';
import { Falcon } from '../../../data/falcon';
import { Disinfectant } from '../../../data/disinfectant';


export default function All() {
    const [popup, setPopup] = useState([]);
    const [popuptoggle, setPopuptoggle] = useState(false);
    const changeContent = (large) => {
        setPopup([large]);
        setPopuptoggle(!popuptoggle);
    };
    // Equine  1
    const [popup1, setPopup1] = useState([]);
    const [popuptoggle1, setPopuptoggle1] = useState(false);
    const changeContent1 = (falcon) => {
        setPopup1([falcon]);
        setPopuptoggle1(!popuptoggle1);
    }

    //   Equipment  2

    const [popup2, setPopup2] = useState([]);
    const [popuptoggle2, setPopuptoggle2] = useState(false);
    const changeContent2 = (equipment) => {
        setPopup2([equipment]);
        setPopuptoggle2(!popuptoggle2);
    }

    // Poultry 3

    const [popup3, setPopup3] = useState([]);
    const [popuptoggle3, setPopuptoggle3] = useState(false);
    const changeContent3 = (poultry) => {
        setPopup3([poultry]);
        setPopuptoggle3(!popuptoggle3);
    };

    //   Poultry 4

    const [popup4, setPopup4] = useState([]);
    const [popuptoggle4, setPopuptoggle4] = useState(false);
    const changeContent4 = (falcon) => {
        setPopup4([falcon]);
        setPopuptoggle4(!popuptoggle4);
    }

    // Disinfectant 5

    const [popup5, setPopup5] = useState([]);
    const [popuptoggle5, setPopuptoggle5] = useState(false);
    const changeContent5 = (dis) => {
        setPopup5([dis]);
        setPopuptoggle5(!popuptoggle5);
    }

    const navigate = useNavigate();
    const navigateToContact = () => {
        navigate("/");
        setTimeout(() => {
            const contactSection = document.getElementById("contact");
            if (contactSection) {
                contactSection.scrollIntoView({ behavior: "smooth" });
            }
        }, 800); // Delay for smoother scroll
    }




    return (
        <>
            <Navbar />
            <SubNavbar />
            <div className="xl:px-[120px] px-[20px] lg:h-[85vh] h-[90vh] lg:flex items-center justify-between">
                <div className="xl:w-[700px] lg:w-[600px] grid content-center">
                    <div className="lg:text-[40px] text-[20px] md:text-[24px] font-bold text-[#88A398] lg:mt-20 mt-[100px]">
                        <p className="">Welcome to Middle East </p>
                        <p className="">Veterinary Supply Co. L.L.C</p>
                    </div>
                    <p className="lg:text-[18px] md:text-[16px] text-[14px] text-[#5F726F] lg:pt-10 pt-5 content-en">
                        where we are committed to [keep healthy environment through serving
                        animal health]. We are a Veterinary Medicines supply in United Arab
                        Emirates, Dubai that has been serving Large Animal Farms, Horse
                        stables, Veterinary Clinics, Large Animal Farms, Poultry Farms and
                        Falconers for more than 20 years
                    </p>
                </div>
                <div className="lg:mt-20 h-60 md:h-80 grid justify-items-center content-center">
                    <img src="../assets/product-bg.png" alt="" className="lg:h-[400px] h-[200px] md:h-[250px]" />
                </div>
            </div>

            <div id='allpro' className="flex gap-10 mt-5 md:mt-8 lg:mt-0 text-center text-[12px] md:px-[30px] lg:px-[50px] xl:px-[120px] whitespace-nowrap overflow-auto scrollbar-hide" >
                <Link to="/all" className='cursor-pointer'>
                    <p className="border-2 border-[#88A398] text-[#88A398] font-semibold rounded-2xl uppercase px-3 py-1  hover:bg-[#FFFFFF]" >
                        All
                    </p>
                </Link>
                <Link to="/large_animal">
                    <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
                        Large Animal
                    </p>
                </Link>
                <Link to="/equine">
                    <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
                        Equine
                    </p>
                </Link>
                <Link to="/electric_equipment">
                    <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
                        Vet. Equipment
                    </p>
                </Link>
                <Link to="/poultry">
                    <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
                        Poultry
                    </p>
                </Link>
                <Link to="/falcon">
                    <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
                        Falcon
                    </p>
                </Link>
                <Link to="/disinfectants">
                    <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
                        Disinfectants
                    </p>
                </Link>
            </div>

            <div className="relative py-10" >
                {/* <div className="w-full grid px-[20px] justify-items-center">
                    <p className="lg:text-[40px] xl:text-[56px] text-[26px] text-[#4E7A97]">
                        Large Animals
                    </p>
                </div> */}
                <div className="">
                    <div className="xl:px-[120px] px-[20px] pb-20 lg:grid-cols-3 md:grid-cols-2 grid w-full mt-10">
                        {Large.map((large) => {
                            return (
                                <div
                                    className="lg:min-h-[600px] min-h-[550px] xl:h-[600px] relative flex flex-col  w-auto xl:mx-10 lg:mx-2 md:mx-4 drop-shadow-2xl shadow-2 bg-white mb-10"
                                    key={large.id}
                                >
                                    <div className="flex justify-center lg:py-7 py-3">
                                        <img
                                            src={`../large/${large.image}`}
                                            alt=""
                                            className="h-[200px]"
                                        />
                                    </div>
                                    <div className="px-5">
                                        <p className="lg:text-[22px] text-[18px] font-[700] text-[#000000] lg:h-[55px] h-[45px]">
                                            {large.title}
                                        </p>

                                        <p className="lg:text-[18px] text-[16px] text-[#647794] pt-2">
                                            {large.berife}
                                        </p>
                                    </div>
                                    <button onClick={() => changeContent(large)}>
                                        <div className=" absolute bottom-0 duration-700 flex  justify-center w-full cursor-pointer hover:bg-[#E9A655] text-[#E9A655] hover:text-white transition-all">
                                            <p className="border-t-[1px] w-full p-2 grid place-content-center border-[#A8B3C4] text-[18px] lg:text-[22px]">
                                                View Products Details
                                            </p>
                                        </div>
                                    </button>
                                </div>
                            );
                        })}


                        {Equine.map((equine) => {
                            return (
                                <div className="lg:min-h-[600px] min-h-[550px] xl:h-[600px] relative flex flex-col  w-auto xl:mx-10 lg:mx-2 md:mx-4 drop-shadow-2xl shadow-2 bg-white mb-10" key={equine.id}>
                                    <div className="flex justify-center lg:py-7 py-3">
                                        <img
                                            src={`../equine/${equine.image}`}
                                            alt=""
                                            className="h-[220px]"
                                        />
                                    </div>
                                    <div className="px-5">
                                        <p className="lg:text-[22px] text-[18px] font-[700] text-[#000000] lg:h-[55px] h-[45px]">
                                            {equine.title}
                                        </p>

                                        <p className="lg:text-[18px] text-[16px] text-[#647794] pt-2">
                                            {equine.mainc1}
                                        </p>
                                    </div>
                                    <button onClick={() => changeContent1(equine)}>
                                        <div className=" absolute bottom-0 duration-700 flex  justify-center w-full cursor-pointer hover:bg-[#E9A655] text-[#E9A655] hover:text-white transition-all">
                                            <p className="border-t-[1px] border-[#A8B3C4] w-full p-2 grid place-content-center text-[18px] lg:text-[22px]">
                                                View Products Details
                                            </p>
                                        </div>
                                    </button>
                                </div>
                            )
                        })}
                        {/* electric  */}
                        {Electric.map((electric) => {
                            return (
                                <div className="lg:min-h-[600px] min-h-[550px] xl:h-[600px] relative flex flex-col  w-auto xl:mx-10 lg:mx-2 md:mx-4 drop-shadow-2xl shadow-2 bg-white mb-10" key={electric.id}>
                                    <div className="flex justify-center lg:py-7 py-3">
                                        <img
                                            src={`../electric/${electric.image}`}
                                            alt=""
                                            className="h-[220px]"
                                        />
                                    </div>
                                    <div className="px-5">
                                        <p className="lg:text-[22px] text-[18px] font-[700] text-[#000000] lg:h-[55px] h-[45px]">
                                            {electric.title}
                                        </p>

                                        <p className="lg:text-[18px] text-[16px] text-[#647794] pt-2">
                                            {electric.mainclimate}
                                        </p>
                                    </div>
                                    <button onClick={() => changeContent2(electric)}>
                                        <div className=" absolute bottom-0 duration-700 flex  justify-center w-full cursor-pointer hover:bg-[#E9A655] text-[#E9A655] hover:text-white transition-all">
                                            <p className="border-t-[1px] border-[#A8B3C4] w-full p-2 grid place-content-center text-[18px] lg:text-[22px]">
                                                View Products Details
                                            </p>
                                        </div>
                                    </button>
                                </div>
                            )
                        })}

                        {/* poultry  */}

                        {PoultryData.map((poultry) => {
                            return (
                                <div className="lg:min-h-[600px] min-h-[550px] xl:h-[600px] relative flex flex-col  w-auto xl:mx-10 lg:mx-2 md:mx-4 drop-shadow-2xl shadow-2 bg-white mb-10" key={poultry.id}>
                                    <div className="flex justify-center lg:py-7 py-3">
                                        <img
                                            src={`../poultry/${poultry.image}`}
                                            alt=""
                                            className="h-[220px]"
                                        />
                                    </div>
                                    <div className="px-5">
                                        <p className="lg:text-[22px] text-[18px] font-[700] text-[#000000] lg:h-[55px] h-[45px]">
                                            {poultry.title}
                                        </p>
                                        <p className="lg:text-[18px] text-[16px] text-[#647794] pt-2">
                                            {poultry.maincontent}
                                        </p>
                                    </div>
                                    <button onClick={() => changeContent3(poultry)}>
                                        <div className=" absolute bottom-0 duration-700 flex  justify-center w-full cursor-pointer hover:bg-[#E9A655] text-[#E9A655] hover:text-white transition-all">
                                            <p className="border-t-[1px] border-[#A8B3C4] w-full p-2 grid place-content-center text-[18px] lg:text-[22px]">
                                                View Products Details
                                            </p>
                                        </div>
                                    </button>
                                </div>
                            )
                        })}

                        {/* falcon  */}
                        {Falcon.map((falcon) => {
                            return (
                                <div className="lg:min-h-[600px] min-h-[550px] xl:h-[600px] relative flex flex-col  w-auto xl:mx-10 lg:mx-2 md:mx-4 drop-shadow-2xl shadow-2 bg-white mb-10" key={falcon.id}>
                                    <div className="flex justify-center lg:py-7 py-3">
                                        <img
                                            src={`../falcon/${falcon.image}`}
                                            alt=""
                                            className="h-[220px]"
                                        />
                                    </div>
                                    <div className="px-5">
                                        <p className="lg:text-[22px] text-[18px] font-[700] text-[#000000] lg:h-[55px] h-[45px]">
                                            {falcon.title}
                                        </p>
                                        <p className="font-[600] text-[#647794]">{falcon.subtitle}</p>

                                        <p className="lg:text-[18px] text-[16px] text-[#647794] pt-2">
                                            {falcon.maincontent}
                                        </p>
                                    </div>
                                    <button onClick={() => changeContent4(falcon)}>
                                        <div className=" absolute bottom-0 duration-700 flex  justify-center w-full cursor-pointer hover:bg-[#E9A655] text-[#E9A655] hover:text-white transition-all">
                                            <p className="border-t-[1px] border-[#A8B3C4] w-full p-2 grid place-content-center text-[18px] lg:text-[22px]">
                                                View Products Details
                                            </p>
                                        </div>
                                    </button>
                                </div>
                            )
                        })}

                        {/* dis  */}

                        {Disinfectant.map((dis) => {
                            return (
                                <div className="lg:min-h-[600px] min-h-[550px] xl:h-[600px] relative flex flex-col  w-auto xl:mx-10 lg:mx-2 md:mx-4 drop-shadow-2xl shadow-2 bg-white mb-10" key={dis.id}>
                                    <div className="flex justify-center lg:py-7 py-3">
                                        <img
                                            src={`../disinfectant_img/${dis.image}`}
                                            alt=""
                                            className="h-[220px]"
                                        />
                                    </div>
                                    <div className="px-5">
                                        <p className="lg:text-[22px] text-[18px] font-[700] text-[#000000] lg:h-[55px] h-[45px]">
                                            {dis.title}
                                        </p>

                                        <p className="lg:text-[18px] text-[16px] text-[#647794] pt-2">
                                            It is a ready-to-use product.
                                            There is no corrosion effect.
                                            It shows optimum efficiency in hard-to-reach areas with us.
                                            homogeneous distribution.
                                        </p>
                                    </div>
                                    <button onClick={() => changeContent5(dis)}>
                                        <div className=" absolute bottom-0 duration-700 flex  justify-center w-full cursor-pointer hover:bg-[#E9A655] text-[#E9A655] hover:text-white transition-all">
                                            <p className="border-t-[1px] border-[#A8B3C4] w-full p-2 grid place-content-center text-[18px] lg:text-[22px]">
                                                View Products Details
                                            </p>
                                        </div>
                                    </button>
                                </div>
                            )
                        })}


                    </div>

                    {/* popup  */}

                    {popuptoggle && (
                        <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-50">
                            {popup.map((pop) => {
                                return (
                                    <>
                                        <div className="lg:w-[510px] md:w-[450px] bg-white pb-10 opacity-100 float-right h-full lg:p-10 p-4 m-4 md:m-0 overflow-y-scroll">
                                            <div className="flex justify-between">
                                                <p className="lg:text-[32px] text-[18px] font-[600] text-[#88A398] lg:w-[350px] w-[200px] md:w-[300px]">
                                                    {pop.title}
                                                </p>
                                                <button onClick={changeContent} className="cursor-pointer">
                                                <p className="text-[30px] font-[500] border rounded-[50%] w-[50px] text-[#88A398] border-[#88A398]">x</p>  {/* <img src="../assets/exit.png" alt="" className="lg:h-10 h-7" /> */} {/* <img src="./assets/exit.png" alt="" className="lg:h-10 h-7" /> */}
                                                </button>
                                            </div>
                                            <div className="text-[#88A398]">
                                                <p className="lg:text-[16px] text-[12px] underline underline-offset-4">
                                                    Target species & dose
                                                </p>
                                                <p className="lg:text-[20px] text-[14px]">
                                                    Dosage & Administration Large Animals:
                                                </p>
                                            </div>
                                            <hr className="border-[#88A398] opacity-[40%]" />
                                            <div className="flex justify-center py-3 bg-[#DBE5EB] my-5">
                                                <img
                                                    src={`../large/${pop.image}`}
                                                    alt=""
                                                    className="h-[180px]"
                                                />
                                            </div>
                                            <div className="lg:text-[16px] text-[14px] text-[#7088A0]">
                                                <p className="pb-2">
                                                    {pop.c1}
                                                </p>
                                                <div className="pb-5">
                                                    <p className="font-[600]">{pop.h2}</p>
                                                    {pop.c2.map((list1) => (
                                                        <ul key={list1.id}>
                                                            <li>{list1.l1}</li>
                                                            <li>{list1.l2}</li>
                                                            <li>{list1.l3}</li>
                                                            <li>{list1.l4}</li>
                                                        </ul>
                                                    ))}

                                                </div>
                                                <div className="pb-5">
                                                    <p className="font-[600]">{pop.h3}</p>
                                                    <p className="font-[600]">{pop.subhead}</p>
                                                </div>
                                                {pop.c3.map((list2) => (
                                                    <div className="pb-5" key={list2.id}>
                                                        <ul>
                                                            <li className="font-[600]">{list2.h1}</li>
                                                            <li className="">{list2.l1}</li>
                                                            <li className="font-[600]">{list2.h2}</li>
                                                            <li className="">{list2.l2}</li>
                                                            <li className="font-[600]">{list2.h3}</li>
                                                            <li className="">{list2.l3}</li>
                                                            <li className="font-[600]">{list2.h4}</li>
                                                            <li className="">{list2.l4}</li>
                                                            <li className="font-[600]">{list2.h5}</li>
                                                            <li className="">{list2.l5}</li>
                                                            <li className="font-[600]">{list2.h6}</li>
                                                            <li className="">{list2.l6}</li>
                                                        </ul>
                                                    </div>
                                                ))}
                                                <div className="pb-5">
                                                    <p className="font-[600]">{pop.h4}</p>
                                                    <p className="">{pop.c4}</p>
                                                </div>
                                                <div className="flex justify-center">

                                                    <button className="bg-[#88A398] w-[160px] h-10 rounded-[10px] text-white text-center p-2" onClick={navigateToContact}>
                                                        Contact Us
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    )}


                    <div className="xl:px-[120px] px-[20px] pb-0 lg:grid-cols-3 md:grid-cols-2 grid w-full mt-0">

                    </div>

                    {popuptoggle1 && (
                        <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-50 ">
                            {popup1.map((pop) => {
                                return (
                                    <div className="lg:w-[510px] md:w-[450px] bg-white pb-10 opacity-100 md:float-right h-full lg:p-10 p-4 m-4 md:m-0 overflow-y-scroll">
                                        <div className="flex justify-between">
                                            <p className="lg:text-[32px] text-[18px] font-[600] text-[#88A398] lg:w-[350px] w-[200px] md:w-[300px]">
                                                {pop.title}
                                            </p>
                                            <button onClick={changeContent1} className="cursor-pointer">
                                            <p className="text-[30px] font-[500] border rounded-[50%] w-[50px] text-[#88A398] border-[#88A398]">x</p>  {/* <img src="../assets/exit.png" alt="" className="lg:h-10 h-7" /> */}  {/* <img src="../assets/exit.png" alt="" className="lg:h-10 h-7" /> */}
                                            </button>
                                        </div>
                                        <div className="text-[#88A398]">
                                            <p className="lg:text-[20px] text-[14px]">
                                                {pop.h}
                                            </p>
                                        </div>
                                        <hr className="border-[#88A398] opacity-[40%]" />
                                        <div className="flex justify-center py-3 bg-[#DBE5EB] my-5">
                                            <img
                                                src={`../equine/${pop.image}`}
                                                alt=""
                                                className="h-[180px]"
                                            />
                                        </div>
                                        <div className="lg:text-[16px] text-[14px] text-[#7088A0]">
                                            <div className="pb-5">
                                                <p className="font-[600]">{pop.h1}</p>
                                                <p className="">
                                                    {pop.c1.map((list1) => (
                                                        <ul className="" key={list1.id}>
                                                            <li>{list1.l1}</li>
                                                            <li>{list1.l2}</li>
                                                            <li>{list1.l3}</li>
                                                        </ul>
                                                    ))}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="lg:text-[16px] text-[14px] text-[#7088A0]">
                                            <div className="pb-5">
                                                <p className="font-[600]">{pop.h2}</p>
                                                <p className="">
                                                    {pop.c2.map((list2) => (
                                                        <ul className="" key={list2.id}>
                                                            <li>{list2.l1}</li>
                                                            <li>{list2.l2}</li>
                                                            <li>{list2.l3}</li>
                                                            <li>{list2.l4}</li>
                                                            <li>{list2.l5}</li>

                                                        </ul>
                                                    ))}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="lg:text-[16px] text-[14px] text-[#7088A0]">
                                            <div className="pb-5">
                                                <p className="font-[600]">{pop.h3}</p>
                                                <p className="">
                                                    {pop.c3.map((list3) => (
                                                        <ul className="" key={list3.id}>
                                                            <li>{list3.l1}</li>
                                                            <li>{list3.l2}</li>
                                                            <li>{list3.l3}</li>
                                                            <li>{list3.l4}</li>
                                                        </ul>
                                                    ))}
                                                </p>
                                            </div>
                                        </div>
                                        {/* <div className="lg:text-[16px] text-[14px] text-[#7088A0]">
                  <div className="pb-5">
                    <p className="font-[600]">head</p>
                    <p className="">Content</p>
                  </div>
                </div> */}


                                        <div className="flex justify-center">
                                            <button className="bg-[#88A398] w-[160px] h-10 rounded-[10px] text-white" onClick={navigateToContact}>
                                                Contact Us
                                            </button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}



                </div>
            </div>


            {/* EQUINE  */}

            {/* EQUIPMENT  */}

            <div className="">
                {/* <div className="w-full grid px-[20px] justify-items-center">
                    <p className="lg:text-[40px] xl:text-[56px] text-[26px] text-[#4E7A97]">
                        Equipment
                    </p>
                </div> */}
                <div className="xl:px-[120px] px-[20px] pb-0 lg:grid-cols-3 md:grid-cols-2 grid w-full mt-0">

                </div>

                {popuptoggle2 && (
                    <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-50 ">
                        {popup2.map((pop) => {
                            return (
                                <div className="lg:w-[510px] md:w-[450px] bg-white pb-10 opacity-100 md:float-right h-full lg:p-10 p-4 m-4 md:m-0 overflow-y-scroll">
                                    <div className="flex justify-between">
                                        <p className="lg:text-[32px] text-[18px] font-[600] text-[#88A398] lg:w-[350px] w-[200px] md:w-[300px]">
                                            {pop.title}
                                        </p>
                                        <button onClick={changeContent2} className="cursor-pointer">
                                        <p className="text-[30px] font-[500] border rounded-[50%] w-[50px] text-[#88A398] border-[#88A398]">x</p>  {/* <img src="../assets/exit.png" alt="" className="lg:h-10 h-7" /> */} {/* <img src="../assets/exit.png" alt="" className="lg:h-10 h-7" /> */}
                                        </button>
                                    </div>
                                    {/* <div className="text-[#88A398]">
                  <p className="lg:text-[16px] text-[12px] underline underline-offset-4">
                    Target species & dose
                  </p>
                  <p className="lg:text-[20px] text-[14px]">
                    Dosage & Administration Poultry:
                  </p>
                </div> */}
                                    <hr className="border-[#88A398] opacity-[40%] mt-3" />
                                    <div className="flex justify-center py-3 bg-[#DBE5EB] my-5">
                                        <img
                                            src={`../electric/${pop.image}`}
                                            alt=""
                                            className="h-[180px]"
                                        />
                                    </div>
                                    <div className="lg:text-[16px] text-[14px] text-[#7088A0]">
                                        <div className="pb-5">
                                            <p className="font-[600]">{pop.subhead}</p>
                                            <p className="">{pop.mainclimate}</p>
                                        </div>
                                    </div>


                                    <div className="flex justify-center">
                                        <button className="bg-[#88A398] w-[160px] h-10 rounded-[10px] text-white" onClick={navigateToContact}>
                                            Contact Us
                                        </button>

                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>

            {/* Poultry  */}

            <div className="">
                {/* <div className="w-full grid px-[20px] justify-items-center">
                    <p className="lg:text-[40px] xl:text-[56px] text-[26px] text-[#4E7A97]">
                        Poultry
                    </p>
                </div> */}
                <div className="xl:px-[120px] px-[20px] pb-0 lg:grid-cols-3 md:grid-cols-2 grid w-full mt-0">

                </div>
                {/* popup  */}

                {popuptoggle3 && (
                    <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-50 ">
                        {popup3.map((pop) => {
                            return (
                                <div className="lg:w-[510px] md:w-[450px] bg-white pb-10 opacity-100 md:float-right h-full lg:p-10 p-4 m-4 md:m-0 overflow-y-scroll">
                                    <div className="flex justify-between">
                                        <p className="lg:text-[32px] text-[18px] font-[600] text-[#88A398] lg:w-[350px] w-[200px] md:w-[300px]">
                                            {pop.title}
                                        </p>
                                        <button onClick={changeContent3} className="cursor-pointer">
                                        <p className="text-[30px] font-[500] border rounded-[50%] w-[50px] text-[#88A398] border-[#88A398]">x</p>  {/* <img src="../assets/exit.png" alt="" className="lg:h-10 h-7" /> */}   {/* <img src="../assets/exit.png" alt="" className="lg:h-10 h-7" /> */}
                                        </button>
                                    </div>
                                    <div className="text-[#88A398]">
                                        <p className="lg:text-[16px] text-[12px] underline underline-offset-4">
                                            Target species & dose
                                        </p>
                                        <p className="lg:text-[20px] text-[14px]">
                                            Dosage & Administration Poultry:
                                        </p>
                                    </div>
                                    <hr className="border-[#88A398] opacity-[40%]" />
                                    <div className="flex justify-center py-3 bg-[#DBE5EB] my-5">
                                        <img
                                            src={`../poultry/${pop.image}`}
                                            alt=""
                                            className="h-[180px]"
                                        />
                                    </div>
                                    <div className="lg:text-[16px] text-[14px] text-[#7088A0]">
                                        <div className="pb-5">
                                            <p className="font-[600]">{pop.h1}</p>
                                            <p className="">{pop.c1}</p>
                                        </div>
                                        <p className="font-[600] pb-2">{pop.h2}</p>
                                        {pop.c2.map((list1) => (
                                            <div className="" key={list1.id}>
                                                <ul>
                                                    <li className="font-[600]">{list1.h1}</li>
                                                    <li>{list1.l1}</li>
                                                    <li className="font-[600]">{list1.h2}</li>
                                                    <li>{list1.l2}</li>
                                                    <li className="font-[600]">{list1.h3}</li>
                                                    <li>{list1.l3}</li>
                                                    <li className="font-[600]">{list1.h4}</li>
                                                    <li>{list1.l4}</li>
                                                    <li className="font-[600]">{list1.h5}</li>
                                                    <li>{list1.l5}</li>

                                                </ul>
                                            </div>
                                        ))}
                                        <p className="font-[600]">{pop.b1}</p>
                                        {pop.b2.map((ben) => (
                                            <ul className="" key={ben.id}>
                                                <li>{ben.l1}</li>
                                                <li>{ben.l2}</li>
                                                <li>{ben.l3}</li>
                                                <li>{ben.l4}</li>
                                                <li>{ben.l5}</li>
                                                <li>{ben.l6}</li>

                                            </ul>
                                        ))}


                                        <div className="pb-5">
                                            <p className="font-[600]">{pop.h3}</p>
                                            <p className="">{pop.c3}</p>
                                        </div>

                                    </div>



                                    <div className="flex justify-center">
                                        <button className="bg-[#88A398] w-[160px] h-10 rounded-[10px] text-white" onClick={navigateToContact}>
                                            Contact Us
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>

            {/* Falcon  */}

            <div className="">
                {/* <div className="w-full grid px-[20px] justify-items-center">
                    <p className="lg:text-[40px] xl:text-[56px] text-[26px] text-[#4E7A97]">
                        Falcon
                    </p>
                </div> */}
                <div className="xl:px-[120px] px-[20px] pb-0 lg:grid-cols-3 md:grid-cols-2 grid w-full mt-0">

                </div>
                {/* popup  */}
                {popuptoggle4 && (
                    <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-50 ">
                        {popup4.map((pop) => {
                            return (
                                <div className="lg:w-[510px] md:w-[450px] bg-white pb-10 opacity-100 md:float-right h-full lg:p-10 p-4 m-4 md:m-0 overflow-y-scroll">
                                    <div className="flex justify-between">
                                        <p className="lg:text-[32px] text-[18px] font-[600] text-[#88A398] lg:w-[350px] w-[200px] md:w-[300px]">
                                            {pop.title}
                                        </p>
                                        <button onClick={changeContent4} className="cursor-pointer">
                                        <p className="text-[30px] font-[500] border rounded-[50%] w-[50px] text-[#88A398] border-[#88A398]">x</p>  {/* <img src="../assets/exit.png" alt="" className="lg:h-10 h-7" /> */}   {/* <img src="../assets/exit.png" alt="" className="lg:h-10 h-7" /> */}
                                        </button>
                                    </div>
                                    <div className="text-[#88A398]">
                                        <p className="lg:text-[16px] text-[12px] underline underline-offset-4">
                                            Target species & dose
                                        </p>
                                        <p className="lg:text-[20px] text-[14px]">
                                            Dosage & Administration:
                                        </p>
                                    </div>
                                    <hr className="border-[#88A398] opacity-[40%]" />
                                    <div className="flex justify-center py-2 bg-[#DBE5EB] my-5">
                                        <img
                                            src={`../falcon/${pop.image}`}
                                            alt=""
                                            className="h-[220px]"
                                        />
                                    </div>
                                    <div className="text-[#7088A0]">
                                        <div className="pb-2">
                                            <p className="font-[600]">{pop.subtitle}</p>
                                        </div>
                                        <div className="lg:text-[16px] text-[14px] ">
                                            <div className="pb-5">
                                                <p className="font-[600]">{pop.composition}</p>
                                                <p className="">{pop.c1}</p>
                                            </div>
                                            {/* indication  */}
                                            <div className="pb-5">
                                                <p className="font-[600]">Indication:</p>
                                                <p className="">{pop.indication}</p>
                                            </div>
                                            {/* dosage  */}
                                            <div className="pb-5">
                                                <p className="font-[600]">Dosage:</p>
                                                <p className="">{pop.dosage}</p>
                                            </div>
                                            {/* storage  */}
                                            <div className="pb-5">
                                                <p className="font-[600]">Storage:</p>
                                                <p className="">{pop.storage}</p>
                                            </div>
                                            {/* packging */}
                                            <div className="pb-5">
                                                <p className="font-[600]">Packing:</p>
                                                <p className="">{pop.packing}</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="flex justify-center">
                                        <button className="bg-[#88A398] w-[160px] h-10 rounded-[10px] text-white" onClick={navigateToContact}>
                                            Contact Us
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>

            {/* Disinfectant  */}

            <div className="">
                {/* <div className="w-full grid px-[20px] justify-items-center">
                    <p className="lg:text-[40px] xl:text-[56px] text-[26px] text-[#4E7A97]">
                        Disinfectant
                    </p>
                </div> */}
                <div className="xl:px-[120px] px-[20px] pb-0 lg:grid-cols-3 md:grid-cols-2 grid w-full mt-0">

                </div>

                {popuptoggle5 && (
                    <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-50 ">
                        {popup5.map((pop) => {
                            return (
                                <div className="lg:w-[510px] md:w-[450px] bg-white pb-10 opacity-100 md:float-right h-full lg:p-10 p-4 m-4 md:m-0 overflow-y-scroll">
                                    <div className="flex justify-between">
                                        <p className="lg:text-[32px] text-[18px] font-[600] text-[#88A398] lg:w-[350px] w-[200px] md:w-[300px]">
                                            {pop.title}
                                        </p>
                                        <button onClick={changeContent5} className="cursor-pointer">
                                        <p className="text-[30px] font-[500] border rounded-[50%] w-[50px] text-[#88A398] border-[#88A398]">x</p>  {/* <img src="../assets/exit.png" alt="" className="lg:h-10 h-7" /> */}     {/* <img src="../assets/exit.png" alt="" className="lg:h-10 h-7" /> */}
                                        </button>
                                    </div>
                                    {/* <div className="text-[#88A398]">
                  <p className="lg:text-[16px] text-[12px] underline underline-offset-4">
                    Target species & dose
                  </p>
                  <p className="lg:text-[20px] text-[14px]">
                    Dosage & Administration Poultry:
                  </p>
                </div> */}
                                    <hr className="border-[#88A398] opacity-[40%] mt-3" />
                                    <div className="flex justify-center py-3 bg-[#DBE5EB] my-5">
                                        <img
                                            src={`../disinfectant_img/${pop.image}`}
                                            alt=""
                                            className="h-[180px]"
                                        />
                                    </div>
                                    <div className="lg:text-[16px] text-[14px] text-[#7088A0]">
                                        <div className="pb-5">
                                            <p className="font-[600] pb-2">{pop.subtitle}</p>
                                            <p className="font-[600] pb-2">{pop.subtitle2}</p>
                                            {/* <p className="">Content</p> */}
                                        </div>

                                        {pop.c.map((list) => (
                                            <ul key={list.id}>
                                                <li className='py-[2px]]'>{list.l1}</li>
                                                <li className='py-[2px]]'>{list.l2}</li>
                                                <li className='py-[2px]]'>{list.l3}</li>
                                                <li className='py-[2px]]'>{list.l4}</li>
                                                <li className='py-[2px]]'>{list.l5}</li>

                                            </ul>
                                        ))}
                                    </div>


                                    <div className="flex justify-center mt-3">
                                        <button className="bg-[#88A398] w-[160px] h-10 rounded-[10px] text-white" onClick={navigateToContact}>
                                            Contact Us
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>



            <Footer />
        </>
    )
}

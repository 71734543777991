import React, { useState, useEffect, useRef } from "react";
import SubNavbar from "../../subnavbar";
import { Poultry_Arabic_Data } from "../../../data_arabic/poultry_arabic_data";
import Navigator from "../../navigator";
import { Link, useNavigate } from "react-router-dom";
import Navbar_Arabic from '../../navbar_arabic/Navbar_Arabic'
import Subnav_Arabic from '../../subnavbar_arabic/Subnav_Arabic'
import Footer_Arabic from "../../footer_arabic/Footer_Arabic";
// import { useEffect } from "react";
import { useLocation } from "react-router-dom";
function Poultry_Arabic() {
  const [popup, setPopup] = useState([]);
  const [popuptoggle, setPopuptoggle] = useState(false);
  const changeContent = (poultry) => {
    setPopup([poultry]);
    setPopuptoggle(!popuptoggle);
  };

  // useEffect(()=>{
  //   document.addEventListener("click", handleClickOutside, true)
  // }, [])

  // const refOne = useRef(null)

  // const handleClickOutside = (e) =>{
  //   if(!refOne.current.contains(e.target)){
  //  console.log("clicked outside")
  //   } else{
  //     console.log("clicked inside")
  //   }
  // }

  const navigate = useNavigate();
  const navigateToContact = () => {
  navigate("/arabic");
  setTimeout(() => {
   const contactSection = document.getElementById("contact_arabic");
   if (contactSection) {
     contactSection.scrollIntoView({ behavior: "smooth" });
   }
 }, 800); // Delay for smoother scroll
}


const navigateToAll = () => {
  navigate("/all_arabic");
  setTimeout(() => {
    const allSection = document.getElementById("all_arabic");
    if (allSection) {
      allSection.scrollIntoView({ behavior: "smooth" });
    }
  }, 45); // Delay for smoother scroll
}


const { pathname } = useLocation();

useEffect(() => {
  window.scrollTo(0, 550);
}, [pathname]);
  return (
    <>
      <div className="banner-arabic">
        <Navbar_Arabic />
      </div>
      <div className="banner-arabic">
        <Subnav_Arabic />
      </div>

      <div className="xl:px-[120px] px-[20px] lg:h-[85vh] h-[90vh] lg:flex items-center justify-between banner-arabic">
        <div className="xl:w-[700px] lg:w-[600px] grid content-center">
          <div className="lg:text-[40px] text-[20px] md:text-[24px] font-bold text-[#88A398] lg:mt-20 mt-[100px]">
            <p className="">مرحبا بكم في الشرق الأوسط </p>
            <p className="">شركة التموين البيطري ذ.م.م</p>
          </div>
          <p className="lg:text-[22px] md:text-[16px] text-[14px] text-[#5F726F] lg:pt-10 pt-5 content-en">
          حيث نلتزم ب [الحفاظ على بيئة صحية من خلال خدمة صحة الحيوان]. نحن توريد الأدوية البيطرية في الإمارات العربية المتحدة ، دبي التي تخدم مزارع الحيوانات الكبيرة ، اسطبلات الخيول ، العيادات البيطرية ، مزارع الحيوانات الكبيرة ، مزارع الدواجن والصقارين لأكثر من 20 عاما
          </p>
        </div>
        <div className="lg:mt-20 h-60 md:h-80 grid justify-items-center content-center">
          <img src="../assets/product-bg.png" alt="" className="lg:h-[400px] h-[200px] md:h-[250px]" />
        </div>
      </div>

      {/* navigator */}

      <div className="px-5 banner-arabic flex gap-10 mt-10 lg:mt-0 text-center text-[20px] md:px-[30px] lg:px-[50px] xl:px-[120px] whitespace-nowrap overflow-auto scrollbar-hide">
        
          <p className="cursor-pointer border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white" onClick={navigateToAll}>
          كل
          </p>
        
        <Link to="/large_arabic">
          <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
          الحيوانات الكبيرة
          </p>
        </Link>
        <Link to="/equine_arabic">
          <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
          فرسي
          </p>
        </Link>
        <Link to="/electric_arabic">
          <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
          معدات
          </p>
        </Link>
        <Link to="/poultry_arabic">
          <p className="border-2 border-[#88A398] text-[#88A398] font-semibold rounded-2xl uppercase px-3 py-1  hover:bg-[#FFFFFF]">
          دواجن
          </p>
        </Link>
        <Link to="/falcon_arabic">
          <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
          صقر
          </p>
        </Link>
        <Link to="/dis_arabic">
          <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
          المطهرات
          </p>
        </Link>
      </div>


      {/* products */}

      <div className="xl:px-[120px] px-[20px] pb-20 lg:grid-cols-3 md:grid-cols-2 grid w-full mt-10 banner-arabic">
        {Poultry_Arabic_Data.map((poultry) => {
          return (
            <div className="lg:min-h-[600px] min-h-[550px] xl:h-[600px] relative flex flex-col  w-auto xl:mx-10 lg:mx-2 md:mx-4 drop-shadow-2xl shadow-2 bg-white mb-10" key={poultry.id}>
              <div className="flex justify-center lg:py-7 py-3">
                <img
                  src={`../poultry/${poultry.image}`}
                  alt=""
                  className="h-[220px]"
                />
              </div>
              <div className="px-5">
                <p className="lg:text-[22px] text-[18px] font-[700] text-[#000000] lg:h-[55px] h-[45px]">
                  {poultry.title}
                </p>
                <p className="lg:text-[20px] text-[16px] text-[#647794] pt-2">
                  {poultry.maincontent}
                </p>
              </div>
              <button onClick={() => changeContent(poultry)}>
                <div className=" absolute bottom-0 duration-700 flex  justify-center w-full cursor-pointer hover:bg-[#E9A655] text-[#E9A655] hover:text-white transition-all">
                  <p className="border-t-[1px] border-[#A8B3C4] w-full p-2 grid place-content-center text-[18px] lg:text-[24px]">
                  عرض تفاصيل المنتجات
                  </p>
                </div>
              </button>
            </div>
          )
        })}
      </div>
      {/* popup  */}

      {popuptoggle && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-50 ">
          {popup.map((pop) => {
            return (
              <div className="lg:w-[510px] md:w-[450px] bg-white pb-10 opacity-100 md:float-right h-full lg:p-10 p-4 m-4 md:m-0 overflow-y-scroll">
                <div className="flex justify-between banner-arabic">
                  <p className="lg:text-[32px] text-[18px] font-[600] text-[#88A398] lg:w-[350px] w-[200px] md:w-[300px]">
                    {pop.title}
                  </p>
                  <button onClick={changeContent} className="cursor-pointer">
                   
                  <p className="text-[30px] font-[500] border rounded-[50%] w-[50px] text-[#88A398] border-[#88A398]">x</p> {/* <img src="../assets/exit.png" alt="" className="lg:h-10 h-7" /> */}
                  </button>
                </div>
                <div className="text-[#88A398] banner-arabic">
                  <p className="lg:text-[20px] text-[12px] underline underline-offset-4">
                  الأنواع المستهدفة والجرعة
                  </p>
                  <p className="lg:text-[24px] text-[14px]">
                  الجرعة وإعطاء الدواجن:
                  </p>
                </div>
                <hr className="border-[#88A398] opacity-[40%] " />
                <div className="flex justify-center py-3 bg-[#DBE5EB] my-5 banner-arabic">
                  <img
                    src={`../poultry/${pop.image}`}
                    alt=""
                    className="h-[180px]"
                  />
                </div>
                <div className="lg:text-[20px] text-[14px] text-[#7088A0] banner-arabic">
                  <div className="pb-5">
                    <p className="font-[600]">{pop.h1}</p>
                    <p className="">{pop.c1}</p>
                  </div>
                  <p className="font-[600] pb-3">{pop.h2}</p>
                  {pop.c2.map((list1) => (
                    <div className="" key={list1.id}>
                      <ul>
                        <li className="font-[600]">{list1.h1}</li>
                        <li className="mb-3">{list1.l1}</li>
                        <li className="font-[600]">{list1.h2}</li>
                        <li className="mb-3">{list1.l2}</li>
                        <li className="font-[600]">{list1.h3}</li>
                        <li className="mb-3">{list1.l3}</li>
                        <li className="font-[600]">{list1.h4}</li>
                        <li className="mb-3">{list1.l4}</li>
                        <li className="font-[600]">{list1.h5}</li>
                        <li className="mb-3">{list1.l5}</li>

                      </ul>
                    </div>
                  ))}
                  <p className="font-[600]">{pop.b1}</p>
                  {pop.b2.map((ben) => (
                    <ul className="" key={ben.id}>
                      <li>{ben.l1}</li>
                      <li>{ben.l2}</li>
                      <li>{ben.l3}</li>
                      <li>{ben.l4}</li>
                      <li>{ben.l5}</li>
                      <li>{ben.l6}</li>

                    </ul>
                  ))}


                  <div className="pb-5">
                    <p className="font-[600]">{pop.h3}</p>
                    <p className="">{pop.c3}</p>
                  </div>

                </div>



                <div className="flex justify-center">
                <button className="bg-[#88A398] w-[170px] h-12 rounded-[10px] text-white text-center p-2 font-[600] text-[22px]" onClick={navigateToContact}>
                  اتصل بنا
                  </button>
                </div>
              </div>
            )
          })}
        </div>
      )}
      <div className="banner-arabic">
        <Footer_Arabic />
      </div>
    </>
  );
}
export default Poultry_Arabic;

export const PoultryData = [
    {
        id:1,
        slug:'aa1',
        title:'APSA POLIVIT AD3EKC',
        image:'APSA Polivit.png',
        maincontent:'Nutritional supplement containing liposoluble vitamins, indicated to compensate for deficiencies derived from malabsorption syndrome and in severe cases of coccidiosis.',
        h1:'Indications :',
        c1:'Nutritional supplement containing liposoluble vitamins, indicated to compensate for deficiencies derived from malabsorption syndrome and in severe cases of coccidiosis.',
        h2:'Target species and dose: ',
        c2:[
            {
                h1:'Poultry, except geese and pigeons:',
                l1:'60 mL/1,000 L of drinking water during the first two weeks of life.',
                h2:'',
                l2:'',
                h3:'',
                l3:'',
                h4:'',
                l4:'',
            }
        ],
        h3:'Form and packaging:',
        c3:' Liquid. 1 L',
        b1:'BENEFITS :',
        b2:[
            {
                l1:'',
                l1:'',
                l1:'',
                l1:'',
                l1:'',
                l1:'',
            }
        ],

    },
    {
        id:2,
        slug:'aa1',
        title:'APSA POLIVITESEL',
        image:'APSA Polivitsel.png',
        maincontent:'Nutritional supplement based on vitamin E and selenium, indicated to improve the quality of meat,avoid encephalomalacia and white muscle disease and improve fertility in breeders.',
        h1:'Indications :',
        c1:'Nutritional supplement based on vitamin E and selenium, indicated to improve the quality of meat,avoid encephalomalacia and white muscle disease and improve fertility in breeders.',
        h2:'Target species and dose:  ',
        c2:[
            {
                h1:'Poultry farming:',
                l1:'1 mL/L of drinking water for 3 - 5 days. ',
                h2:'Piglets:',
                l2:' 1 mL/L of drinking water for 5 days.',
                h3:'Breeding pigs and pigs for fattening: ',
                l3:'0.5 mL/L of drinking water for 5 - 7 days. ',
                h4:' Cattle for fattening:',
                l4:'mL/L of drinking water for 5 days.',
                h5:'Sheep/goats:',
                l5:' 1 mL/L of drinking water for 5 days.'
            }
        ],
        h3:'Form and packaging:',
        c3:' Liquid. 1 L',
        b1:'BENEFITS :',
        b2:[
            {
                l1:'',
                l1:'',
                l1:'',
                l1:'',
                l1:'',
                l1:'',
            }
        ],
    },
    {
        id:3,
        slug:'aa1',
        title:'APSA B COMPLEX + K3 + CHOLINE',
        image:'APSA B.png',
        maincontent:'Nutritional supplement based on B-group and K3 vitamins and choline, indicated for recovery of liver function in case of serious affectation.',
        h1:'Indications :',
        c1:'Nutritional supplement based on B-group and K3 vitamins and choline, indicated for recovery of liver function in case of serious affectation.',
        h2:'Target species and dose:  ',
        c2:[
            {
                h1:' Poultry farming, swine and rabbits:',
                l1:'1 mL/L of drinking water for 5 - 7 days.  ',
                h2:'Piglets and laying poultry:',
                l2:' 2 mL/L of drinking water for 5 - 7 days. ',
                h3:'Ruminants:',
                l3:'1 - 2 mL/L of drinking water for 5 - 7 days ',
                h4:' ',
                l4:'',
                h5:'',
                l5:''
            }
        ],
        h3:'Form and packaging:',
        c3:' Liquid. 1 L',
        b1:'BENEFITS :',
        b2:[
            {
                l1:'',
                l1:'',
                l1:'',
                l1:'',
                l1:'',
                l1:'',
            }
        ],
    },
    {
        id:4,
        slug:'a1',
        title:'AminoFIX',
        image:'Aminofix.png',
        maincontent:'AminoFIX vet helps in protein formation. It acts as building blocking agent for the development of meat production. It also uses to increase egg & milk production. ',
        h1:'',
        c1:'AminoFIX vet helps in protein formation. It acts as building blocking agent for the development of meat production. It also uses to increase egg & milk production. Supporting therapy on the operation & after operation, convalescing, dehydration, weakness, vomiting, diarrhea, imbalance of electrolytes, ketosis, anaphylaxis, acidosis and hypoproteinemia',
        h2:'Dosage & Administration',
        c2:[
            {
                h1:'Poultry :',
                l1:'Add 1ml per 1 litre water continue for 3-5 days. For Cattle, Buffalo, Horse, Goat, Sheep, Dog & other animals',
                h2:'Normal dose :',
                l2:'2-3 ml per 10 kg body weight in drinking water. ',
                h3:'Beef fattening dose : ',
                l3:'4-6 ml per 10kg body weight in drinking water',
                h4:'Milk increaser dose : ',
                l4:'4-6 ml per 10kg body weight in drinking water. Or as advised by the registered veterinarian',
                h5:'',
                l5:'',
            }
        ],
        h3:'Presentation :',
        c3:'1 L',
        b1:'BENEFITS :',
        b2:[
            {
                l1:'',
                l1:'',
                l1:'',
                l1:'',
                l1:'',
                l1:'',
            }
        ],
    },
    {
        id:5,
        slug:'a1',
        title:'Cal-D-Phos',
        image:'Cal D.png',
        maincontent:'Liquid calcium & phosphorus preparation for better animal health and productivity',
        h1:'',
        c1:'',
        h2:'DOSAGE : LIVESTOCK',
        c2:[
            {
                h1:'Cattle & Buffaloes / Horses :',
                l1:'100 ml./ day / 7-10 days in a month.',
                h2:'Calves : ',
                l2:'40 ml./ day / 7 days in a month.',
                h3:'Dogs : ',
                l3:'5 ml. / day.',
                h4:'Sheep & Goats :',
                l4:'10 ml. / day – 7 days in a month.(or) as advised by a veterinarian',
                h5:'PRESENTATION :',
                l5:'500 ml., 1ltr. plastic containers',
            }
        ],
        b1:'BENEFITS :',
        b2:[
            {
                l1:'· Improves milk production to its maximum possible extent.',
                l2:'· Helps for better bone and skeletol strength.',
                l3:'· Prevents hypocalcimia and milk fever.',
                l4:'· Prevents complications like dystocia, retention of placenta, metritis, mastitis etc.',
                l5:'· Increases growth and body weights of calves.',
                l6:'· Improves health, vigour, appetite,bone strength and growth of dogs',
            }

        ],
        h3:'',
        c3:''
    }
]
import React, { useState } from 'react'

import { Link, useNavigate } from "react-router-dom";
import Navbar_Arabic from '../../navbar_arabic/Navbar_Arabic';
import Subnav_Arabic from '../../subnavbar_arabic/Subnav_Arabic';
import { Large_Arabic_Data } from '../../../data_arabic/large_arabic_data';
import { Equine_Arabic_Data } from '../../../data_arabic/equine_arabic_data';
import { Electric_Arabic_Data } from '../../../data_arabic/electric_arabic_data';
import { Poultry_Arabic_Data } from '../../../data_arabic/poultry_arabic_data';
import { Falcon_Arabic_Data } from '../../../data_arabic/falcon_arabic_data';
import { Disinfectant_Arabic_Data } from '../../../data_arabic/disinfectant_arabic_data';
import Footer_Arabic from '../../footer_arabic/Footer_Arabic';

export default function All_Arabic() {

  const navigate = useNavigate();
  const navigateToContact = () => {
    navigate("/arabic");
    setTimeout(() => {
      const contactSection = document.getElementById("contact_arabic");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 800); // Delay for smoother scroll
  }

  const [popup, setPopup] = useState([]);
  const [popuptoggle, setPopuptoggle] = useState(false);
  const changeContent = (large) => {
    setPopup([large]);
    setPopuptoggle(!popuptoggle);
  };

  const [popup1, setPopup1] = useState([]);
  const [popuptoggle1, setPopuptoggle1] = useState(false);
  const changeContent1 = (falcon) => {
    setPopup1([falcon]);
    setPopuptoggle1(!popuptoggle1);
  }

  //   Equipment  2

  const [popup2, setPopup2] = useState([]);
  const [popuptoggle2, setPopuptoggle2] = useState(false);
  const changeContent2 = (equipment) => {
    setPopup2([equipment]);
    setPopuptoggle2(!popuptoggle2);
  }

  // Poultry 3

  const [popup3, setPopup3] = useState([]);
  const [popuptoggle3, setPopuptoggle3] = useState(false);
  const changeContent3 = (poultry) => {
    setPopup3([poultry]);
    setPopuptoggle3(!popuptoggle3);
  };

  //   Poultry 4

  const [popup4, setPopup4] = useState([]);
  const [popuptoggle4, setPopuptoggle4] = useState(false);
  const changeContent4 = (falcon) => {
    setPopup4([falcon]);
    setPopuptoggle4(!popuptoggle4);
  }

  // Disinfectant 5

  const [popup5, setPopup5] = useState([]);
  const [popuptoggle5, setPopuptoggle5] = useState(false);
  const changeContent5 = (dis) => {
    setPopup5([dis]);
    setPopuptoggle5(!popuptoggle5);
  }



  return (
    <>
      <div className="banner-arabic">
        <Navbar_Arabic />
      </div>
      <div className="banner-arabic">
        <Subnav_Arabic />
      </div>

      <div className="xl:px-[120px] px-[20px] lg:h-[85vh] h-[90vh] lg:flex items-center justify-between banner-arabic">
        <div className="xl:w-[700px] lg:w-[600px] grid content-center">
          <div className="lg:text-[40px] text-[20px] md:text-[24px] font-bold text-[#88A398] lg:mt-20 mt-[100px]">
            <p className="">مرحبا بكم في الشرق الأوسط </p>
            <p className="">شركة التموين البيطري ذ.م.م</p>
          </div>
          <p className="lg:text-[22px] md:text-[16px] text-[14px] text-[#5F726F] lg:pt-10 pt-5 content-en">
            حيث نلتزم ب [الحفاظ على بيئة صحية من خلال خدمة صحة الحيوان]. نحن توريد الأدوية البيطرية في الإمارات العربية المتحدة ، دبي التي تخدم مزارع الحيوانات الكبيرة ، اسطبلات الخيول ، العيادات البيطرية ، مزارع الحيوانات الكبيرة ، مزارع الدواجن والصقارين لأكثر من 20 عاما
          </p>
        </div>
        <div className="lg:mt-20 h-60 md:h-80 grid justify-items-center content-center">
          <img src="../assets/product-bg.png" alt="" className="lg:h-[400px] h-[200px] md:h-[250px]" />
        </div>
      </div>

      {/* navigator */}

      <div className="px-5 flex gap-10 mt-10 lg:mt-0 text-center text-[20px] md:px-[30px] lg:px-[50px] xl:px-[120px] whitespace-nowrap overflow-auto scrollbar-hide banner-arabic">

        <p className="cursor-pointer border-2 border-[#88A398] text-[#88A398] font-semibold rounded-2xl uppercase px-3 py-1  hover:bg-[#FFFFFF]">
          كل
        </p>

        <Link to="/large_arabic">
          <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
            الحيوانات الكبيرة
          </p>
        </Link>
        <Link to="/equine_arabic">
          <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
            فرسي
          </p>
        </Link>
        <Link to="/electric_arabic">
          <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
            معدات
          </p>
        </Link>
        <Link to="/poultry_arabic">
          <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
            دواجن
          </p>
        </Link>
        <Link to="/falcon_arabic">
          <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
            صقر
          </p>
        </Link>
        <Link to="/dis_arabic">
          <p className="border-2 border-transparent hover:border-[#88A398] text-[#666874] rounded-2xl uppercase px-3 py-1 bg-[#EDF3F9] hover:bg-white">
            المطهرات
          </p>
        </Link>
      </div>

      <div className="">
        <div className="xl:px-[120px] px-[20px] pb-20 lg:grid-cols-3 md:grid-cols-2 grid w-full mt-10 banner-arabic" id='all_arabic'>
          {Large_Arabic_Data.map((large) => {
            return (
              <div
                className="lg:min-h-[600px] min-h-[550px] xl:h-[600px] relative flex flex-col  w-auto xl:mx-10 lg:mx-2 md:mx-4 drop-shadow-2xl shadow-2 bg-white mb-10"
                key={large.id}
              >
                <div className="flex justify-center lg:py-7 py-3">
                  <img
                    src={`../large/${large.image}`}
                    alt=""
                    className="h-[200px]"
                  />
                </div>
                <div className="px-5">
                  <p className="lg:text-[22px] text-[18px] font-[700] text-[#000000] lg:h-[55px] h-[45px]">
                    {large.title}
                  </p>

                  <p className="lg:text-[22px] text-[16px] text-[#647794] pt-2">
                    {large.berife}
                  </p>
                </div>
                <button onClick={() => changeContent(large)}>
                  <div className=" absolute bottom-0 duration-700 flex  justify-center w-full cursor-pointer hover:bg-[#E9A655] text-[#E9A655] hover:text-white transition-all">
                    <p className="border-t-[1px] w-full p-2 grid place-content-center border-[#A8B3C4] text-[18px] lg:text-[24px]">
                      عرض تفاصيل المنتجات
                    </p>
                  </div>
                </button>
              </div>
            );
          })}

          {/* equine  */}
          {Equine_Arabic_Data.map((equine) => {
            return (
              <div className="lg:min-h-[600px] min-h-[550px] xl:h-[600px] relative flex flex-col  w-auto xl:mx-10 lg:mx-2 md:mx-4 drop-shadow-2xl shadow-2 bg-white mb-10" key={equine.id}>
                <div className="flex justify-center lg:py-7 py-3">
                  <img
                    src={`../equine/${equine.image}`}
                    alt=""
                    className="h-[220px]"
                  />
                </div>
                <div className="px-5">
                  <p className="lg:text-[22px] text-[18px] font-[700] text-[#000000] lg:h-[55px] h-[45px]">
                    {equine.title}
                  </p>

                  <p className="lg:text-[18px] text-[16px] text-[#647794] pt-2">
                    {equine.mainc1}
                  </p>
                </div>
                <button onClick={() => changeContent1(equine)}>
                  <div className=" absolute bottom-0 duration-700 flex  justify-center w-full cursor-pointer hover:bg-[#E9A655] text-[#E9A655] hover:text-white transition-all">
                    <p className="border-t-[1px] border-[#A8B3C4] w-full p-2 grid place-content-center text-[18px] lg:text-[24px]">
                      عرض تفاصيل المنتجات
                    </p>
                  </div>
                </button>
              </div>
            )
          })}
          {/* electric  */}
          {Electric_Arabic_Data.map((electric) => {
            return (
              <div className="lg:min-h-[600px] min-h-[550px] xl:h-[600px] relative flex flex-col  w-auto xl:mx-10 lg:mx-2 md:mx-4 drop-shadow-2xl shadow-2 bg-white mb-10" key={electric.id}>
                <div className="flex justify-center lg:py-7 py-3">
                  <img
                    src={`../electric/${electric.image}`}
                    alt=""
                    className="h-[220px]"
                  />
                </div>
                <div className="px-5">
                  <p className="lg:text-[22px] text-[18px] font-[700] text-[#000000] lg:h-[55px] h-[45px]">
                    {electric.title}
                  </p>

                  <p className="lg:text-[18px] text-[16px] text-[#647794] pt-2">
                    {electric.mainclimate}
                  </p>
                </div>
                <button onClick={() => changeContent2(electric)}>
                  <div className=" absolute bottom-0 duration-700 flex  justify-center w-full cursor-pointer hover:bg-[#E9A655] text-[#E9A655] hover:text-white transition-all">
                    <p className="border-t-[1px] border-[#A8B3C4] w-full p-2 grid place-content-center text-[18px] lg:text-[24px]">
                      عرض تفاصيل المنتجات
                    </p>
                  </div>
                </button>
              </div>
            )
          })}
          {/* poultry  */}
          {Poultry_Arabic_Data.map((poultry) => {
            return (
              <div className="lg:min-h-[600px] min-h-[550px] xl:h-[600px] relative flex flex-col  w-auto xl:mx-10 lg:mx-2 md:mx-4 drop-shadow-2xl shadow-2 bg-white mb-10" key={poultry.id}>
                <div className="flex justify-center lg:py-7 py-3">
                  <img
                    src={`../poultry/${poultry.image}`}
                    alt=""
                    className="h-[220px]"
                  />
                </div>
                <div className="px-5">
                  <p className="lg:text-[22px] text-[18px] font-[700] text-[#000000] lg:h-[55px] h-[45px]">
                    {poultry.title}
                  </p>
                  <p className="lg:text-[20px] text-[16px] text-[#647794] pt-2">
                    {poultry.maincontent}
                  </p>
                </div>
                <button onClick={() => changeContent3(poultry)}>
                  <div className=" absolute bottom-0 duration-700 flex  justify-center w-full cursor-pointer hover:bg-[#E9A655] text-[#E9A655] hover:text-white transition-all">
                    <p className="border-t-[1px] border-[#A8B3C4] w-full p-2 grid place-content-center text-[18px] lg:text-[24px]">
                      عرض تفاصيل المنتجات
                    </p>
                  </div>
                </button>
              </div>
            )
          })}

          {Falcon_Arabic_Data.map((falcon) => {
            return (
              <div className="lg:min-h-[600px] min-h-[550px] xl:h-[600px] relative flex flex-col  w-auto xl:mx-10 lg:mx-2 md:mx-4 drop-shadow-2xl shadow-2 bg-white mb-10" key={falcon.id}>
                <div className="flex justify-center lg:py-7 py-3">
                  <img
                    src={`../falcon/${falcon.image}`}
                    alt=""
                    className="h-[220px]"
                  />
                </div>
                <div className="px-5">
                  <p className="lg:text-[22px] text-[18px] font-[700] text-[#000000] lg:h-[55px] h-[45px]">
                    {falcon.title}
                  </p>
                  <p className="font-[600] text-[#647794]">{falcon.subtitle}</p>

                  <p className="lg:text-[18px] text-[16px] text-[#647794] pt-2">
                    {falcon.maincontent}
                  </p>
                </div>
                <button onClick={() => changeContent4(falcon)}>
                  <div className=" absolute bottom-0 duration-700 flex  justify-center w-full cursor-pointer hover:bg-[#E9A655] text-[#E9A655] hover:text-white transition-all">
                    <p className="border-t-[1px] border-[#A8B3C4] w-full p-2 grid place-content-center text-[18px] lg:text-[24px]">
                      عرض تفاصيل المنتجات
                    </p>
                  </div>
                </button>
              </div>
            )
          })}
          {Disinfectant_Arabic_Data.map((dis) => {
            return (
              <div className="lg:min-h-[600px] min-h-[550px] xl:h-[600px] relative flex flex-col  w-auto xl:mx-10 lg:mx-2 md:mx-4 drop-shadow-2xl shadow-2 bg-white mb-10" key={dis.id}>
                <div className="flex justify-center lg:py-7 py-3">
                  <img
                    src={`../disinfectant_img/${dis.image}`}
                    alt=""
                    className="h-[220px]"
                  />
                </div>
                <div className="px-5">
                  <p className="lg:text-[22px] text-[18px] font-[700] text-[#000000] lg:h-[55px] h-[45px]">
                    {dis.title}
                  </p>

                  <p className="lg:text-[22px] text-[20px] text-[#647794] pt-2">
                    إنه منتج جاهز للاستخدام.
                    لا يوجد تأثير التآكل.
                    يظهر الكفاءة المثلى في المناطق التي يصعب الوصول إليها معنا.
                    توزيع متجانس.
                  </p>
                </div>
                <button onClick={() => changeContent5(dis)}>
                  <div className=" absolute bottom-0 duration-700 flex  justify-center w-full cursor-pointer hover:bg-[#E9A655] text-[#E9A655] hover:text-white transition-all">
                    <p className="border-t-[1px] border-[#A8B3C4] w-full p-2 grid place-content-center text-[18px] lg:text-[24px]">
                      عرض تفاصيل المنتجات
                    </p>
                  </div>
                </button>
              </div>
            )
          })}
        </div>

        {/* popup  */}

        {popuptoggle && (
          <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-50 ">
            {popup.map((pop) => {
              return (
                <>
                  <div className="lg:w-[510px] md:w-[450px] bg-white pb-10 opacity-100 float-right h-full lg:p-10 p-4 m-4 md:m-0 overflow-y-scroll">
                    <div className="flex justify-between banner-arabic">
                      <p className="lg:text-[32px] text-[18px] font-[600] text-[#88A398] lg:w-[350px] w-[200px] md:w-[300px]">
                        {pop.title}
                      </p>
                      <button onClick={changeContent} className="cursor-pointer">
                        <p className="text-[30px] font-[500] border rounded-[50%] w-[50px] text-[#88A398] border-[#88A398]">x</p> {/* <img src="./assets/exit.png" alt="" className="lg:h-10 h-7" /> */}
                      </button>
                    </div>
                    <div className="text-[#88A398] banner-arabic">
                      <p className="lg:text-[20px] text-[12px] underline underline-offset-4">
                        الأنواع المستهدفة والجرعة
                      </p>
                      <p className="lg:text-[24px] text-[14px]">
                        الجرعة وإعطاء الحيوانات الكبيرة:
                      </p>
                    </div>
                    <hr className="border-[#88A398] opacity-[40%]" />
                    <div className="flex justify-center py-3 bg-[#DBE5EB] my-5 banner-arabic">
                      <img
                        src={`../large/${pop.image}`}
                        alt=""
                        className="h-[180px]"
                      />
                    </div>
                    <div className="lg:text-[20px] text-[18px] text-[#7088A0] banner-arabic">
                      <p className="pb-2">
                        {pop.c1}
                      </p>
                      <div className="pb-5">
                        <p className="font-[600]">{pop.h2}</p>
                        {pop.c2.map((list1) => (
                          <ul key={list1.id}>
                            <li>{list1.l1}</li>
                            <li>{list1.l2}</li>
                            <li>{list1.l3}</li>
                            <li>{list1.l4}</li>
                          </ul>
                        ))}

                      </div>
                      <div className="pb-5">
                        <p className="font-[600]">{pop.h3}</p>
                        <p className="font-[600]">{pop.subhead}</p>
                      </div>
                      {pop.c3.map((list2) => (
                        <div className="pb-5" key={list2.id}>
                          <ul>
                            <li className="font-[600]">{list2.h1}</li>
                            <li className="mb-3">{list2.l1}</li>
                            <li className="font-[600]">{list2.h2}</li>
                            <li className="mb-3">{list2.l2}</li>
                            <li className="font-[600]">{list2.h3}</li>
                            <li className="mb-3">{list2.l3}</li>
                            <li className="font-[600]">{list2.h4}</li>
                            <li className="mb-3">{list2.l4}</li>
                            <li className="font-[600]">{list2.h5}</li>
                            <li className="mb-3">{list2.l5}</li>
                            <li className="font-[600]">{list2.h6}</li>
                            <li className="">{list2.l6}</li>
                          </ul>
                        </div>
                      ))}
                      <div className="pb-5">
                        <p className="font-[600]">{pop.h4}</p>
                        <p className="">{pop.c4}</p>
                      </div>
                      <div className="flex justify-center">

                        <button className="bg-[#88A398] w-[170px] h-12 rounded-[10px] text-white text-center p-2 font-[600] text-[22px]" onClick={navigateToContact}>
                          اتصل بنا
                        </button>

                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        )}
      </div>


      <div className="">
        <div className="xl:px-[120px] px-[20px] lg:grid-cols-3 md:grid-cols-2 grid w-full banner-arabic">

        </div>

        {popuptoggle1 && (
          <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-50 ">
            {popup1.map((pop) => {
              return (
                <div className="lg:w-[510px] md:w-[450px] bg-white pb-10 opacity-100 md:float-right h-full lg:p-10 p-4 m-4 md:m-0 overflow-y-scroll">
                  <div className="flex justify-between banner-arabic">
                    <p className="lg:text-[32px] text-[18px] font-[600] text-[#88A398] lg:w-[350px] w-[200px] md:w-[300px]">
                      {pop.title}
                    </p>
                    <button onClick={changeContent1} className="cursor-pointer">
                      <p className="text-[30px] font-[500] border rounded-[50%] w-[50px] text-[#88A398] border-[#88A398]">x</p>  {/* <img src="../assets/exit.png" alt="" className="lg:h-10 h-7" /> */}
                    </button>
                  </div>
                  <div className="text-[#88A398] banner-arabic">
                    <p className="lg:text-[20px] text-[14px]">
                      {pop.h}
                    </p>
                  </div>
                  <hr className="border-[#88A398] opacity-[40%]" />
                  <div className="flex justify-center py-3 bg-[#DBE5EB] my-5 banner-arabic">
                    <img
                      src={`../equine/${pop.image}`}
                      alt=""
                      className="h-[180px]"
                    />
                  </div>
                  <div className="lg:text-[20px] text-[14px] text-[#7088A0]">
                    <div className="pb-5">
                      <p className="font-[600]">{pop.h1}</p>
                      <p className="">
                        {pop.c1.map((list1) => (
                          <ul className="" key={list1.id}>
                            <li>{list1.l1}</li>
                            <li>{list1.l2}</li>
                            <li>{list1.l3}</li>
                          </ul>
                        ))}
                      </p>
                    </div>
                  </div>
                  <div className="lg:text-[20px] text-[14px] text-[#7088A0]">
                    <div className="pb-5">
                      <p className="font-[600]">{pop.h2}</p>
                      <p className="">
                        {pop.c2.map((list2) => (
                          <ul className="" key={list2.id}>
                            <li>{list2.l1}</li>
                            <li>{list2.l2}</li>
                            <li>{list2.l3}</li>
                            <li>{list2.l4}</li>
                            <li>{list2.l5}</li>

                          </ul>
                        ))}
                      </p>
                    </div>
                  </div>
                  <div className="lg:text-[16px] text-[14px] text-[#7088A0]">
                    <div className="pb-5">
                      <p className="font-[600]">{pop.h3}</p>
                      <p className="">
                        {pop.c3.map((list3) => (
                          <ul className="" key={list3.id}>
                            <li>{list3.l1}</li>
                            <li>{list3.l2}</li>
                            <li>{list3.l3}</li>
                            <li>{list3.l4}</li>
                          </ul>
                        ))}
                      </p>
                    </div>
                  </div>
                  {/* <div className="lg:text-[16px] text-[14px] text-[#7088A0]">
                  <div className="pb-5">
                    <p className="font-[600]">head</p>
                    <p className="">Content</p>
                  </div>
                </div> */}


                  <div className="flex justify-center">
                    <button className="bg-[#88A398] w-[170px] h-12 rounded-[10px] text-white text-center p-2 font-[600] text-[22px]" onClick={navigateToContact}>
                      اتصل بنا
                    </button>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>

      <div className="">
        <div className="xl:px-[120px] px-[20px] lg:grid-cols-3 md:grid-cols-2 grid w-full banner-arabic">

        </div>

        {popuptoggle2 && (
          <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-50  banner-arabic">
            {popup2.map((pop) => {
              return (
                <div className="lg:w-[510px] md:w-[450px] bg-white pb-10 opacity-100 md:float-right h-full lg:p-10 p-4 m-4 md:m-0 overflow-y-scroll">
                  <div className="flex justify-between">
                    <p className="lg:text-[32px] text-[18px] font-[600] text-[#88A398] lg:w-[350px] w-[200px] md:w-[300px]">
                      {pop.title}
                    </p>
                    <button onClick={changeContent2} className="cursor-pointer">
                      <p className="text-[30px] font-[500] border rounded-[50%] w-[50px] text-[#88A398] border-[#88A398]">x</p>   {/* <img src="../assets/exit.png" alt="" className="lg:h-10 h-7" /> */}
                    </button>
                  </div>
                  {/* <div className="text-[#88A398]">
                  <p className="lg:text-[16px] text-[12px] underline underline-offset-4">
                    Target species & dose
                  </p>
                  <p className="lg:text-[20px] text-[14px]">
                    Dosage & Administration Poultry:
                  </p>
                </div> */}
                  <hr className="border-[#88A398] opacity-[40%] mt-3" />
                  <div className="flex justify-center py-3 bg-[#DBE5EB] my-5">
                    <img
                      src={`../electric/${pop.image}`}
                      alt=""
                      className="h-[180px]"
                    />
                  </div>
                  <div className="lg:text-[16px] text-[14px] text-[#7088A0]">
                    <div className="pb-5">
                      <p className="font-[600]">{pop.subhead}</p>
                      <p className="">{pop.mainclimate}</p>
                    </div>
                  </div>


                  <div className="flex justify-center">
                    <button className="bg-[#88A398] w-[170px] h-12 rounded-[10px] text-white text-center p-2 font-[600] text-[22px]" onClick={navigateToContact}>
                      اتصل بنا
                    </button>

                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>

      <div className="">
        <div className="xl:px-[120px] px-[20px] lg:grid-cols-3 md:grid-cols-2 grid w-full banner-arabic">

        </div>
        {/* popup  */}

        {popuptoggle3 && (
          <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-50 ">
            {popup3.map((pop) => {
              return (
                <div className="lg:w-[510px] md:w-[450px] bg-white pb-10 opacity-100 md:float-right h-full lg:p-10 p-4 m-4 md:m-0 overflow-y-scroll">
                  <div className="flex justify-between banner-arabic">
                    <p className="lg:text-[32px] text-[18px] font-[600] text-[#88A398] lg:w-[350px] w-[200px] md:w-[300px]">
                      {pop.title}
                    </p>
                    <button onClick={changeContent3} className="cursor-pointer">
                      <p className="text-[30px] font-[500] border rounded-[50%] w-[50px] text-[#88A398] border-[#88A398]">x</p> {/* <img src="../assets/exit.png" alt="" className="lg:h-10 h-7" /> */}
                    </button>
                  </div>
                  <div className="text-[#88A398] banner-arabic">
                    <p className="lg:text-[20px] text-[12px] underline underline-offset-4">
                      الأنواع المستهدفة والجرعة
                    </p>
                    <p className="lg:text-[24px] text-[14px]">
                      الجرعة وإعطاء الدواجن:
                    </p>
                  </div>
                  <hr className="border-[#88A398] opacity-[40%] " />
                  <div className="flex justify-center py-3 bg-[#DBE5EB] my-5 banner-arabic">
                    <img
                      src={`../poultry/${pop.image}`}
                      alt=""
                      className="h-[180px]"
                    />
                  </div>
                  <div className="lg:text-[20px] text-[14px] text-[#7088A0] banner-arabic">
                    <div className="pb-5">
                      <p className="font-[600]">{pop.h1}</p>
                      <p className="">{pop.c1}</p>
                    </div>
                    <p className="font-[600] pb-3">{pop.h2}</p>
                    {pop.c2.map((list1) => (
                      <div className="" key={list1.id}>
                        <ul>
                          <li className="font-[600]">{list1.h1}</li>
                          <li className="mb-3">{list1.l1}</li>
                          <li className="font-[600]">{list1.h2}</li>
                          <li className="mb-3">{list1.l2}</li>
                          <li className="font-[600]">{list1.h3}</li>
                          <li className="mb-3">{list1.l3}</li>
                          <li className="font-[600]">{list1.h4}</li>
                          <li className="mb-3">{list1.l4}</li>
                          <li className="font-[600]">{list1.h5}</li>
                          <li className="mb-3">{list1.l5}</li>

                        </ul>
                      </div>
                    ))}
                    <p className="font-[600]">{pop.b1}</p>
                    {pop.b2.map((ben) => (
                      <ul className="" key={ben.id}>
                        <li>{ben.l1}</li>
                        <li>{ben.l2}</li>
                        <li>{ben.l3}</li>
                        <li>{ben.l4}</li>
                        <li>{ben.l5}</li>
                        <li>{ben.l6}</li>

                      </ul>
                    ))}


                    <div className="pb-5">
                      <p className="font-[600]">{pop.h3}</p>
                      <p className="">{pop.c3}</p>
                    </div>

                  </div>



                  <div className="flex justify-center">
                    <button className="bg-[#88A398] w-[170px] h-12 rounded-[10px] text-white text-center p-2 font-[600] text-[22px]" onClick={navigateToContact}>
                      اتصل بنا
                    </button>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>

      <div className="">
        <div className="banner-arabic xl:px-[120px] px-[20px] pb-20 lg:grid-cols-3 md:grid-cols-2 grid w-full mt-10">

        </div>
        {/* popup  */}
        {popuptoggle4 && (
          <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-50 banner-arabic">
            {popup4.map((pop) => {
              return (
                <div className="lg:w-[510px] md:w-[450px] bg-white pb-10 opacity-100 md:float-right h-full lg:p-10 p-4 m-4 md:m-0 overflow-y-scroll">
                  <div className="flex justify-between">
                    <p className="lg:text-[32px] text-[18px] font-[600] text-[#88A398] lg:w-[350px] w-[200px] md:w-[300px]">
                      {pop.title}
                    </p>
                    <button onClick={changeContent4} className="cursor-pointer">
                      <p className="text-[30px] font-[500] border rounded-[50%] w-[50px] text-[#88A398] border-[#88A398]">x</p>  {/* <img src="../assets/exit.png" alt="" className="lg:h-10 h-7" /> */}
                    </button>
                  </div>
                  <div className="text-[#88A398]">
                    <p className="lg:text-[20px] text-[12px] underline underline-offset-4">
                      الأنواع المستهدفة والجرعة
                    </p>
                    <p className="lg:text-[24px] text-[14px]">
                      الجرعة وطريقة الاستعمال:
                    </p>
                  </div>
                  <hr className="border-[#88A398] opacity-[40%]" />
                  <div className="flex justify-center py-2 bg-[#DBE5EB] my-5">
                    <img
                      src={`../falcon/${pop.image}`}
                      alt=""
                      className="h-[220px]"
                    />
                  </div>
                  <div className="text-[#7088A0]">
                    <div className="pb-2">
                      <p className="font-[600] text-[20px]">{pop.subtitle}</p>
                    </div>
                    <div className="lg:text-[16px] text-[14px] ">
                      <div className="pb-5">
                        <p className="font-[600] text-[20px]">{pop.composition}</p>
                        <p className="">{pop.c1}</p>
                      </div>
                      {/* indication  */}
                      <div className="pb-5">
                        <p className="font-[600] text-[20px]">دليل:</p>
                        <p className="">{pop.indication}</p>
                      </div>
                      {/* dosage  */}
                      <div className="pb-5">
                        <p className="font-[600] text-[20px]">جرعة:</p>
                        <p className="">{pop.dosage}</p>
                      </div>
                      {/* storage  */}
                      <div className="pb-5">
                        <p className="font-[600] text-[20px]">خزن:</p>
                        <p className="">{pop.storage}</p>
                      </div>
                      {/* packging */}
                      <div className="pb-5">
                        <p className="font-[600] text-[20px]">التعبئه:</p>
                        <p className="">{pop.packing}</p>
                      </div>
                    </div>
                  </div>


                  <div className="flex justify-center">
                    <button className="bg-[#88A398] w-[170px] h-12 rounded-[10px] text-white text-center p-2 font-[600] text-[22px]" onClick={navigateToContact}>
                      اتصل بنا
                    </button>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>

      <div className="">
        <div className="xl:px-[120px] px-[20px] lg:grid-cols-3 md:grid-cols-2 grid w-full banner-arabic">

        </div>

        {popuptoggle5 && (
          <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-50 ">
            {popup5.map((pop) => {
              return (
                <div className="lg:w-[510px] md:w-[450px] bg-white pb-10 opacity-100 md:float-right h-full lg:p-10 p-4 m-4 md:m-0 overflow-y-scroll ">
                  <div className="flex justify-between banner-arabic">
                    <p className="lg:text-[32px] text-[18px] font-[600] text-[#88A398] lg:w-[350px] w-[200px] md:w-[300px]">
                      {pop.title}
                    </p>
                    <button onClick={changeContent5} className="cursor-pointer">
                    <p className="text-[30px] font-[500] border rounded-[50%] w-[50px] text-[#88A398] border-[#88A398]">x</p>
                      {/* <img src="../assets/exit.png" alt="" className="lg:h-10 h-7" /> */}
                    </button>
                  </div>
                  {/* <div className="text-[#88A398]">
                  <p className="lg:text-[16px] text-[12px] underline underline-offset-4">
                    Target species & dose
                  </p>
                  <p className="lg:text-[20px] text-[14px]">
                    Dosage & Administration Poultry:
                  </p>
                </div> */}
                  <hr className="border-[#88A398] opacity-[40%] mt-3 banner-arabic" />
                  <div className="flex justify-center py-3 bg-[#DBE5EB] my-5">
                    <img
                      src={`../disinfectant_img/${pop.image}`}
                      alt=""
                      className="h-[180px]"
                    />
                  </div>
                  <div className="lg:text-[16px] text-[14px] text-[#7088A0] banner-arabic">
                    <div className="pb-5">
                      <p className="font-[600] pb-2">{pop.subtitle}</p>
                      <p className="font-[600] pb-2">{pop.subtitle2}</p>
                      {/* <p className="">Content</p> */}
                    </div>

                    {pop.c.map((list) => (
                      <ul key={list.id}>
                        <li className='py-[2px]]'>{list.l1}</li>
                        <li className='py-[2px]]'>{list.l2}</li>
                        <li className='py-[2px]]'>{list.l3}</li>
                        <li className='py-[2px]]'>{list.l4}</li>
                        <li className='py-[2px]]'>{list.l5}</li>

                      </ul>
                    ))}
                  </div>


                  <div className="flex justify-center mt-3">
                    <button className="bg-[#88A398] w-[170px] h-12 rounded-[10px] text-white text-center p-2 font-[600] text-[22px]" onClick={navigateToContact}>
                      اتصل بنا
                    </button>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>

      <Footer_Arabic />
    </>

  )
}
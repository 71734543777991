import React, { useEffect, useState } from "react";
import axios from 'axios';
import Navbar from "../navbar/Navbar";
import { Carousel, initTE } from "tw-elements";
// import Footer from '../footer/Footer';
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
import './arabic.css';
import ReCAPTCHA from "react-google-recaptcha";
import Navbar_Arabic from "../navbar_arabic/Navbar_Arabic";
import Footer_Arabic from "../footer_arabic/Footer_Arabic";

// initTE({  });

function Arabic() {

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);

  const [recaptchaChecked, setRecaptchaChecked] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!recaptchaChecked) {
      alert("Please complete the reCAPTCHA verification.");
      return;
    }
    setLoading(true);

    try {
      const response = await axios.post(
        "http://localhost:5000/send-email",
        formData
      );

      if (response.data.success) {
        setIsFormSubmitted(true);
        window.location.reload(); // Reload the page after form submission
      } else {
        console.error("Form submission error", response.data.message);
      }
    } catch (error) {
      console.error("Form submission error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaChecked(true);
  };


  useEffect(() => {
    initTE({ Carousel });
  }, []);
  AOS.init();
  return (
    <>
      <div className="banner-arabic">
        <Navbar_Arabic />
      </div>
      <div
        id="carouselExampleIndicators"
        class="relative"
        data-te-carousel-init
        data-te-ride="carousel"
      >
        <div
          class="dot absolute md:top-0 top-[100px] md:left-10 left-10 z-[2] mb-4  list-none p-0 h-full w-5 grid md:items-center"
          data-te-carousel-indicators
        >
          <div className="">
            <div
              type="button"
              data-te-target="#carouselExampleIndicators"
              data-te-slide-to="0"
              data-te-carousel-active
              class="mx-[3px] box-content h-[10px] rounded-[50%] w-[10px] cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
              aria-current="true"
              aria-label="Slide 1"></div>
            <div
              type="button"
              data-te-target="#carouselExampleIndicators"
              data-te-slide-to="1"
              class="mx-[3px] box-content h-[10px] rounded-[50%] w-[10px] lex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
              aria-label="Slide 2" ></div>
            <div
              type="button"
              data-te-target="#carouselExampleIndicators"
              data-te-slide-to="2"
              class="mx-[3px] box-content h-[10px] rounded-[50%] w-[10px] lex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
              aria-label="Slide 3"
            ></div>
            <div
              type="button"
              data-te-target="#carouselExampleIndicators"
              data-te-slide-to="3"
              class="mx-[3px] box-content h-[10px] rounded-[50%] w-[10px] lex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
              aria-label="Slide 4"
            ></div>
            <div
              type="button"
              data-te-target="#carouselExampleIndicators"
              data-te-slide-to="4"
              class="mx-[3px] box-content h-[10px] rounded-[50%] w-[10px] lex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
              aria-label="Slide 5"
            ></div>
            <div
              type="button"
              data-te-target="#carouselExampleIndicators"
              data-te-slide-to="5"
              class="mx-[3px] box-content h-[10px] rounded-[50%] w-[10px] lex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
              aria-label="Slide 6"
            ></div>
          </div>
        </div>

        {/* page 1 */}
        <div class="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
          <div
            class="relative float-left -mr-[100%] w-full transition-transform duration-[700ms] ease-in-out motion-reduce:transition-none"
            data-te-carousel-item
            data-te-carousel-active
          >
            <div class="block w-full h-[100vh] md:h-[100vh]"></div>
            {/* <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed">
              <div class="md:flex h-full relative">

                <div className="md:relative grid w-full absolute top-0 lg:w-[60%]">
                  <div className="h-full justify-self-end">
                    <div className="bg-[#88A398] xl:h-[900px] lg:h-[700px] lg:w-[700px] md:h-[500px] md:w-[500px] h-[300px] w-[300px] xl:w-[900px] rounded-[50%]  relative xl:-top-[200px]  xl:-left-[220px] lg:-top-[100px] lg:-left-[160px] md:-left-[120px] left-[60px]"></div>
                    <div className="z-40 xl:top-[140px] lg:left-[180px] xl:w-[550px] lg:w-[440px] lg:top-[120px] md:top-[140px] md:right-[70px] top-[100px] right-[70px] xl:right-[90px] absolute">
                      <img
                        src="./assets/home-1.png"
                        alt=""
                        className="xl:h-[540px] lg:h-[450px] h-[200px] xl:w-[550px] lg:w-[440px] md:h-[350px]"
                      />
                    </div>
                    <div className="bg-white xl:h-[400px] h-[200px] w-[200px] xl:w-[430px] lg:w-[380px] lg:h-[380px] md:w-[290px] md:h-[280px] rounded-[50%] absolute xl:top-[110px] top-[90px] lg:left-[245px] lg:top-[100px] right-[90px] xl:right-[130px]  md:top-[125px] md:right-[85px] z-10"></div>
                    <div className="bg-[#E9A655] xl:h-[390px] lg:h-[350px] xl:w-[430px] lg:w-[340px] h-[170px] w-[170px] md:w-[270px] md:h-[270px] rounded-[50%] absolute xl:top-[330px] md:top-[240px] md:left-[200px] top-[140px] right-[110px] xl:left-[380px] lg:left-[280px] lg:top-[240px] z-20"></div>
                  </div>
                </div>
                <div className="banner-arabic lg:w-[40%] h-full content-end  grid justify-center md:content-center xl:pr-[120px] md:pr-[20px] md:mt-10 mt-0">
                  <p className="lg:text-[44px] md:text-[36px] text-[24px] text-justify lg:text-start font-[600] text-[#88A398] lg:py-4 leading-[62px]">
                    
                  </p>
                  <p className="lg:text-[28px] md:text-[20px] text-[18px] font-semibold text-justify lg:text-start text-[#88A398] lg:pb-5 pb-1">
                  </p>
                  <p className="lg:text-[24px] text-[14px] font-normal text-justify lg:text-start text-[#5F726F] w-[300px] md:w-[200px] xl:w-[500px] lg:w-[390px] lg:leading-[35px] mb-5">
                  </p>

                  <div className="text-justify lg:text-start">
                    <a href="/disinfectants">

                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#88A398] text-white rounded-[10px]">
                      </button>
                    </a>
                  </div>
                  <div className="text-justify lg:text-start">
                    <a href="/disinfectants">
                      {" "}
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#E9A655] text-white rounded-[10px]">
                      </button>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            <div class="flip absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed">
              <div class="md:flex h-full relative">
                <div className="flip banner-arabic lg:w-[40%] h-full content-end  grid justify-center lg:justify-start md:content-center xl:pr-[120px] md:pr-[20px] md:mt-20 mt-0">
                  <p className="lg:text-[44px] md:text-[36px] text-[24px] text-justify lg:text-start font-[600] text-[#88A398] lg:py-4 leading-[62px]">
                    المطهرات
                  </p>
                  <p className="lg:text-[28px] md:text-[22px] text-[18px] font-semibold text-justify lg:text-start text-[#88A398] lg:pb-5 pb-1">
                    كل شيء عن الأمن البيولوجي
                  </p>
                  <p className="lg:text-[24px] text-[14px] font-normal text-justify lg:text-start text-[#5F726F] w-[300px] md:w-[260px] xl:w-[500px] lg:w-[390px] lg:leading-[35px] mb-5">
                    البيئة الآمنة تؤدي إلى ارتفاع الإنتاج في نهاية المطاف الحفاظ على مستوى عال للصحة العامة لكل من الإنسان والحيوان.

                  </p>

                  <div className="text-justify lg:text-start">
                    <Link to={"/dis_arabic"}>
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#88A398] text-white rounded-[10px]">
                        المطهرات
                      </button>
                    </Link>
                  </div>
                  <div className="text-justify lg:text-start">
                    <Link to={"/dis_arabic"}>
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#E9A655] text-white rounded-[10px]">
                        منتجات أخرى
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="lg:relative grid w-full absolute top-0">
                  <div className="  h-full justify-self-end">
                    <div className="bg-[#88A398] xl:h-[900px] lg:h-[700px] lg:w-[700px] md:h-[500px] md:w-[500px] h-[300px] w-[300px] xl:w-[900px] rounded-[50%]  relative xl:-top-[200px]  xl:left-[220px] lg:-top-[100px] lg:left-[120px] md:left-[90px] left-[60px]"></div>
                    <div className="z-40 xl:top-[140px] lg:right-[250px] lg:top-[120px] md:top-[150px] md:right-[130px] top-[100px] right-[70px] xl:right-[260px] absolute">
                      <img
                        src="./assets/home-1.png"
                        alt=""
                        className="xl:h-[540px] lg:h-[440px] h-[200px] xl:w-[550px] lg:w-[440px] md:h-[340px]"
                      />
                    </div>
                    <div className="bg-white  xl:h-[400px] h-[200px] w-[200px] xl:w-[430px] lg:w-[380px] lg:h-[380px] md:w-[290px] md:h-[280px] rounded-[50%] absolute xl:top-[110px] top-[90px] lg:right-[295px] lg:top-[100px] right-[90px] xl:right-[350px]  md:top-[125px] md:right-[170px] z-10"></div>
                    <div className="bg-[#E9A655] xl:h-[390px] lg:h-[340px] xl:w-[430px] lg:w-[340px] h-[170px] w-[170px] md:w-[270px] md:h-[270px] rounded-[50%] absolute xl:top-[330px] md:top-[240px] md:right-[210px] top-[140px] right-[110px] xl:right-[390px] lg:right-[350px] lg:top-[240px] z-20"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Page 2 */}
          <div
            class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
            data-te-carousel-item
          >
            <div class="block w-full h-[100vh] md:h-[100vh]"></div>
            {/* <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed">
              <div class="md:flex h-full relative">
                <div className="md:relative grid w-full absolute top-0 lg:w-[60%]">
                  <div className="  h-full justify-self-end">
                    <div className="bg-[#88A398]  xl:h-[900px] lg:h-[700px] lg:w-[700px] md:h-[500px] md:w-[500px] h-[300px] w-[300px] xl:w-[900px] rounded-[50%]  relative xl:-top-[200px]  xl:-left-[220px] lg:-top-[100px] lg:-left-[160px] md:-left-[120px] left-[60px]"></div>
                    <div className="z-40 xl:top-[140px] lg:top-[120px] lg:left-[180px] top-[100px] right-[70px] xl:left-[260px] md:top-[145px] md:left-[140px] absolute">
                      <div className="bg-[#E9A655] xl:h-[540px] h-[200px] w-[200px] xl:w-[550px] md:w-[335px] md:h-[345px] lg:w-[450px] lg:h-[450px] rounded-[50%]"></div>

                    </div>
                    <div className=" z-40 lg:top-[140px] top-[100px] right-[70px] lg:left-[220px] md:right-[10px] absolute">
                      <div className="xl:h-[540px] h-[200px] w-[200px] xl:w-[700px] rounded-[50%] md:w-[400px] md:h-[300px]"></div>
                      <img src="./assets/home-falcon.png" alt="" className="z-40 absolute xl:top-[160px] xl:left-[150px] md:top-[110px] md:left-[50px] xl:w-[610px] xl:h-[420px] md:w-[350px] md:h-[300px] w-[200px] left-[10px] top-[65px]" />
                    </div>
                    <div className="bg-white xl:h-[400px] h-[200px] w-[200px] xl:w-[430px] lg:w-[380px] lg:h-[380px] md:w-[290px] md:h-[280px] rounded-[50%] absolute xl:top-[110px] top-[90px] lg:left-[245px] lg:top-[100px] right-[90px] xl:left-[350px]  md:top-[125px] md:left-[175px] z-10"></div>
                    <div className="bg-[#4E7A97] xl:h-[390px] lg:h-[350px] xl:w-[430px] lg:w-[340px] h-[170px] w-[170px] md:w-[270px] md:h-[270px] rounded-[50%] absolute xl:top-[330px] md:top-[240px] md:left-[200px] top-[140px] right-[110px] xl:left-[380px] lg:left-[280px] lg:top-[240px] z-20"></div>
                  </div>
                </div>
                <div className="banner-arabic lg:w-[40%] h-full content-end  grid justify-center md:content-center xl:pr-[120px] md:pr-[20px] md:mt-10 mt-0">
                  <p className="lg:text-[44px] text-[24px] text-justify lg:text-start font-semibold text-[#88A398] lg:py-4">
                  </p>
                  <p className=" lg:text-[24px] text-[14px] font-normal text-justify lg:text-start text-[#88A398] w-[200px] lg:w-[370px] xl:w-[500px] lg:leading-[35px] mb-5">
                  </p>
                  <div className="text-justify lg:text-start">
                    <a href="/falcon">
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#88A398] text-white rounded-[10px]">
                      </button>
                    </a>
                  </div>
                  <div className="text-justify lg:text-start">
                    <a href="/falcon">
                      {" "}
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#E9A655] text-white rounded-[10px]">
                      </button>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            <div class="flip absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed">
              <div class="md:flex lg:flex h-full relative">
                <div className="flip banner-arabic lg:w-[40%] h-full content-end  grid lg:justify-start justify-center md:content-center xl:pr-[120px] md:pr-[20px] md:mt-10 mt-0">
                  <p className="lg:text-[44px] text-[24px] text-justify lg:text-start font-semibold text-[#88A398] lg:py-4">
                    صقر منتجات
                  </p>
                  <p className="lg:text-[24px] text-[14px] font-normal text-justify lg:text-start text-[#88A398] w-[300px] lg:w-[390px] lg:leading-[35px] mb-5">
                    منتجات متخصصة لجميع الصقور سواء الصيد أو السباق أو التربية.

                  </p>

                  <div className="text-justify lg:text-start">
                    <Link to={"/falcon_arabic"}>
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#88A398] text-white rounded-[10px]">
                        تفاصيل الصقر
                      </button></Link>
                  </div>
                  <div className="text-justify lg:text-start">
                    <Link to={"/falcon_arabic"}>
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#E9A655] text-white rounded-[10px]">
                        منتجات أخرى
                      </button></Link>
                  </div>
                </div>
                <div className="lg:relative grid w-full absolute top-0">
                  <div className="  h-full justify-self-end">
                    <div className="bg-[#88A398]  xl:h-[900px] lg:h-[700px] lg:w-[700px] md:h-[500px] md:w-[500px] h-[300px] w-[300px] xl:w-[900px] rounded-[50%]  relative xl:-top-[200px]  xl:left-[220px] lg:-top-[100px] lg:left-[120px] md:left-[90px] left-[60px]"></div>
                    <div className="z-40 xl:top-[140px] lg:top-[120px] lg:right-[240px] top-[100px] right-[70px] xl:right-[260px] md:top-[150px] md:right-[140px] absolute">
                      <div className="bg-[#E9A655] xl:h-[540px] h-[200px] w-[200px] xl:w-[550px] md:w-[335px] md:h-[345px] lg:w-[450px] lg:h-[450px] rounded-[50%]"></div>
                      {/* <img src="./assets/home-falcon.png" alt="" className="z-40 absolute xl:top-[180px] xl:left-[210px] xl:w-[700px] xl:h-[420px] w-[200px] left-[50px] top-[165px]" /> */}
                    </div>
                    <div className="z-40 lg:top-[140px] top-[100px] right-[70px] lg:right-[260px] md:right-[100px] absolute">
                      <div className="xl:h-[540px] h-[200px] w-[200px] xl:w-[700px] rounded-[50%] md:w-[400px] md:h-[300px]"></div>
                      <img
                        src="./assets/home-falcon.png"
                        alt=""
                        className="z-40 absolute xl:top-[160px] xl:left-[150px] md:top-[110px] md:left-[50px] xl:w-[610px] xl:h-[420px] md:w-[350px] md:h-[300px] w-[200px] left-[10px] top-[65px]"
                      />
                    </div>
                    <div className="bg-white  xl:h-[400px] h-[200px] w-[200px] xl:w-[430px] lg:w-[380px] lg:h-[380px] md:w-[290px] md:h-[280px] rounded-[50%] absolute xl:top-[110px] top-[90px] lg:right-[295px] lg:top-[100px] right-[90px] xl:right-[350px]  md:top-[125px] md:right-[170px] z-10"></div>
                    <div className="bg-[#4E7A97] xl:h-[390px] lg:h-[350px] xl:w-[430px] lg:w-[340px] h-[170px] w-[170px] md:w-[270px] md:h-[270px] rounded-[50%] absolute xl:top-[330px] md:top-[240px] md:right-[210px] top-[140px] right-[110px] xl:right-[390px] lg:right-[350px] lg:top-[240px] z-20"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Page  3 */}
          <div
            class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
            data-te-carousel-item
          >
            <div class="block w-full h-[100vh] md:h-[100vh]"></div>
            {/* <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed">
              <div class="md:flex lg:flex h-full relative">
                <div className="md:relative grid w-full absolute top-0 lg:w-[60%]">
                  <div className="  h-full justify-self-end">
                    <div className="bg-[#4E7A97] xl:h-[900px] lg:h-[700px] lg:w-[700px] md:h-[500px] md:w-[500px] h-[300px] w-[300px] xl:w-[900px] rounded-[50%]  relative xl:-top-[200px]  xl:-left-[220px] lg:-top-[100px] lg:-left-[160px] md:-left-[120px] left-[60px]"></div>

                    <div className="z-40 xl:top-[140px] lg:top-[120px] lg:left-[180px] top-[100px] right-[70px] xl:left-[260px] md:top-[145px] md:left-[140px] absolute">
                      <div className="bg-[#88A398] xl:h-[540px] h-[200px] w-[200px] xl:w-[550px] md:w-[335px] md:h-[345px] lg:w-[450px] lg:h-[450px] rounded-[50%]"></div>

                    </div>
                    <div className="z-40 lg:top-[140px] top-[100px] right-[70px] lg:left-[100px] absolute">
                      <div className="xl:h-[540px] h-[200px] w-[200px] xl:w-[700px] rounded-[50%] md:w-[400px] md:h-[300px]"></div>
                      <img src="./assets/home-hen.png" alt="" className="z-40 absolute xl:top-[70px] xl:left-[320px] lg:left-[110px] lg:top-[100px] md:top-[120px] md:left-[90px] md:h-[300px] md:w-[200px] xl:w-[360px] xl:h-[510px] lg:w-[230px] lg:h-[370px] w-[130px] left-[50px] top-[25px]" />
                    </div>
                    <div className="bg-white xl:h-[400px] h-[200px] w-[200px] xl:w-[430px] lg:w-[380px] lg:h-[380px] md:w-[290px] md:h-[280px] rounded-[50%] absolute xl:top-[110px] top-[90px] lg:left-[245px] lg:top-[100px] right-[90px] xl:left-[350px]  md:top-[125px] md:left-[175px] z-10"></div>
                    <div className="bg-[#E9A655] xl:h-[390px] lg:h-[350px] xl:w-[430px] lg:w-[340px] h-[170px] w-[170px] md:w-[270px] md:h-[270px] rounded-[50%] absolute xl:top-[330px] md:top-[240px] md:left-[200px] top-[140px] right-[110px] xl:left-[380px] lg:left-[280px] lg:top-[240px] z-20"></div>
                  </div>
                </div>
                <div className="banner-arabic lg:w-[40%] h-full content-end  grid justify-center md:content-center xl:pr-[120px] md:pr-[20px]">
                  <p className="lg:text-[44px] text-[24px] text-justify lg:text-start font-semibold text-[#88A398] lg:py-4">
                  </p>
                  <p className="lg:text-[24px] text-[14px] font-normal text-justify lg:text-start text-[#88A398] w-[200px] lg:w-[390px] xl:w-[500px] lg:leading-[35px] mb-5">
                  </p>
                  <div className="text-justify lg:text-start">
                    <a href="/poultry">
                      {" "}
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#88A398] text-white rounded-[10px]">
                      </button>{" "}
                    </a>
                  </div>
                  <div className="text-justify lg:text-start">
                    <a href="/poultry">
                      {" "}
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#E9A655] text-white rounded-[10px]">
                      </button>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            <div class="flip absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed">
              <div class="md:flex lg:flex h-full relative">
                <div className="flip banner-arabic lg:w-[40%] h-full content-end lg:justify-start grid justify-center md:content-center xl:pr-[120px] md:pr-[20px] md:mt-10 mt-0">
                  <p className="lg:text-[44px] text-[24px] text-justify lg:text-start font-semibold text-[#88A398] lg:py-4">
                    طب الدواجن
                  </p>
                  <p className="lg:text-[24px] text-[14px] font-normal text-justify lg:text-start text-[#88A398] w-[300px] lg:w-[390px] lg:leading-[35px] mb-5">
                    مجموعة من المنتجات تحافظ على حيوانك قويا ومنتجا.
                  </p>

                  <div className="text-justify lg:text-start">
                    <Link to={"/poultry_arabic"}>
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#88A398] text-white rounded-[10px]">
                        تفاصيل الدواجن
                      </button></Link>
                  </div>
                  <div className="text-justify lg:text-start">
                    <Link to={"/poultry_arabic"}>
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#E9A655] text-white rounded-[10px]">
                        منتجات أخرى
                      </button></Link>
                  </div>
                </div>
                <div className="lg:relative grid w-full absolute top-0">
                  <div className="  h-full justify-self-end">
                    <div className="bg-[#4E7A97] xl:h-[900px] lg:h-[700px] lg:w-[700px] md:h-[500px] md:w-[500px] h-[300px] w-[300px] xl:w-[900px] rounded-[50%]  relative xl:-top-[200px]  xl:left-[220px] lg:-top-[100px] lg:left-[120px] md:left-[90px] left-[60px]"></div>
                    <div className="z-40 xl:top-[140px] lg:top-[120px] lg:right-[240px] top-[100px] right-[70px] xl:right-[260px] md:top-[150px] md:right-[140px] absolute">
                      <div className=" bg-[#88A398]  xl:h-[540px] h-[200px] w-[200px] xl:w-[550px] md:w-[335px] md:h-[345px] lg:w-[450px] lg:h-[450px] rounded-[50%]"></div>
                    </div>
                    <div className="z-40 lg:top-[140px] top-[100px] right-[70px] lg:right-[260px] absolute">
                      <div className="xl:h-[540px] h-[200px] w-[200px] xl:w-[700px] rounded-[50%] md:w-[400px] md:h-[300px]"></div>
                      <img
                        src="./assets/home-hen.png"
                        alt=""
                        className="z-40 absolute xl:top-[70px] xl:left-[320px] lg:left-[110px] lg:top-[100px] md:top-[120px] md:left-[90px] md:h-[300px] md:w-[200px] xl:w-[360px] xl:h-[510px] lg:w-[230px] lg:h-[370px] w-[130px] left-[50px] top-[25px]"
                      />
                    </div>
                    <div className="bg-white  xl:h-[400px] h-[200px] w-[200px] xl:w-[430px] lg:w-[380px] lg:h-[380px] md:w-[290px] md:h-[280px] rounded-[50%] absolute xl:top-[110px] top-[90px] lg:right-[295px] lg:top-[100px] right-[90px] xl:right-[350px]  md:top-[125px] md:right-[170px] z-10"></div>
                    <div className="bg-[#E9A655] xl:h-[390px] lg:h-[350px] xl:w-[430px] lg:w-[340px] h-[170px] w-[170px] md:w-[270px] md:h-[270px] rounded-[50%] absolute xl:top-[330px] md:top-[240px] md:right-[210px] top-[140px] right-[110px] xl:right-[390px] lg:right-[350px] lg:top-[240px] z-20"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Page  4 */}
          <div
            class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
            data-te-carousel-item
          >
            <div class="block w-full h-[100vh] md:h-[100vh]"></div>
            {/* <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed">
              <div class="md:flex lg:flex h-full relative">
                <div className="md:relative grid w-full absolute top-0 lg:w-[60%]">
                  <div className="  h-full justify-self-end">
                    <div className="bg-[#88A398] xl:h-[900px] lg:h-[700px] lg:w-[700px] md:h-[500px] md:w-[500px] h-[300px] w-[300px] xl:w-[900px] rounded-[50%]  relative xl:-top-[200px]  xl:-left-[220px] lg:-top-[100px] lg:-left-[160px] md:-left-[120px] left-[60px]"></div>

                    <div className="z-40 xl:top-[140px] lg:top-[120px] lg:left-[180px] top-[100px] right-[70px] xl:left-[260px] md:top-[145px] md:left-[140px] absolute">
                      <div className="bg-[#E9A655] xl:h-[540px] h-[200px] w-[200px] xl:w-[550px] md:w-[335px] md:h-[345px] lg:w-[450px] lg:h-[450px] rounded-[50%]"></div>

                    </div>
                    <div className="z-40 lg:top-[140px] top-[100px] right-[70px] lg:left-[160px] absolute">
                      <div className="lg:h-[540px] h-[200px] w-[200px] xl:w-[700px] md:w-[400px] md:h-[300px]"></div>
                      <img src="./assets/home-horse.png" alt="" className="z-40 absolute xl:top-[100px] xl:left-[210px] xl:w-[600px] xl:h-[480px] lg:h-[360px] lg:w-[400px] lg:top-[90px] md:w-[300px] w-[170px] left-[40px] lg:left-[30px] top-[50px] md:top-[130px]" />
                    </div>
                    <div className="bg-white xl:h-[400px] h-[200px] w-[200px] xl:w-[430px] lg:w-[380px] lg:h-[380px] md:w-[290px] md:h-[280px] rounded-[50%] absolute xl:top-[110px] top-[90px] lg:left-[245px] lg:top-[100px] right-[90px] xl:left-[350px]  md:top-[125px] md:left-[175px] z-10"></div>
                    <div className="bg-[#4E7A97] xl:h-[390px] lg:h-[350px] xl:w-[430px] lg:w-[340px] h-[170px] w-[170px] md:w-[270px] md:h-[270px] rounded-[50%] absolute xl:top-[330px] md:top-[240px] md:left-[200px] top-[140px] right-[110px] xl:left-[380px] lg:left-[280px] lg:top-[240px] z-20"></div>
                  </div>
                </div>
                <div className="banner-arabic lg:w-[40%] h-full content-end  grid justify-center md:content-center md:pr-[20px] xl:pr-[120px] md:mt-10 mt-0">
                  <p className="lg:text-[44px] text-[24px] text-justify lg:text-start font-semibold text-[#88A398] lg:py-4">
                    
                  </p>

                  <p className="lg:text-[24px] text-[14px] font-normal text-justify lg:text-start text-[#88A398] w-[200px]  xl:w-[500px] lg:w-[350px] lg:leading-[35px] mb-5">
                  </p>

                  <div className="text-justify lg:text-start">
                    <a href="/equine">

                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#88A398] text-white rounded-[10px]">
                      </button>
                    </a>
                  </div>
                  <div className="text-justify lg:text-start">
                    <a href="/equine">

                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#E9A655] text-white rounded-[10px]">
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div> */}

            <div class="flip absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed">
              <div class="md:flex lg:flex h-full relative">
                <div className="flip banner-arabic lg:w-[40%] h-full content-end  grid justify-center lg:justify-start md:content-center md:pr-[20px] xl:pr-[120px] md:mt-10 mt-0">
                  <p className="lg:text-[44px] text-[24px] text-justify lg:text-start font-semibold text-[#88A398] lg:py-4">
                    فرسي
                  </p>
                  {/* <p className="lg:text-[28px] text-[18px] font-semibold text-justify lg:text-start text-[#5F726F] lg:pb-5 pb-1">
                    All about biosecurity
                  </p> */}
                  <p className="lg:text-[24px] text-[14px] font-normal text-justify lg:text-start text-[#88A398] w-[300px] xl:w-[500px] lg:w-[390px] lg:leading-[35px] mb-5">
                    منتج طبيعي فريد مصمم للمساعدة في حماية مجرى الهواء العلوي للحصان من مسببات الحساسية الهوائية المهيجة.

                  </p>

                  <div className="text-justify lg:text-start">
                    <Link to={"/equine_arabic"}>
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#88A398] text-white rounded-[10px]">
                        تفاصيل الخيول
                      </button></Link>
                  </div>
                  <div className="text-justify lg:text-start">
                    <Link to={"/equine_arabic"}>
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#E9A655] text-white rounded-[10px]">
                        منتجات أخرى
                      </button></Link>
                  </div>
                </div>
                <div className="lg:relative grid w-full absolute top-0">
                  <div className="  h-full justify-self-end">
                    <div className="bg-[#88A398] xl:h-[900px] lg:h-[700px] lg:w-[700px] md:h-[500px] md:w-[500px] h-[300px] w-[300px] xl:w-[900px] rounded-[50%]  relative xl:-top-[200px]  xl:left-[220px] lg:-top-[100px] lg:left-[120px] md:left-[90px] left-[60px]"></div>
                    <div className="z-40 xl:top-[140px] lg:top-[120px] lg:right-[240px] top-[100px] right-[70px] xl:right-[260px] md:top-[150px] md:right-[140px] absolute">
                      <div className="bg-[#E9A655] xl:h-[540px] h-[200px] w-[200px] xl:w-[550px] md:w-[335px] md:h-[345px] lg:w-[450px] lg:h-[450px] rounded-[50%]"></div>
                      {/* <img src="./assets/home-falcon.png" alt="" className="z-40 absolute xl:top-[180px] xl:left-[210px] xl:w-[700px] xl:h-[420px] w-[200px] left-[50px] top-[165px]" /> */}
                    </div>
                    <div className="z-40 lg:top-[140px] top-[100px] right-[70px] lg:right-[260px] absolute">
                      <div className="lg:h-[540px] h-[200px] w-[200px] xl:w-[700px] md:w-[400px] md:h-[300px]"></div>
                      <img
                        src="./assets/home-horse.png"
                        alt=""
                        className="z-40 absolute xl:top-[100px] xl:left-[210px] xl:w-[600px] xl:h-[480px] lg:h-[360px] lg:w-[400px] lg:top-[90px] md:w-[300px] w-[170px] left-[40px] lg:left-[30px] top-[50px] md:top-[130px]"
                      />
                    </div>
                    <div className="bg-white  xl:h-[400px] h-[200px] w-[200px] xl:w-[430px] lg:w-[380px] lg:h-[380px] md:w-[290px] md:h-[280px] rounded-[50%] absolute xl:top-[110px] top-[90px] lg:right-[295px] lg:top-[100px] right-[90px] xl:right-[350px]  md:top-[125px] md:right-[170px] z-10"></div>
                    <div className="bg-[#4E7A97] xl:h-[390px] lg:h-[350px] xl:w-[430px] lg:w-[340px] h-[170px] w-[170px] md:w-[270px] md:h-[270px] rounded-[50%] absolute xl:top-[330px] md:top-[240px] md:right-[210px] top-[140px] right-[110px] xl:right-[390px] lg:right-[350px] lg:top-[240px] z-20"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* slide 5  */}
          <div
            class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
            data-te-carousel-item
          >
            <div class="block w-full h-[100vh] md:h-[100vh]"></div>
            {/* <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed">
              <div class="md:flex lg:flex h-full relative">
              <div className="md:relative grid w-full absolute top-0  lg:w-[60%]">
                  <div className="  h-full justify-self-end">

                    <div className="bg-[#88A398] xl:h-[900px] lg:h-[700px] lg:w-[700px] md:h-[500px] md:w-[500px] h-[300px] w-[300px] xl:w-[900px] rounded-[50%]  relative xl:-top-[200px]  xl:-left-[220px] lg:-top-[100px] lg:-left-[160px] md:-left-[120px] left-[60px]"></div>
                    
                    <div className="z-40 xl:top-[140px] lg:top-[120px] lg:left-[180px] top-[100px] right-[70px] xl:left-[260px] md:top-[145px] md:left-[140px] absolute">
                      <div className="bg-[#E9A655] xl:h-[540px] h-[200px] w-[200px] xl:w-[550px] md:w-[335px] md:h-[345px] lg:w-[450px] lg:h-[450px] rounded-[50%]"></div>
                      
                    </div>
                    <div className="z-40 lg:top-[140px] top-[100px] right-[70px] lg:left-[160px] absolute">
                      <div className="lg:h-[540px] h-[200px] w-[200px] xl:w-[700px] md:w-[400px] md:h-[300px]"></div>
                      <img src="./assets/home-equipment.png" alt="" className="z-40 absolute xl:top-[80px] xl:left-[210px] lg:top-[30px] xl:w-[600px] xl:h-[520px] lg:h-[400px] lg:w-[500px] md:top-[110px] md:w-[320px] w-[170px] left-[45px] top-[45px]" />
                    </div>
                    <div className="bg-white xl:h-[400px] h-[200px] w-[200px] xl:w-[430px] lg:w-[380px] lg:h-[380px] md:w-[290px] md:h-[280px] rounded-[50%] absolute xl:top-[110px] top-[90px] lg:left-[245px] lg:top-[100px] right-[90px] xl:left-[350px]  md:top-[125px] md:left-[175px] z-10"></div>
                    <div className="bg-[#4E7A97] xl:h-[390px] lg:h-[350px] xl:w-[430px] lg:w-[340px] h-[170px] w-[170px] md:w-[270px] md:h-[270px] rounded-[50%] absolute xl:top-[330px] md:top-[240px] md:left-[200px] top-[140px] right-[110px] xl:left-[380px] lg:left-[280px] lg:top-[240px] z-20"></div>
                  </div>
                </div>
                <div className="banner-arabic md:w-[40%] h-full content-end  grid justify-center md:content-center xl:pr-[120px] md:pr-[0px] md:mt-10 mt-0">
                  <p className="lg:text-[38px] text-[24px] text-justify lg:text-start font-semibold text-[#88A398] lg:py-4">
                  </p>
                  
                  <p className="lg:text-[24px] text-[14px] font-normal text-justify lg:text-start text-[#88A398] w-[300px] md:w-[160px] xl:w-[500px] lg:w-[390px] lg:leading-[35px] mb-5">
                  </p>

                  <div className="text-justify lg:text-start">
                    <a href="/electric_equipment">
                      {" "}
                      <button className="lg:text-[20px]  text-[16px] font-semibold  w-[160px] lg:w-[235px] py-3 mb-5 bg-[#88A398] text-white rounded-[10px]">
                      </button>{" "}
                    </a>
                  </div>
                  <div className="text-justify lg:text-start">
                    <a href="/electric_equipment">
                      {" "}
                      <button className="lg:text-[20px]  text-[16px] font-semibold  w-[140px] lg:w-[235px] py-3 mb-5 bg-[#E9A655] text-white rounded-[10px]">
                      </button>{" "}
                    </a>
                  </div>
                </div>                
              </div>
            </div> */}

            <div class="flip absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed">
              <div class="md:flex lg:flex h-full relative">
                <div className="flip banner-arabic lg:w-[40%] h-full content-end  grid justify-center lg:justify-start md:content-center xl:pr-[120px] md:pr-[20px] md:mt-10 mt-0">
                  <p className="lg:text-[38px] text-[24px] text-justify lg:text-start font-semibold text-[#88A398] lg:py-4">
                    معدات بيطرية
                  </p>
                  {/* <p className="lg:text-[28px] text-[18px] font-semibold text-justify lg:text-start text-[#5F726F] lg:pb-5 pb-1">
                    All about biosecurity
                  </p> */}
                  <p className="lg:text-[24px] text-[14px] font-normal text-justify lg:text-start text-[#88A398] w-[300px] md:w-[260px] xl:w-[500px] lg:w-[390px] lg:leading-[35px] mb-5">
                    لدينا مجموعة واسعة من لوحات التحكم في المناخ لأجنحة الدواجن والبيوت المحمية والصوامع. لمزيد من المنتجات والاستفسارات يرجى التواصل معنا
                  </p>

                  <div className="text-justify lg:text-start">
                    <Link to={"/electric_arabic"}>
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[160px] lg:w-[235px] py-3 mb-5 bg-[#88A398] text-white rounded-[10px]">
                        تفاصيل المعدات
                      </button></Link>
                  </div>
                  <div className="text-justify lg:text-start">
                    <Link to={"/electric_arabic"}>
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#E9A655] text-white rounded-[10px]">
                        منتجات أخرى
                      </button></Link>
                  </div>
                </div>
                <div className="lg:relative grid w-full absolute top-0">
                  <div className="  h-full justify-self-end">
                    <div className="bg-[#88A398] xl:h-[900px] lg:h-[700px] lg:w-[700px] md:h-[500px] md:w-[500px] h-[300px] w-[300px] xl:w-[900px] rounded-[50%]  relative xl:-top-[200px]  xl:left-[220px] lg:-top-[100px] lg:left-[120px] md:left-[90px] left-[60px]"></div>
                    <div className="z-40 xl:top-[140px] lg:top-[120px] lg:right-[240px] top-[100px] right-[70px] xl:right-[260px] md:top-[150px] md:right-[140px] absolute">
                      <div className="bg-[#E9A655] xl:h-[540px] h-[200px] w-[200px] xl:w-[550px] md:w-[335px] md:h-[345px] lg:w-[450px] lg:h-[450px] rounded-[50%]"></div>
                      {/* <img src="./assets/home-falcon.png" alt="" className="z-40 absolute xl:top-[180px] xl:left-[210px] xl:w-[700px] xl:h-[420px] w-[200px] left-[50px] top-[165px]" /> */}
                    </div>
                    <div className="z-40 lg:top-[140px] top-[100px] right-[70px] lg:right-[260px] absolute">
                      <div className="lg:h-[540px] h-[200px] w-[200px] xl:w-[700px] md:w-[400px] md:h-[300px]"></div>
                      <img
                        src="./assets/home-equipment.png"
                        alt=""
                        className="flip z-40 absolute xl:top-[80px] xl:left-[210px] lg:top-[30px] xl:w-[600px] xl:h-[520px] lg:h-[400px] lg:w-[500px] md:top-[110px] md:w-[320px] w-[170px] left-[45px] top-[45px]"
                      />
                    </div>
                    <div className="bg-white  xl:h-[400px] h-[200px] w-[200px] xl:w-[430px] lg:w-[380px] lg:h-[380px] md:w-[290px] md:h-[280px] rounded-[50%] absolute xl:top-[110px] top-[90px] lg:right-[295px] lg:top-[100px] right-[90px] xl:right-[350px]  md:top-[125px] md:right-[170px] z-10"></div>
                    <div className="bg-[#4E7A97] xl:h-[390px] lg:h-[350px] xl:w-[430px] lg:w-[340px] h-[170px] w-[170px] md:w-[270px] md:h-[270px] rounded-[50%] absolute xl:top-[330px] md:top-[240px] md:right-[210px] top-[140px] right-[110px] xl:right-[390px] lg:right-[350px] lg:top-[240px] z-20"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* slide 6 */}

          <div
            class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
            data-te-carousel-item
          >
            <div class="block w-full h-[100vh] md:h-[100vh]"></div>
            {/* <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed">
              <div class="md:flex h-full relative">
                <div className="md:relative grid w-full absolute top-0  lg:w-[60%]">
                  <div className="h-full justify-self-end">
                    <div className="bg-[#E9A655] xl:h-[900px] lg:h-[700px] lg:w-[700px] md:h-[500px] md:w-[500px] h-[300px] w-[300px] xl:w-[900px] rounded-[50%]  relative xl:-top-[200px]  xl:-left-[220px] lg:-top-[100px] lg:-left-[160px] md:-left-[120px] left-[60px]"></div>
                    <div className="z-40 xl:top-[140px] lg:top-[120px] lg:left-[180px] top-[100px] right-[70px] xl:left-[260px] md:top-[145px] md:left-[140px] absolute">
                      <div className="bg-[#88A398] xl:h-[540px] h-[200px] w-[200px] xl:w-[550px] md:w-[335px] md:h-[345px] lg:w-[450px] lg:h-[450px] rounded-[50%]"></div>

                    </div>
                    <div className="z-40 lg:top-[140px] top-[100px] right-[70px] lg:left-[110px] absolute">
                      <div className="xl:h-[540px] h-[200px] w-[200px] xl:w-[700px] lg:w-[600px] rounded-[50%] md:w-[400px] md:h-[300px]"></div>
                      <img src="./assets/home-large1.png" alt="" className="z-40 absolute xl:top-[190px] xl:left-[10px] left-[20px] top-[165px] md:top-[180px] md:left-[60px] top-[90px] lg:w-[370px] lg:h-[300px] lg:top-[150px] lg:left-[10px] xl:w-[460px] xl:h-[340px]  md:w-[220px] md:h-[210px] w-[150px] " />
                      <img src="./assets/home-large2.png" alt="" className="z-40 absolute xl:bottom-0 xl:left-[0px] left-[10px] md:-bottom-[100px] lg:-bottom-[130px] md:-left-[0px] lg:left-0    xl:w-[190px] xl:h-[190px] md:w-[130px] md:h-[120px] w-[60px] -left-1 lg:h-[150px] lg:w-[160px] bottom-0" />
                      <img src="./assets/home-large3.png" alt="" className="z-40 absolute lg:top-[210px] xl:top-[270px] lg:left-[360px] xl:left-[460px] md:top-[260px] md:right-[30px] xl:w-[180px] xl:h-[270px] md:w-[90px] md:h-[140px] lg:h-[240px] lg:w-[140px] w-[60px] -right-6 top-[120px] h-[80px]" />
                    </div>
                    <div className="bg-white xl:h-[400px] h-[200px] w-[200px] xl:w-[430px] lg:w-[380px] lg:h-[380px] md:w-[290px] md:h-[280px] rounded-[50%] absolute xl:top-[110px] top-[90px] lg:left-[245px] lg:top-[100px] right-[90px] xl:left-[350px]  md:top-[125px] md:left-[175px] z-10"></div>
                    <div className="bg-[#4E7A97] xl:h-[390px] lg:h-[350px] xl:w-[430px] lg:w-[340px] h-[170px] w-[170px] md:w-[270px] md:h-[270px] rounded-[50%] absolute xl:top-[330px] md:top-[240px] md:left-[200px] top-[140px] right-[110px] xl:left-[380px] lg:left-[280px] lg:top-[240px] z-20"></div>
                  </div>
                </div>
                <div className="banner-arabic md:w-[40%] h-full content-end  grid justify-center md:content-center xl:pr-[120px] md:pr-[0px]">
                  <p className="lg:text-[44px] text-[24px] text-justify lg:text-start font-semibold text-[#88A398] lg:py-4">
                  </p>

                  <p className="lg:text-[24px] text-[14px] font-normal text-justify lg:text-start text-[#88A398] w-[200px] lg:w-[390px] xl:w-[500px] lg:leading-[35px] mb-5">
                  </p>
                  <div className="text-justify lg:text-start">
                    <a href="/large_animal">
                      {" "}
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[190px] lg:w-[235px] py-3 mb-5 bg-[#88A398] text-white rounded-[10px]">
                      </button>{" "}
                    </a>
                  </div>
                  <div className="text-justify lg:text-start">
                    <a href="/large_animal">
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#E9A655] text-white rounded-[10px]">
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div> */}


            <div class="flip absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed">
              <div class="md:flex lg:flex h-full relative">
                <div className="flip banner-arabic lg:w-[40%] h-full content-end  grid justify-center lg:justify-start md:content-center xl:pr-[120px] md:pr-[20px] md:mt-10 mt-0">
                  <p className="lg:text-[44px] text-[24px] text-justify lg:text-start font-semibold text-[#88A398] lg:py-4">
                    الحيوانات الكبيرة
                  </p>
                  {/* <p className="lg:text-[28px] text-[18px] font-semibold text-justify lg:text-start text-[#5F726F] lg:pb-5 pb-1">
                    All about biosecurity
                  </p> */}
                  <p className="lg:text-[24px] text-[14px] font-normal text-justify lg:text-start text-[#88A398] w-[300px] lg:w-[390px] lg:leading-[35px] mb-5">
                    مجموعة من المنتجات تحافظ على حيوانك قويا ومنتجا
                  </p>
                  <div className="text-justify lg:text-start">
                    <Link to={"/large_arabic"}>
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[190px] lg:w-[235px] py-3 mb-5 bg-[#88A398] text-white rounded-[10px]">
                        تفاصيل الحيوانات الكبيرة
                      </button></Link>
                  </div>
                  <div className="text-justify lg:text-start">
                    <Link to={"/large_arabic"}>
                      <button className="lg:text-[20px]  text-[16px] font-semibold border w-[140px] lg:w-[235px] py-3 mb-5 bg-[#E9A655] text-white rounded-[10px]">
                        منتجات أخرى
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="lg:relative grid w-full absolute top-0">
                  <div className="h-full justify-self-end">
                    <div className="bg-[#E9A655] xl:h-[900px] lg:h-[700px] lg:w-[700px] md:h-[500px] md:w-[500px] h-[300px] w-[300px] xl:w-[900px] rounded-[50%]  relative xl:-top-[200px]  xl:left-[220px] lg:-top-[100px] lg:left-[120px] md:left-[90px] left-[60px]"></div>
                    <div className="z-40 xl:top-[140px] lg:top-[120px] lg:right-[240px] top-[100px] right-[70px] xl:right-[260px] md:top-[150px] md:right-[140px] absolute">
                      <div className="bg-[#88A398] xl:h-[540px] h-[200px] w-[200px] xl:w-[550px] md:w-[335px] md:h-[345px] lg:w-[450px] lg:h-[450px] rounded-[50%]"></div>
                      {/* <img src="./assets/home-falcon.png" alt="" className="z-40 absolute xl:top-[180px] xl:left-[210px] xl:w-[700px] xl:h-[420px] w-[200px] left-[50px] top-[165px]" /> */}
                    </div>
                    <div className="z-40 lg:top-[140px] top-[100px] right-[70px] lg:right-[110px] absolute">
                      <div className="xl:h-[540px] h-[200px] w-[200px] xl:w-[700px] lg:w-[600px] rounded-[50%] md:w-[400px] md:h-[300px]"></div>
                      <img
                        src="./assets/home-large1.png"
                        alt=""
                        className="z-40 absolute xl:top-[190px] xl:left-[10px] left-[20px] md:top-[180px] md:left-[60px] top-[90px] lg:w-[370px] lg:h-[300px] lg:top-[150px] lg:left-[40px] xl:w-[460px] xl:h-[340px]  md:w-[220px] md:h-[210px] w-[150px] "
                      />
                      <img
                        src="./assets/home-large2.png"
                        alt=""
                        className="z-40 absolute xl:bottom-0 xl:left-[0px] left-[10px] md:-bottom-[100px] lg:-bottom-[130px] md:-left-[0px] lg:left-0    xl:w-[190px] xl:h-[190px] md:w-[130px] md:h-[120px] w-[60px]  lg:h-[150px] lg:w-[160px] bottom-0"
                      />
                      <img
                        src="./assets/home-large3.png"
                        alt=""
                        className="z-40 absolute lg:top-[210px] xl:top-[270px] lg:right-[60px] md:top-[260px] md:right-[30px] xl:w-[180px] xl:h-[270px] md:w-[90px] md:h-[140px] lg:h-[240px] lg:w-[140px] w-[60px] -right-6 top-[120px] h-[80px]"
                      />
                    </div>
                    <div className="bg-white  xl:h-[400px] h-[200px] w-[200px] xl:w-[430px] lg:w-[380px] lg:h-[380px] md:w-[290px] md:h-[280px] rounded-[50%] absolute xl:top-[110px] top-[90px] lg:right-[295px] lg:top-[100px] right-[90px] xl:right-[350px]  md:top-[125px] md:right-[170px] z-10"></div>
                    <div className="bg-[#4E7A97] xl:h-[390px] lg:h-[350px] xl:w-[430px] lg:w-[340px] h-[170px] w-[170px] md:w-[270px] md:h-[270px] rounded-[50%] absolute xl:top-[330px] md:top-[240px] md:right-[210px] top-[140px] right-[110px] xl:right-[390px] lg:right-[350px] lg:top-[240px] z-20"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          class="absolute z-50 ml-1 md:ml-0 bottom-0 left-0 top-0 flex w-[26px] items-center justify-center border-0 bg-none p-0 text-justify text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
          type="button"
          data-te-target="#carouselExampleIndicators"
          data-te-slide="prev"
        >
          <span class="inline-block h-8 w-8 invisible">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-6 w-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </span>
          <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Previous
          </span>
        </button>

        <button
          class="absolute mr-1 bottom-0 right-0 top-0 z-[1] flex w-[26px] items-center justify-center border-0 bg-none p-0 text-justify text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
          type="button"
          data-te-target="#carouselExampleIndicators"
          data-te-slide="next"
        >
          <span class="inline-block h-8 w-8 invisible">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-6 w-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Next
          </span>
        </button>
      </div>
      <div className="xl:px-[120px] pb-14 mt-10">
        <div className="w-full flex justify-center">
          <p
            className="md:text-[36px] text-[30px] font-semibold text-[#88A398]"
            data-aos="fade-up"
          >
            فوائدنا
          </p>
        </div>
        <div className="lg:flex justify-between mt-5">
          <div className=" w-ful grid justify-items-center mb-5  h-[300px] ">
            <img
              src="./assets/benefit-1.png"
              alt=""
              className="bg-[#F0F6FA] rounded-[50%] h-20 w-20 p-5" data-aos="fade-up"
              data-aos-duration="2000"
            />
            <p className="md:text-[30px] text-[26px] font-bold py-3 text-[#88A398]" data-aos="fade-up"
              data-aos-duration="2500">
              أدوية بيطرية موثوقة
            </p>
            <p className="md:text-[23px] text-[20px] w-[290px]  md:w-[330px] h-[120px] text-jusify text-justify text-[#7088A0]" data-aos="fade-up"
              data-aos-duration="2500">
              نحن نقدم أدوية بيطرية ذات نوعية جيدة في الشرق الأوسط الطبيب البيطري. هو شريكك المفضل. نحن متحمسون لصحة الحيوان ونلتزم بإحداث تأثير إيجابي على المجتمع.
            </p>
          </div>
          <div className=" w-ful grid justify-items-center mb-5 h-[300px] ">
            <img
              src="./assets/benefit-2.png"
              alt=""
              className="bg-[#F0F6FA] rounded-[50%] h-20 w-20 p-5" data-aos="fade-up"
              data-aos-duration="2000"
            />
            <p className="md:text-[30px] text-[26px] font-bold py-3 text-[#88A398]" data-aos="fade-up"
              data-aos-duration="2500">
              أحدث الاتجاهات والتقنيات
            </p>
            <p className="md:text-[23px] text-[20px]  w-[290px] lg:w-[330px] h-[120px] text-jusify text-justify text-[#7088A0]" data-aos-duration="2500" data-aos="fade-up"
            >
              نحن نفخر بأنفسنا على الحلول المبتكرة التي تميزنا عن المنافسة. فريق الخبراء لدينا مكرس لتقديم منتجات عالية الجودة وتقديم خدمات متميزة.
            </p>
          </div>
          <div className=" w-ful grid justify-items-center mb-5 h-[300px] ">
            <img
              src="./assets/benefit-3.png"
              alt=""
              className="bg-[#F0F6FA] rounded-[50%] h-20 w-20 p-5" data-aos="fade-up"
              data-aos-duration="2000"
            />
            <p className="md:text-[30px] text-[26px] font-bold text-[#88A398] py-3" data-aos="fade-up"
              data-aos-duration="2500">
              شريكك الموثوق به
            </p>
            <p className="md:text-[23px] text-[20px]  w-[290px] lg:w-[330px] h-[120px] text-jusify text-justify text-[#7088A0]" data-aos="fade-up"
              data-aos-duration="2500">
              تتطلع شركة الشرق الأوسط للتجهيزات البيطرية إلى فرصة العمل معك ومساعدتك في تحقيق أهدافك.
            </p>
          </div>
        </div>
      </div>

      {/* part-3 exhibition */}

      <div className="hidden md:block ">
        <div className="">
          <div
            id="carouselExampleIndicators1"
            class="relative"
            data-te-carousel-init
            data-te-ride="carousel"
          >
            <div
              class="absolute xl:bottom-0 lg:-bottom-5 md:-bottom-5 left-0 right-0 z-[2] mx-[15%] mb-4 flex list-none justify-center p-0"
              data-te-carousel-indicators
            >
              <div
                type="button"
                data-te-target="#carouselExampleIndicators1"
                data-te-slide-to="0"
                data-te-carousel-active
                class="mx-[3px] box-content h-[10px] w-[10px] rounded-[50%] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-[#88A398] bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-current="true"
                aria-label="Slide 1"
              ></div>
              <div
                type="button"
                data-te-target="#carouselExampleIndicators1"
                data-te-slide-to="1"
                class="mx-[3px] box-content h-[10px] w-[10px] rounded-[50%] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-[#88A398] bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-label="Slide 2"
              ></div>
              <div
                type="button"
                data-te-target="#carouselExampleIndicators1"
                data-te-slide-to="2"
                class="mx-[3px] box-content h-[10px] w-[10px] rounded-[50%] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-[#88A398] bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-label="Slide 3"
              ></div>
            </div>

            <div class="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
              <div
                class="relative xl:h-[43vh] lg:h-[48vh] md:h-[48vh] float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item
                data-te-carousel-active
              >
                <div className="absolute top-0 w-full h-full ">
                  <div className="flip grid flex-row pb-5 h-[100px] content-center relative">
                    <p className="flip font-serif xl:text-[28px] lg:text-[24px] md:text-[20px] justify-self-center mt-5 text-[#AF822E] font-semibold">
                      ADIHEX 2023
                    </p>
                    <img
                      src="./assets/abudhabi.png"
                      alt=""
                      className="flip justify-self-end -mt-10 xl:mr-[120px] lg:mr-[90px] md:mr-[70px] xl:w-[300px] lg:w-[200px] md:w-[180px]"
                    />

                    <img
                      src="./assets/foot-1.png"
                      alt=""
                      className="absolute left-[60px]"
                    />
                    <img
                      src="./assets/foot-2.png"
                      alt=""
                      className="absolute xl:left-[560px] lg:left-[360px] md:left-[260px] top-6"
                    />
                    <img
                      src="./assets/foot-3.png"
                      alt=""
                      className="absolute right-[0px]"
                    />
                  </div>

                  <div className="flex bg-[#FFF3DD] lg:px-[120px] md:px-[40px] h-full banner-arabic">
                    <div className="w-[40%] relative">
                      <div className="z-20 ">
                        <img
                          src="./assets/exhibition-1.png"
                          alt=""
                          className="rounded-[50%] absolute -top-10 border-[#AF822E] border-[30px] h-[300px]"
                        />
                      </div>
                      <img
                        src="./assets/foot-2.png"
                        alt=""
                        className="absolute -left-[100px] top-[150px] lg:block hidden"
                      />
                    </div>
                    <div className="w-[60%] lg:pr-5 xl:pr-10 md:pr-5">
                      <p className="text-[22px] text-[#857962] py-5">
                        تقام الدورة العشرون من معرض أبوظبي الدولي للصيد والفروسية في الفترة من 02 إلى 8 سبتمبر 2023 في مركز أبوظبي الوطني للمعارض (أدنيك)
                      </p>
                      <button className="bg-[#AF822E] text-white px-4 py-2 text-[16px] rounded-[10px]">
                        تفاصيل الفعالية
                      </button>
                      <img
                        src="./assets/foot-4.png"
                        alt=""
                        className="absolute lg:left-[60px] lg:top-[180px] md:top-[230px] md:left-1 md:h-[80px] lg:h-[120px]"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                class=" xl:h-[43vh] lg:h-[48vh] md:h-[48vh] float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item
              >
                {/* <div className="absolute top-0 w-full h-full ">
                  <div className="grid flex-row pb-5 h-[100px] content-center relative">
                    <div className="justify-self-center text-[#88A398] font-semibold lg:pl-20 xl:pl-5 md:pl-20">
                      <p className="font-serif xl:text-[22px] lg:text-[24px] md:text-[20px] mt-5  ">
                        الطب البيطري
                      </p>
                      <p className="font-serif xl:text-[32px] lg:text-[24px] md:text-[20px]">
                        معارض في الإمارات
                      </p>
                    </div>            
                    <img
                      src="./assets/2-foot-1.png"
                      alt=""
                      className="absolute left-[60px]"
                    />
                    <img
                      src="./assets/2-foot-2.png"
                      alt=""
                      className="absolute xl:left-[560px] lg:left-[330px] md:left-[260px] top-6"
                    />
                    <img
                      src="./assets/3-foot-3.png"
                      alt=""
                      className="absolute right-[0px]"
                    />
                  </div>

                  <div className="flex bg-[#F0F6FA] xl:px-[120px] lg:px-[100px] border md:px-[40px] h-full">
                    <div className="w-[40%] relative">
                      <div className="z-20 ">
                        <img
                          src="./assets/exhibition-1.png"
                          alt=""
                          className="rounded-[50%] absolute -top-10 border-[#AF822E] border-[30px] h-[300px]"
                        />
                      </div>
                      <img
                        src="./assets/2-foot-2.png"
                        alt=""
                        className="absolute -left-[100px] top-[150px] lg:block hidden"
                      />
                    </div>
                    <div className="w-[60%] lg:pl-0 md:pl-5">
                      <p className="text-[22px] py-5 text-[#4B7765] font-[500] lg:w-[550px] lg:pl-5 xl:pl-0 ">
                        حدث جديد على غرار مهرجان الشرق الأوسط للأطباء البيطريين والمتخصصين في صحة الحيوان وأعمال رعاية الحيوانات الأليفة
                      </p>
                      <div className="flex justify-between md:w-[300px] lg:w-[400px] lg:pl-5 xl:pl-0">
                        <div className="text-[#88A398] text-[20px] font-[600]">
                          <p className="">2500+</p>
                          <p className="">الحضور</p>
                        </div>
                        <div className="text-[#88A398] text-[20px] font-[600]">
                          <p className="">120+</p>
                          <p className="">العارضون</p>
                        </div>
                      </div>
                      <img
                        src="./assets/4-foot-4.png"
                        alt=""
                        className="absolute lg:right-[60px] lg:top-[180px] md:top-[230px] md:right-1 md:h-[80px] lg:h-[120px]"
                      />
                    </div>
                  </div>
                </div> */}
                <div className="absolute top-0 w-full h-full banner-arabic">
                  <div className="grid flex-row pb-5 h-[100px] content-center relative">
                    <div className="justify-self-center text-[#88A398] font-semibold lg:pr-20 xl:pl-[120px] md:pr-20">
                      <p className="font-serif xl:text-[22px] lg:text-[24px] md:text-[20px] mt-5  ">
                        الطب البيطري
                      </p>
                      <p className="font-serif xl:text-[32px] lg:text-[24px] md:text-[20px]">
                        معارض في الإمارات
                      </p>
                    </div>
                    <img
                      src="./assets/2-foot-1.png"
                      alt=""
                      className="absolute right-[60px]"
                    />
                    <img
                      src="./assets/2-foot-2.png"
                      alt=""
                      className="absolute xl:right-[560px] lg:right-[330px] md:right-[260px] top-6"
                    />
                    <img
                      src="./assets/3-foot-3.png"
                      alt=""
                      className="absolute left-[0px] flip"
                    />
                  </div>

                  <div className="flex bg-[#F0F6FA] xl:px-[120px] lg:px-[100px] border md:px-[40px] h-full">
                    <div className="w-[40%] relative">
                      <div className="z-20 ">
                        <div className="rounded-[50%] absolute lg:-top-14 md:-top-10 bg-[#E9A655] lg:h-[320px] lg:w-[320px] md:h-[260px] md:w-[260px] ">
                          <img
                            src="./assets/home-falcon.png"
                            alt=""
                            className="lg:h-[240px] md:h-[200px] absolute top-14 flip right-3"
                          />
                        </div>
                      </div>
                      <img
                        src="./assets/2-foot-2.png"
                        alt=""
                        className="absolute -left-[100px] top-[150px] lg:block hidden"
                      />
                    </div>
                    <div className="w-[60%] lg:pl-0 md:pl-5">
                      <p className="text-[18px] py-5 text-[#4B7765] font-[500] lg:w-[550px] lg:pl-5 xl:pl-0 ">
                        حدث جديد على غرار مهرجان الشرق الأوسط للأطباء البيطريين والمتخصصين في صحة الحيوان وأعمال رعاية الحيوانات الأليفة
                      </p>
                      <div className="flex justify-between md:w-[300px] lg:w-[400px] lg:pl-5 xl:pl-0">
                        <div className="text-[#88A398] text-[20px] font-[600]">
                          <p className="">2500+</p>
                          <p className="">الحضور</p>
                        </div>
                        <div className="text-[#88A398] text-[20px] font-[600]">
                          <p className="">120+</p>
                          <p className="">العارضون</p>
                        </div>
                      </div>
                      <img
                        src="./assets/4-foot-4.png"
                        alt=""
                        className="absolute lg:left-[60px] lg:top-[180px] md:top-[230px] md:left-1 md:h-[80px] lg:h-[120px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="relative xl:h-[43vh] lg:h-[48vh] md:h-[48vh] float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item
              >
                {/* <div className="absolute top-0 w-full h-full ">
                  <div className="grid flex-row pb-5 h-[100px] content-center relative">
                    <p className="font-serif xl:text-[28px] lg:text-[24px] md:text-[20px] justify-self-center mt-5 text-[#AF822E] font-semibold">
                      ADIHEX 2023
                    </p>
                    <img
                      src="./assets/abudhabi.png"
                      alt=""
                      className="justify-self-end -mt-10 xl:mr-[120px] lg:mr-[90px] md:mr-[70px] xl:w-[300px] lg:w-[200px] md:w-[180px]"
                    />

                    <img
                      src="./assets/foot-1.png"
                      alt=""
                      className="absolute left-[60px]"
                    />
                    <img
                      src="./assets/foot-2.png"
                      alt=""
                      className="absolute xl:left-[560px] lg:left-[360px] md:left-[260px] top-6"
                    />
                    <img
                      src="./assets/foot-3.png"
                      alt=""
                      className="absolute right-[0px]"
                    />
                  </div>

                  <div className="flex bg-[#FFF3DD] lg:px-[120px] md:px-[40px] h-full">
                    <div className="w-[40%] relative">
                      <div className="z-20 ">
                        <img
                          src="./assets/exhibition-1.png"
                          alt=""
                          className="rounded-[50%] absolute -top-10 border-[#AF822E] border-[30px] h-[300px]"
                        />
                      </div>
                      <img
                        src="./assets/foot-2.png"
                        alt=""
                        className="absolute -left-[100px] top-[150px] lg:block hidden"
                      />
                    </div>
                    <div className="w-[60%] lg:pl-5 xl:pl-10 md:pl-5">
                      <p className="text-[18px] text-[#857962] py-5">
                        The 20th edition of Abu Dhabi International Hunting and
                        Equestrian Exhibition (ADIHEX) will be held from 02 - 08
                        September 2023 at Abu Dhabi National Exhibition Centre
                        (ADNEC)
                      </p>
                      <button className="bg-[#AF822E] text-white px-4 py-2 text-[16px] rounded-[10px]">
                        Event Details
                      </button>
                      <img
                        src="./assets/foot-4.png"
                        alt=""
                        className="absolute lg:right-[60px] lg:top-[180px] md:top-[230px] md:right-1 md:h-[80px] lg:h-[120px]"
                      />
                    </div>
                  </div>
                </div> */}
                <div className="absolute top-0 w-full h-full banner-arabic">
                  <div className="grid flex-row pb-5 h-[100px] content-center relative">
                    <div className="justify-self-center text-[#88A398] font-[600] lg:pr-40 xl:pr-[250px] md:pr-40">
                      <p className="font-serif xl:text-[22px] lg:text-[24px] md:text-[20px] mt-5  ">
                        الشرق الأوسط وأفريقيا
                      </p>
                      <p className="font-serif xl:text-[32px] lg:text-[24px] md:text-[20px]">
                        المؤتمر البيطري ، دبي - الإمارات العربية المتحدة
                      </p>
                    </div>
                    {/* <img
                      src="./assets/abudhabi.png"
                      alt=""
                      className="justify-self-end -mt-10 xl:mr-[120px] lg:mr-[90px] md:mr-[70px] xl:w-[300px] lg:w-[200px] md:w-[180px]"
                    /> */}

                    <img
                      src="./assets/2-foot-1.png"
                      alt=""
                      className="absolute right-[60px]"
                    />
                    <img
                      src="./assets/2-foot-2.png"
                      alt=""
                      className="absolute xl:right-[560px] lg:right-[330px] md:right-[260px] top-6"
                    />
                    <img
                      src="./assets/3-foot-3.png"
                      alt=""
                      className="absolute left-[0px] flip"
                    />
                  </div>

                  <div className="flex bg-[#F0F6FA] xl:px-[120px] lg:px-[100px] border md:px-[40px] h-full">
                    <div className="w-[40%] relative">
                      <div className="z-20 ">
                        <div className="rounded-[50%] absolute lg:-top-14 md:-top-10 bg-[#88A398] lg:h-[320px] lg:w-[320px] md:h-[260px] md:w-[260px]">
                          <img
                            src="./assets/home-hen.png"
                            alt=""
                            className="lg:h-[220px] md:h-[180px] lg:w-[180px] md:w-[160px] absolute top-16 -right-4"
                          />
                          <img src="./assets/home-equipment.png" alt="" className="h-[130px] w-[160px] absolute top-[140px] flip lg:right-[130px] md:right-[100px]" />
                        </div>
                      </div>
                      <img
                        src="./assets/2-foot-2.png"
                        alt=""
                        className="absolute -left-[100px] top-[150px] lg:block hidden"
                      />
                    </div>
                    <div className="w-[60%] lg:pl-0 md:pl-5">
                      <p className="text-[18px] py-5 text-[#4B7765] font-[500] lg:w-[550px] lg:pl-5 xl:pl-0 ">
                        حدث جديد على غرار مهرجان الشرق الأوسط للأطباء البيطريين والمتخصصين في صحة الحيوان وأعمال رعاية الحيوانات الأليفة
                      </p>
                      <div className="flex justify-between md:w-[300px] lg:w-[400px] lg:pl-5 xl:pl-0">
                        <div className="text-[#88A398] text-[20px] font-[600]">
                          <p className="">2500+</p>
                          <p className="">الحضور</p>
                        </div>
                        <div className="text-[#88A398] text-[20px] font-[600]">
                          <p className="">120+</p>
                          <p className="">العارضون</p>
                        </div>
                      </div>
                      <img
                        src="./assets/4-foot-4.png"
                        alt=""
                        className="absolute lg:left-[60px] lg:top-[180px] md:top-[230px] md:left-1 md:h-[80px] lg:h-[120px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              class="absolute bottom-0 left-0 top-0  z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-justify text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
              type="button"
              data-te-target="#carouselExampleIndicators1"
              data-te-slide="prev"
            >
              {/* <span class="inline-block h-8 w-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </span> */}
              <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Previous
              </span>
            </button>

            <button
              class="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-justify text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
              type="button"
              data-te-target="#carouselExampleIndicators1"
              data-te-slide="next"
            >
              {/* <span class="inline-block h-8 w-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </span> */}
              <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Next
              </span>
            </button>
          </div>
        </div>
      </div>

      {/* mobile  */}

      <div className="md:hidden block pb-10">
        <div className="">
          <div
            id="carouselExampleIndicators2"
            class="relative"
            data-te-carousel-init
            data-te-ride="carousel"
          >
            <div
              class="absolute bottom-0 left-0 right-0 z-[2] mx-[15%] mb-4 flex list-none justify-center p-0"
              data-te-carousel-indicators
            >
              <div
                type="button"
                data-te-target="#carouselExampleIndicators2"
                data-te-slide-to="0"
                data-te-carousel-active
                class="mx-[3px] box-content h-[10px] w-[10px] rounded-[50%] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-[#88A398] bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-current="true"
                aria-label="Slide 1"
              ></div>
              <div
                type="button"
                data-te-target="#carouselExampleIndicators2"
                data-te-slide-to="1"
                class="mx-[3px] box-content h-[10px] w-[10px] rounded-[50%] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-[#88A398] bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-label="Slide 2"
              ></div>
              <div
                type="button"
                data-te-target="#carouselExampleIndicators2"
                data-te-slide-to="2"
                class="mx-[3px] box-content h-[10px] w-[10px] rounded-[50%] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-[#88A398] bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-label="Slide 3"
              ></div>
            </div>

            <div class="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
              <div
                class="relative h-[90vh] float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item
                data-te-carousel-active
              >
                <div className="absolute top-0 w-full h-full ">
                  <div className="grid pb-5 h-[100px] content-center relative ">
                    <p className="font-serif text-[20px] justify-self-center mt-3 text-[#AF822E] font-semibold">
                      ADIHEX 2023
                    </p>
                    <img
                      src="./assets/abudhabi.png"
                      alt=""
                      className="justify-self-center mt-3 w-[240px]"
                    />

                    <img
                      src="./assets/foot-1.png"
                      alt=""
                      className="absolute left-[10px] h-10"
                    />
                    <img
                      src="./assets/foot-2.png"
                      alt=""
                      className="absolute left-[75px] top-[10px] h-4"
                    />
                    <img
                      src="./assets/foot-3.png"
                      alt=""
                      className="absolute right-[0px] top-2 self-center h-10"
                    />
                  </div>

                  <div className="bg-[#FFF3DD] h-[80vh] banner-arabic">
                    <div className="relative">
                      <div className="z-20  grid justify-items-center">
                        <img
                          src="./assets/exhibition-1.png"
                          alt=""
                          className="rounded-[50%] -top-10 border-[#AF822E] border-[20px] w-[200px] h-[200px] "
                        />
                      </div>
                      <img
                        src="./assets/foot-2.png"
                        alt=""
                        className="absolute left-[15px] top-[10px]"
                      />
                    </div>
                    <div className="px-[20px]">
                      <p className="text-[16px] text-[#857962] py-5">
                        تقام الدورة العشرون من معرض أبوظبي الدولي للصيد والفروسية في الفترة من 02 إلى 8 سبتمبر 2023 في مركز أبوظبي الوطني للمعارض (أدنيك)
                      </p>
                      <button className="bg-[#AF822E] text-white px-4 py-2 text-[16px] rounded-[10px]">
                        تفاصيل الفعالية
                      </button>
                      <img
                        src="./assets/foot-4.png"
                        alt=""
                        className="absolute right-[20px] bottom-[10px] h-20"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="relative h-[90vh] float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item
              >
                <div className="absolute top-0 w-full h-full ">
                  <div className="grid pb-5 h-[100px] content-center relative ">
                    <div className="grid justify-items-center justify-self-center text-[#88A398] font-semibold pl-5">
                      <p className="font-serif text-[18px] mt-5  ">
                        الطب البيطري
                      </p>
                      <p className="font-serif text-[20px]">
                        معارض في الإمارات
                      </p>
                    </div>
                    {/* <img
                      src="./assets/abudhabi.png"
                      alt=""
                      className="justify-self-center mt-3 w-[240px]"
                    /> */}

                    <img
                      src="./assets/2-foot-1.png"
                      alt=""
                      className="absolute left-[10px] h-10"
                    />
                    <img
                      src="./assets/2-foot-2.png"
                      alt=""
                      className="absolute left-[55px] top-[20px] h-4"
                    />
                    <img
                      src="./assets/3-foot-3.png"
                      alt=""
                      className="absolute right-[0px] top-2 self-center h-14"
                    />
                  </div>

                  <div className="bg-[#F0F6FA] h-[80vh] banner-arabic">
                    <div className="relative">
                      <div className="z-20  grid justify-items-center">
                        <div className="rounded-[50%] bg-[#E9A655] flip w-[200px] h-[200px] relative">
                          <img
                            src="./assets/home-falcon.png"
                            alt=""
                            className="absolute top-10 -right-4 h-[160px]"
                          />

                        </div>
                      </div>
                      <img
                        src="./assets/2-foot-2.png"
                        alt=""
                        className="absolute left-[15px] top-[10px]"
                      />
                    </div>
                    <div className="px-[20px]">
                      <p className="text-[16px] text-[#4B7765] py-5">
                        حدث جديد على غرار مهرجان الشرق الأوسط للأطباء البيطريين والمتخصصين في صحة الحيوان وأعمال رعاية الحيوانات الأليفة
                      </p>
                      <div className="flex justify-between md:w-[300px] lg:w-[400px] lg:pl-5 xl:pl-0">
                        <div className="text-[#88A398] text-[20px] font-[600]">
                          <p className="">2500+</p>
                          <p className="">الحضور</p>
                        </div>
                        <div className="text-[#88A398] text-[20px] font-[600]">
                          <p className="">120+</p>
                          <p className="">العارضون</p>
                        </div>
                      </div>
                      <img
                        src="./assets/4-foot-4.png"
                        alt=""
                        className="absolute right-[20px] bottom-[10px] h-20"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="relative   h-[90vh] float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item
              >
                <div className="absolute top-0 w-full h-full ">
                  <div className="grid pb-5 h-[100px] content-center relative ">
                    <div className="grid justify-items-center justify-self-center text-[#88A398] font-semibold pl-5">
                      <p className="font-serif text-[18px] mt-5  ">
                        الشرق الأوسط وأفريقيا
                      </p>
                      <p className="font-serif text-[20px]">
                        المؤتمر البيطري ، دبي - الإمارات العربية المتحدة
                      </p>
                    </div>
                    {/* <img
                      src="./assets/abudhabi.png"
                      alt=""
                      className="justify-self-center mt-3 w-[240px]"
                    /> */}

                    <img
                      src="./assets/2-foot-1.png"
                      alt=""
                      className="absolute left-[10px] h-10"
                    />
                    <img
                      src="./assets/2-foot-2.png"
                      alt=""
                      className="absolute left-[55px] top-[20px] h-4"
                    />
                    <img
                      src="./assets/3-foot-3.png"
                      alt=""
                      className="absolute right-[0px] top-2 self-center h-14"
                    />
                  </div>

                  <div className="bg-[#F0F6FA] h-[80vh] banner-arabic">
                    <div className="relative">
                      <div className="z-20  grid justify-items-center">
                        <div className="rounded-[50%] bg-[#88A398] w-[200px] h-[200px] relative">
                          <img
                            src="./assets/home-hen.png"
                            alt=""
                            className="absolute top-10 -right-4 h-[160px]"
                          />
                          <img
                            src="./assets/home-equipment.png"
                            alt=""
                            className="absolute top-[100px] flip left-3 h-[100px]"
                          />
                        </div>
                      </div>

                      <img
                        src="./assets/2-foot-2.png"
                        alt=""
                        className="absolute left-[15px] top-[10px]"
                      />
                    </div>
                    <div className="px-[20px]">
                      <p className="text-[16px] text-[#4B7765] py-5">
                        حدث جديد على غرار مهرجان الشرق الأوسط للأطباء البيطريين والمتخصصين في صحة الحيوان وأعمال رعاية الحيوانات الأليفة
                      </p>
                      <div className="flex justify-between md:w-[300px] lg:w-[400px] lg:pl-5 xl:pl-0">
                        <div className="text-[#88A398] text-[20px] font-[600]">
                          <p className="">2500+</p>
                          <p className="">الحضور</p>
                        </div>
                        <div className="text-[#88A398] text-[20px] font-[600]">
                          <p className="">120+</p>
                          <p className="">العارضون</p>
                        </div>
                      </div>
                      <img
                        src="./assets/4-foot-4.png"
                        alt=""
                        className="absolute right-[20px] bottom-[10px] h-20"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              class="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-justify text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
              type="button"
              data-te-target="#carouselExampleIndicators2"
              data-te-slide="prev"
            >
              {/* <span class="inline-block h-8 w-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </span> */}
              <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Previous
              </span>
            </button>

            <button
              class="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-justify text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
              type="button"
              data-te-target="#carouselExampleIndicators2"
              data-te-slide="next"
            >
              {/* <span class="inline-block h-8 w-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </span> */}
              <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Next
              </span>
            </button>
          </div>
        </div>
      </div>

      {/* part 4  products */}

      <div className="py-10 banner-arabic" id="product_arabic">
        <div className="w-full grid px-[20px] justify-items-center ">
          <p className="lg:text-[40px] xl:text-[56px] text-[26px] text-[#4E7A97]">
            منتجات فخورة <b>يجعلنا نتميز</b>
          </p>
          <p className="lg:text-[20px] text-[16px] text-[#4E7A97] mt-4">
            البحث باستمرار عن منتجات وابتكارات جديدة لتوفير رعاية أفضل لحيواناتك.
          </p>
        </div>

        <div className="grid grid-cols-1 justify-items-center lg:grid-cols-3 xl:gap-20 xl:px-[120px] px-[20px] lg:mt-20 mt-10">
          <div className="relative h-[420px]  grid justify-items-center lg:p-7 mb-5 lg:mb-0 rounded-[10px] bg-[#ffffff] drop-shadow-2xl shadow-2 lg:w-[300px] w-[280px]" data-aos="zoom-out-up" data-aos-duration="1000">
            <div className="px-5 lg:px-0">
              <img
                src="./assets/large.png"
                alt=""
                className="lg:w-[300px] w-[250px] h-[160px] rounded-[10px] mt-4 lg:mt-0"
              />
              <p className="lg:text-[22px] text-[18px] font-semibold py-4">
                الحيوانات الكبيرة
              </p>
              <p className="text-[#647794] text-start lg:text-[19px] text-[18px]">
                مجموعة من المنتجات تحافظ على حيوانك قويا ومنتجا.
              </p>
              <div className="flex mb-5 absolute bottom-0">
                <div className="flex hover:scale-110 transition-all duration-500 scale-100">
                  <Link to={'/large_arabic'}> <p className="text-[#88A398] font-bold">إقرأ المزيد</p></Link>
                  <div className="cursor-pointer flip">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.3338 16.572C11.5238 16.572 11.7138 16.502 11.8638 16.352L15.3938 12.822C15.6838 12.532 15.6838 12.052 15.3938 11.762L11.8638 8.232C11.5738 7.942 11.0938 7.942 10.8038 8.232C10.5138 8.522 10.5138 9.002 10.8038 9.292L13.8038 12.292L10.8038 15.292C10.5138 15.582 10.5138 16.062 10.8038 16.352C10.9438 16.502 11.1338 16.572 11.3338 16.572Z"
                        fill="#88A398"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative h-[420px] grid justify-items-center lg:p-7 mb-5 lg:mb-0 rounded-[10px] bg-[#ffffff] drop-shadow-2xl shadow-2 lg:w-[300px] w-[280px]" data-aos="zoom-out-up" data-aos-duration="1000">
            <div className="px-5 lg:px-0">
              <img
                src="./assets/poultry.png"
                alt=""
                className="lg:w-[300px] w-[250px] h-[160px] rounded-[10px] mt-4 lg:mt-0"
              />
              <p className="lg:text-[22px] text-[18px] font-semibold py-4">
                طب الدواجن
              </p>
              <p className="text-[#647794] text-start lg:text-[19px] text-[18px]">
                المنتجات التي تحتاجها الدواجن لصحة أفضل.
              </p>
              <div className="flex mb-5 absolute bottom-0">
                <div className="flex hover:scale-110 transition-all duration-500 scale-100">
                  <Link to={'/poultry_arabic'}>
                    <p className="text-[#88A398] font-bold">
                      إقرأ المزيد
                    </p>
                  </Link>
                  <div className="cursor-pointer flip">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.3338 16.572C11.5238 16.572 11.7138 16.502 11.8638 16.352L15.3938 12.822C15.6838 12.532 15.6838 12.052 15.3938 11.762L11.8638 8.232C11.5738 7.942 11.0938 7.942 10.8038 8.232C10.5138 8.522 10.5138 9.002 10.8038 9.292L13.8038 12.292L10.8038 15.292C10.5138 15.582 10.5138 16.062 10.8038 16.352C10.9438 16.502 11.1338 16.572 11.3338 16.572Z"
                        fill="#88A398"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative h-[420px] grid justify-items-center lg:p-7 mb-5 lg:mb-0 rounded-[10px] bg-[#ffffff] drop-shadow-2xl shadow-2 lg:w-[300px] w-[280px]" data-aos="zoom-out-up" data-aos-duration="1000">
            <div className="px-5 lg:px-0">
              <img
                src="./assets/falcon.png"
                alt=""
                className="lg:w-[300px] w-[250px] h-[160px] rounded-[10px] mt-4 lg:mt-0"
              />
              <p className="lg:text-[22px] text-[18px] font-semibold py-4">
                الصقور للطب
              </p>
              <p className="text-[#647794] text-start lg:text-[19px] text-[18px]">
                منتجات متخصصة لجميع الصقور سواء الصيد أو السباق أو التربية.
              </p>
              <div className="flex mb-5 absolute bottom-0">
                <div className="flex hover:scale-110 transition-all duration-500 scale-100">
                  <Link to={'/falcon_arabic'}>
                    <p href="" className="text-[#88A398] font-bold">
                      إقرأ المزيد
                    </p>
                  </Link>
                  <div className="cursor-pointer flip">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.3338 16.572C11.5238 16.572 11.7138 16.502 11.8638 16.352L15.3938 12.822C15.6838 12.532 15.6838 12.052 15.3938 11.762L11.8638 8.232C11.5738 7.942 11.0938 7.942 10.8038 8.232C10.5138 8.522 10.5138 9.002 10.8038 9.292L13.8038 12.292L10.8038 15.292C10.5138 15.582 10.5138 16.062 10.8038 16.352C10.9438 16.502 11.1338 16.572 11.3338 16.572Z"
                        fill="#88A398"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" grid grid-cols-1 justify-items-center lg:grid-cols-3 xl:gap-20 xl:px-[120px] px-[20px] lg:mt-20 mt-10">
          <div className="relative h-[420px] grid justify-items-center lg:p-7 mb-5 lg:mb-0 rounded-[10px] bg-[#ffffff] drop-shadow-2xl shadow-2 lg:w-[300px] w-[280px]" data-aos="zoom-out-up" data-aos-duration="1000">
            <div className="px-5 lg:px-0">
              <img
                src="./assets/disinfectant.png"
                alt=""
                className="lg:w-[300px] w-[250px] h-[160px] rounded-[10px] mt-4 lg:mt-0"
              />
              <p className="lg:text-[22px] text-[18px] font-semibold py-4">
                مطهر
              </p>
              <p className="text-[#647794] text-start lg:text-[19px] text-[18px]">
                البيئة الآمنة تؤدي إلى ارتفاع الإنتاج في نهاية المطاف الحفاظ على مستوى عال للصحة العامة لكل من الإنسان والحيوان.
              </p>
              <div className="flex mb-5 absolute bottom-0">
                <div className="flex hover:scale-110 transition-all duration-500 scale-100">
                  <Link to={'/dis_arabic'}>
                    <p className="text-[#88A398] font-bold">
                      إقرأ المزيد
                    </p>
                  </Link>
                  <div className="cursor-pointer flip">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.3338 16.572C11.5238 16.572 11.7138 16.502 11.8638 16.352L15.3938 12.822C15.6838 12.532 15.6838 12.052 15.3938 11.762L11.8638 8.232C11.5738 7.942 11.0938 7.942 10.8038 8.232C10.5138 8.522 10.5138 9.002 10.8038 9.292L13.8038 12.292L10.8038 15.292C10.5138 15.582 10.5138 16.062 10.8038 16.352C10.9438 16.502 11.1338 16.572 11.3338 16.572Z"
                        fill="#88A398"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative h-[420px] grid justify-items-center lg:p-7 mb-5 lg:mb-0 rounded-[10px] bg-[#ffffff] drop-shadow-2xl shadow-2 lg:w-[300px] w-[280px]" data-aos="zoom-out-up" data-aos-duration="1000">
            <div className="px-5 lg:px-0">
              <div className="bg-[#4E7A97] rounded-[10px]">
                <img
                  src="./assets/electric.png"
                  alt=""
                  className="lg:w-[300px] w-[250px] h-[160px] rounded-[10px] mt-4 lg:mt-0"
                />
              </div>
              <p className="lg:text-[22px] text-[18px] font-semibold py-4">
                المعدات الكهربائية
              </p>
              <p className="text-[#647794] text-start lg:text-[19px] text-[18px]">
                لدينا مجموعة واسعة من لوحات التحكم في المناخ لأجنحة الدواجن والبيوت المحمية والصوامع. لمزيد من المنتجات والاستفسارات يرجى الاتصال بنا.
              </p>
              <div className="flex mb-5 absolute bottom-0">
                <div className="flex hover:scale-110 transition-all duration-500 scale-100">
                  <Link to={'/electric_arabic'}>
                    <p className="text-[#88A398] font-bold">
                      إقرأ المزيد
                    </p>
                  </Link>

                  <div className="cursor-pointer flip">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.3338 16.572C11.5238 16.572 11.7138 16.502 11.8638 16.352L15.3938 12.822C15.6838 12.532 15.6838 12.052 15.3938 11.762L11.8638 8.232C11.5738 7.942 11.0938 7.942 10.8038 8.232C10.5138 8.522 10.5138 9.002 10.8038 9.292L13.8038 12.292L10.8038 15.292C10.5138 15.582 10.5138 16.062 10.8038 16.352C10.9438 16.502 11.1338 16.572 11.3338 16.572Z"
                        fill="#88A398"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative h-[420px] grid justify-items-center lg:p-7 mb-5 lg:mb-0 rounded-[10px] bg-[#ffffff] drop-shadow-2xl shadow-2 lg:w-[300px] w-[280px]" data-aos="zoom-out-up" data-aos-duration="1000">
            <div className="px-5 lg:px-0">
              <img
                src="./assets/equine.png"
                alt=""
                className="lg:w-[300px] w-[250px] h-[160px] rounded-[10px] mt-4 lg:mt-0"
              />
              <p className="lg:text-[22px] text-[18px] font-semibold py-4">
                فرسي
              </p>
              <p className="text-[#647794] text-start lg:text-[19px] text-[18px]">
                رذاذ الأنف مصمم للمساعدة في حماية مجرى الهواء العلوي للحصان من مسببات الحساسية الهوائية المهيجة
              </p>
              <div className="flex mb-5 absolute bottom-0">
                <div className="flex hover:scale-110 transition-all duration-500 scale-100">
                  <Link to={'/equine_arabic'}>
                    <p className="text-[#88A398] font-bold">
                      إقرأ المزيد
                    </p>
                  </Link>

                  <div className="cursor-pointer flip">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.3338 16.572C11.5238 16.572 11.7138 16.502 11.8638 16.352L15.3938 12.822C15.6838 12.532 15.6838 12.052 15.3938 11.762L11.8638 8.232C11.5738 7.942 11.0938 7.942 10.8038 8.232C10.5138 8.522 10.5138 9.002 10.8038 9.292L13.8038 12.292L10.8038 15.292C10.5138 15.582 10.5138 16.062 10.8038 16.352C10.9438 16.502 11.1338 16.572 11.3338 16.572Z"
                        fill="#88A398"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* part 5 numbers  */}

      <div className="mt-20 banner-arabic">
        <div className="w-full grid justify-items-center">
          <p className="lg:text-[36px] md:text-[28px] text-[24px] text-[#4E7A97] font-semibold">
            MEVZ في أرقام
          </p>
        </div>
        <div className="grid justify-items-center grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-10">
          <div className="">
            <p className="text-[56px] text-[#3B3F54] font-bold"> 24+</p>
            <p className="text-[32px] text-[#3B3F54]">اعوام </p>
          </div>
          <div className="">
            <p className="text-[56px] text-[#3B3F54] font-bold"> 800+</p>
            <p className="text-[32px] text-[#3B3F54]">العملاء </p>
          </div>
          <div className="">
            <p className="text-[56px] text-[#3B3F54] font-bold"> 100+</p>
            <p className="text-[32px] text-[#3B3F54]">منتجات </p>
          </div>
          <div className="">
            <p className="text-[56px] text-[#3B3F54] font-bold"> 200+</p>
            <p className="text-[32px] text-[#3B3F54]">الخبراء </p>
          </div>
        </div>
      </div>

      {/* video part  */}

      <div className="lg:flex mt-10 banner-arabic">
        <div className="lg:w-[50%]">
          <video autoPlay muted loop >
            <source src="./assets/vid.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="lg:w-[50%] bg-[#F0F6FA]">
          <div className="xl:pr-[60px] xl:pl-[160px] px-[20px] lg:px-0">
            <p className="md:text-[28px] text-[22px] text-[#4E7A97] font-semibold xl:mt-12 pt-4">
              رضا العملاء
            </p>
            <p className="xl:text-[20px] lg:text-[18px] text-[17px] xl:py-6 lg:py-4 py-3 text-[#4E7A97]">
              نحن نبحث باستمرار عن منتجات وابتكارات جديدة لتوفير رعاية أفضل لحيواناتك. نحن فخورون بأننا اكتسبنا سمعة كمورد موثوق به وموثوق للأدوية البيطرية.
            </p>
            <p className="xl:text-[20px] lg:text-[18px] text-[17px] text-[#4E7A97] pb-3 lg:pb-0">
              التزامنا برضا العملاء لا يتزعزع ، ونحن نسعى دائما لتجاوز التوقعات. نحن متحمسون لمواصلة خدمة عملائنا وبناء علاقات دائمة لسنوات عديدة قادمة
            </p>
            <Link to={"/all_arabic"}>
              <button className="bg-[#4E7A97] px-4 py-3 text-white xl:mt-5 mt-3 rounded-[7px] mb-3">
                اكتشف المنتجات
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* our partners  */}

      <div id="partners_arabic" className="py-10">
        <div className="grid justify-items-center w-full">
          <p className="text-[#4E7A97] text-[36px] ">شركاؤنا</p>
        </div>
        <div className="">
          <div
            id="carouselExampleIndicators12"
            class="relative"
            data-te-carousel-init
            data-te-ride="carousel"
          >
            <div
              class="absolute z-20 -bottom-5 left-0 right-0 mx-[15%] mb-4 flex list-none justify-center p-0"
              data-te-carousel-indicators
            >
              <div
                type="button"
                data-te-target="#carouselExampleIndicators12"
                data-te-slide-to="0"
                data-te-carousel-active
                class="mx-[3px] box-content h-[10px] w-[10px] rounded-[50%] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-[#88A398] bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-current="true"
                aria-label="Slide 1"
              ></div>
              <div
                type="button"
                data-te-target="#carouselExampleIndicators12"
                data-te-slide-to="1"
                class="mx-[3px] box-content h-[10px] w-[10px] rounded-[50%] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-[#88A398] bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-label="Slide 2"
              ></div>
              <div
                type="button"
                data-te-target="#carouselExampleIndicators12"
                data-te-slide-to="2"
                class="mx-[3px] box-content h-[10px] w-[10px] rounded-[50%] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-[#88A398] bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-label="Slide 3"
              ></div>
            </div>
            <div class="relative w-full lg:h-[200px] h-[260px] overflow-hidden after:clear-both after:block after:content-['']">
              <div
                class="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item
                data-te-carousel-active
              >
                <div className="grid xl:px-[120px] py-4 grid-cols-2 lg:grid-cols-4 px-5">
                  <img src="./assets/logo1.png" alt="" />
                  <img src="./assets/logo2.png" alt="" />
                  <img src="./assets/logo3.png" alt="" />
                  <img src="./assets/logo4.png" alt="" />
                </div>
              </div>

              <div
                class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item
              >
                <div className="grid xl:px-[120px] py-4 grid-cols-2 lg:grid-cols-4 px-5">
                  <img src="./assets/logo1.png" alt="" />
                  <img src="./assets/logo2.png" alt="" />
                  <img src="./assets/logo3.png" alt="" />
                  <img src="./assets/logo4.png" alt="" />
                </div>
              </div>

              <div
                class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item
              >
                <div className="grid xl:px-[120px] py-4 grid-cols-2 lg:grid-cols-4 px-5">
                  <img src="./assets/logo1.png" alt="" />
                  <img src="./assets/logo2.png" alt="" />
                  <img src="./assets/logo3.png" alt="" />
                  <img src="./assets/logo4.png" alt="" />
                </div>
              </div>
            </div>

            <button
              class="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-justify text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
              type="button"
              data-te-target="#carouselExampleIndicators12"
              data-te-slide="prev"
            >
              <span class="inline-block h-8 w-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </span>
              <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Previous
              </span>
            </button>

            <button
              class="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-justify text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
              type="button"
              data-te-target="#carouselExampleIndicators12"
              data-te-slide="next"
            >
              <span class="inline-block h-8 w-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </span>
              <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Next
              </span>
            </button>
          </div>
        </div>
      </div>

      {/* about  */}

      <div id="about_arabic" className="lg:flex justify-between xl:px-[120px]  mt-10 py-10 ">
        <div className="lg:w-[50%] xl:pr-10 lg:px-5 xl:px-0 px-[20px] banner-arabic">
          <p className="lg:text-[28px] font-semibold text-[22px] text-[#88A398]">
            شركة الشرق الأوسط للمستلزمات البيطرية
          </p>
          <p className="lg:py-3 lg:text-[22px] font-semibold text-[18px] text-[#88A398]">
            المستورد والموزع الرائد للأدوية البيطرية في دبي
          </p>

          <p className="lg:py-5 py-3 mt-5 text-[#7088A0] lg:text-[20px] md:text-[18px] text-[17px]">
            على مدى السنوات ال 20 الماضية ، كنا نقدم الأدوية البيطرية عالية الجودة لحيوانات المزرعة والدواجن والخيول والصقور لعملائنا الكرام في جميع أنحاء الشرق الأوسط.
          </p>
          <p className="lg:py-5 py-3 text-[#7088A0] lg:text-[20px] md:text-[18px] text-[17px]">
            فريقنا الشغوف من الخبراء مكرس لتقديم أعلى مستوى من الخدمة لعملائنا. نحن نفخر بالتزامنا بتقديم منتجات عالية الجودة تلبي الاحتياجات الفريدة لكل عميل من عملائنا.
          </p>
          <p className="lg:py-5 py-3 text-[#7088A0] lg:text-[20px] md:text-[18px] text-[17px]">
            فلسفتنا بسيطة: نحن نؤمن بأن الحيوانات السليمة تصنع عالما صحيا ، ولهذا السبب نحن ملتزمون بتوفير أفضل رعاية ممكنة لجميع الحيوانات. في شركة الشرق الأوسط للمستلزمات البيطرية ، نتفهم أهمية مواكبة أحدث الاتجاهات والتقنيات في الصناعة البيطرية
          </p>
        </div>
        <div className="lg:w-[70%] xl:w-[50%] grid items-center justify-items-center">
          {/* <img
            src="./assets/Group-about.png"
            alt=""
            className=" lg:justify-self-end px-[10px]"
          /> */}
          <div className="relative md:h-[520px] md:w-[600px] lg:w-[600px] xl:w-[600px] w-[300px] h-[300px] justify-self-center overflow-hidden" >
            <div className="absolute md:h-[200px] md:w-[200px] md:top-20 bg-[#E9A655] md:left-20 md:rounded-[35px] rounded-[20px] h-[110px] w-[110px] top-5 left-8" data-aos="fade-down-right" data-aos-duration="2000"></div>
            <div className="absolute bg-[#4E7A97] md:h-[150px] md:w-[150px] md:top-[230px] md:rounded-[35px] rounded-[20px] md:right-[140px] h-[100px] w-[100px] right-[40px] top-[90px]" data-aos="fade-left" data-aos-duration="2000"></div>
            <img src="./assets/about-hen.png" alt="" className="absolute md:top-20 md:right-20 md:h-[200px] md:w-[200px] w-[110px] h-[110px] top-5 right-0" data-aos="fade-down-left" data-aos-duration="2000" />
            <img src="./assets/about-goat.png" alt="" className="absolute md:top-[120px] md:h-[220px] md:w-[220px] h-[120px] w-[120px] top-[50px]" data-aos="fade-right" data-aos-duration="2000" />
            <img src="./assets/about-falcon.png" alt="" className="absolute md:left-[160px] md:top-[170px] md:h-[320px] md:w-[260px] h-[170px] w-[150px] top-[80px] left-[70px]" data-aos="fade-up" data-aos-duration="2000" />
          </div>
        </div>
      </div>

      {/* contact  */}

      <div
        id="contact_arabic"
        className="lg:flex justify-between xl:px-[120px] py-10 px-[20px] banner-arabic"
      >
        <div className="lg:w-[45%]">
          <div className="bg-[#4E7A97] rounded-[14px] h-[420px]  text-white lg:pr-[50px] pr-2">
            <p className="text-[22px]  font-semibold py-5 pt-10">
              اتصل بنا الآن
            </p>
            <div className="flex py-7">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1525_7968)">
                  <path
                    d="M16 16.0668C16.7555 16.0668 17.4 15.8001 17.9333 15.2668C18.4666 14.7335 18.7333 14.089 18.7333 13.3335C18.7333 12.5779 18.4666 11.9335 17.9333 11.4001C17.4 10.8668 16.7555 10.6001 16 10.6001C15.2444 10.6001 14.6 10.8668 14.0666 11.4001C13.5333 11.9335 13.2666 12.5779 13.2666 13.3335C13.2666 14.089 13.5333 14.7335 14.0666 15.2668C14.6 15.8001 15.2444 16.0668 16 16.0668ZM16 29.5668C12.3555 26.4779 9.6333 23.6055 7.8333 20.9495C6.0333 18.2944 5.1333 15.8446 5.1333 13.6001C5.1333 10.2001 6.22752 7.49435 8.41597 5.4828C10.6053 3.47213 13.1333 2.4668 16 2.4668C18.8666 2.4668 21.3946 3.47213 23.584 5.4828C25.7724 7.49435 26.8666 10.2001 26.8666 13.6001C26.8666 15.8446 25.9666 18.2944 24.1666 20.9495C22.3666 23.6055 19.6444 26.4779 16 29.5668Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1525_7968">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p className="mr-4">
                ألتي ، الخوانيج ، صندوق بريد 115937 دبي الإمارات العربية المتحدة.
              </p>
            </div>
            <div className="flex py-7">
              <svg
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.6095 14.3908C20.4857 14.2668 20.3388 14.1685 20.177 14.1014C20.0153 14.0344 19.8419 13.9998 19.6668 13.9998C19.4917 13.9998 19.3183 14.0344 19.1565 14.1014C18.9948 14.1685 18.8478 14.2668 18.7241 14.3908L16.5988 16.5161C15.6135 16.2228 13.7748 15.5561 12.6095 14.3908C11.4441 13.2254 10.7775 11.3868 10.4841 10.4014L12.6095 8.2761C12.7334 8.15239 12.8317 8.00545 12.8988 7.8437C12.9659 7.68194 13.0004 7.50855 13.0004 7.33344C13.0004 7.15833 12.9659 6.98493 12.8988 6.82318C12.8317 6.66143 12.7334 6.51449 12.6095 6.39077L7.27612 1.05744C7.1524 0.933511 7.00546 0.835194 6.84371 0.768113C6.68196 0.701032 6.50856 0.666504 6.33345 0.666504C6.15834 0.666504 5.98495 0.701032 5.8232 0.768113C5.66144 0.835194 5.5145 0.933511 5.39079 1.05744L1.77479 4.67344C1.26812 5.18011 0.982786 5.87611 0.993453 6.58677C1.02412 8.48544 1.52679 15.0801 6.72412 20.2774C11.9215 25.4748 18.5161 25.9761 20.4161 26.0081H20.4535C21.1575 26.0081 21.8228 25.7308 22.3268 25.2268L25.9428 21.6108C26.0667 21.4871 26.165 21.3401 26.2321 21.1784C26.2992 21.0166 26.3337 20.8432 26.3337 20.6681C26.3337 20.493 26.2992 20.3196 26.2321 20.1578C26.165 19.9961 26.0667 19.8492 25.9428 19.7254L20.6095 14.3908ZM20.4401 23.3401C18.7761 23.3121 13.0828 22.8654 8.60945 18.3908C4.12145 13.9028 3.68679 8.18944 3.66012 6.55877L6.33345 3.88544L9.78145 7.33344L8.05745 9.05744C7.90074 9.21404 7.78551 9.40722 7.72219 9.61953C7.65887 9.83184 7.64945 10.0566 7.69479 10.2734C7.72679 10.4268 8.50945 14.0628 10.7228 16.2761C12.9361 18.4894 16.5721 19.2721 16.7255 19.3041C16.9422 19.3507 17.1672 19.3421 17.3797 19.2789C17.5921 19.2158 17.7853 19.1002 17.9415 18.9428L19.6668 17.2188L23.1148 20.6668L20.4401 23.3401Z"
                  fill="white"
                />
              </svg>
              <a href="tel:+971 43922851"><p className="mr-4 ltr">+971 43922851</p></a>
            </div>
            <div className="flex py-7">
              <svg
                className="mt-3"
                width="28"
                height="22"
                viewBox="0 0 28 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.6665 0.333252H3.33317C1.8625 0.333252 0.666504 1.52925 0.666504 2.99992V18.9999C0.666504 20.4706 1.8625 21.6666 3.33317 21.6666H24.6665C26.1372 21.6666 27.3332 20.4706 27.3332 18.9999V2.99992C27.3332 1.52925 26.1372 0.333252 24.6665 0.333252ZM24.6665 2.99992V3.68125L13.9998 11.9786L3.33317 3.68259V2.99992H24.6665ZM3.33317 18.9999V7.05858L13.1812 14.7186C13.4146 14.902 13.7029 15.0017 13.9998 15.0017C14.2967 15.0017 14.585 14.902 14.8185 14.7186L24.6665 7.05858L24.6692 18.9999H3.33317Z"
                  fill="white"
                />
              </svg>
              <div className=" mr-4">
                <p className="">mvsco@emi.ae</p>
                <p className="">mvsco@emirates.net.ae</p>
              </div>
            </div>
          </div>
          <div className="lg:mt-10 mt-5 h-[215px]">
            <iframe
              title="1"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28872.267046291065!2d55.49174575569283!3d25.235800636324534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef58abad43ad5d9%3A0x52d0a28e5ff2a980!2sAl%20khawaneej%20Grocery!5e0!3m2!1sen!2sin!4v1695723807075!5m2!1sen!2sin"
              className="w-full h-[215px] rounded-[14px]"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        <div className="lg:w-[50%] bg-[#C5DED4] rounded-[14px] px-3 pb-3 lg:pb-0 lg:px-14 mt-10 lg:mt-0">
          <p className="text-[22px] font-bold pt-10 text-[#243548]">
            انخرط معنا!
          </p>
          {/* <form action="https://formsubmit.co/abdullaibunuhameed1@gmail.com" method="POST" className="mt-4">

            <input
              type="text"
              placeholder="اكتب اسمك" name="name"
              className="w-full h-10 pr-3 rounded-[7px]" required
            />


            <input
              type="mail" name="email"
              placeholder="اكتب بريدك الإلكتروني"
              className="w-full h-10 pr-3 rounded-[7px]" required
            />


            <input
              type="tel" name="number"
              placeholder="اكتب رقمك"
              className="w-full h-10 pr-3 rounded-[7px]" required
            />


            <textarea
              name="message"
              id=""
              placeholder="رسالة"
              className="w-full p-3 rounded-[7px]" required
              rows="4"
            ></textarea>

            <div className="w-full grid mt-4">
              <button className="relative justify-self-end bg-[#88A398] md:px-16 px-12 py-2 rounded-[7px] text-white hover:bg-[#477060] transition-all duration-500" type="submit">
                
              </button>
            </div>
          </form> */}


          {!isFormSubmitted ? (
            <form className="mt-2" onSubmit={handleSubmit}>
              <p className="text-[19px] py-4 text-[#243548]">اسم</p>
              <input

                type="text"

                value={formData.name}
                onChange={handleInputChange}
                required
                placeholder="اكتب اسمك" name="name"
                className="w-full h-10 pr-3 rounded-[7px]"
              />

              <p className="text-[19px] py-4 text-[#243548]">البريد الالكترونى</p>
              <input
                type="email"
                name="email"
                required
                value={formData.email}
                onChange={handleInputChange}
                placeholder="اكتب بريدك الإلكتروني"
                className="w-full h-10 pr-3 rounded-[7px]"
              />

              <p className="text-[19px] py-4 text-[#243548]">الهاتف</p>
              <input
                type="number"
                name="number"
                required
                value={formData.number}
                onChange={handleInputChange}
                placeholder="اكتب رقمك"
                className="w-full h-10 pr-3 rounded-[7px]"
              />

              <p className="text-[19px] py-4 text-[#243548]">رسالة</p>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}                
                required
                placeholder="رسالة"
                className="w-full p-3 rounded-[7px]"
                rows="4"
              ></textarea>
              <div className="">
                <ReCAPTCHA
                  sitekey="6Ldla8opAAAAAD4ouCqwtLUsSxL-0-EyZleLKJ1-"
                  onChange={handleRecaptchaChange}
                />
              </div>

              <div className="w-full grid my-4">
                <button
                  className="relative justify-self-end bg-[#88A398] md:px-16 px-12 py-2 rounded-[7px] text-white hover:bg-[#477060] transition-all duration-500"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "إرسال..." : "إرسال"}
                </button>
              </div>
            </form>
          ) : (
            <p className="text-green-500 mt-2">
              Form submitted successfully!
            </p>
          )}

        </div>
      </div>
      <div className="banner-arabic">
        <Footer_Arabic />
      </div>
    </>
  );
}

export default Arabic;

const data = {
    products : [
        {
            id:1,
            slug:'aa1',
            title:'APSA POLIVITESEL',
            berife:'Nutritional supplement based on vitamin E and selenium, indicated to improve the quality of meat, avoid encephalomalacia and white muscle disease and improve fertility in breeders.',
            viewmore:'View Products Details'           
        },
        {
            id:2,
            slug:'aa2',
            title:'APSA B COMPLEX + K3 + CHOLINE',
            berife:'Nutritional supplement based on B-group and K3 vitamins and choline, indicated for recovery of liver function in case of serious affectation.',
            viewmore:'View Products Details'
        },
        {
            id:3,
            slug:'aa3',
            title:'AMINOFIX',
            berife:'Nutritional supplement based on vitamin E and AminoFIX vet helps in protein formation. It acts as building blocking agent for the development of meat production.',
            viewmore:'View Products Details'
        },
        {
            id:4,
            slug:'aa4',
            title:'PVS Long Liv',
            berife:'Liquid Concentrate Unique Combination of herbal ingredients for use in Livestock and Poultry.',
            viewmore:'View Products Details'
        },
        {
            id:5,
            slug:'aa4',
            title:'Cal D Phos',
            berife:'Liquid calcium & phosphorus preparation for better animal health and productivity',
            viewmore:'View Products Details'
        },
    ]
}
export default data;
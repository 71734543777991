export const Disinfectant = [
    {
        id: 1,
        image: 'Deskol.png',
        title: 'DISKOL-ES',
        subtitle: 'New Generation Chemo - Gaseous Sterilant',
        subtitle2: '',
        c: [
            {
                l1: '· DISKOL-ES is a chemo-gaseous sterilizer with proven efficacy against all types of microorganisms including bacterial spores, viruses, fungi, Chlamydia, yeast and molds.',
                l2: '· It has a unique triple synergistic system which makes the product more powerful and reduces the contact time.',
                l3: '· It has unparalleled efficacy even in presence of organic matter and adverse conditions of water like hardness, pH and dissolved metal ions.',
                l4: '·  DISKOL-ES is fortified with a fourth generation quat (DDAC) and the pH of the product is adjusted to maintain the efficacy of all ingredients',
                l5: '',
            }
        ]

    },
    {
        id: 2,
        image: 'VOX.png',
        title: 'V-OX',
        subtitle: 'Ultimate Disinfectant',
        subtitle2: 'Ultimate Virucidal & Biocidal Disinfectan ',
        c: [
            {
                l1: '· V-OX is the ultimate broad spectrum disinfectant.',
                l2: '· It is a blend of triple salt of potassium monopersulphate (KMPS), sodium chloride,surfactant, organic acids and inorganic buffers.',
                l3: '· V-OX is recommended for a wide range of applications like livestock farm disinfection, surface decontamination and water sanitation.',
                l4: '· V-OX resists heavy organic soiling loads and high water hardness.',
                L5: '· An aqueous solution of V-OX releases a battery of 6 biocides that destroys the fungal, bacterial and viral pathogens.'
            }
        ]
    }



]
export const Large = [
    {
        id: 1,
        slug: 'aa1',
        image: 'asap.png',
        title: 'APSA POLIVITESEL',
        berife: 'Nutritional supplement based on vitamin E and selenium, indicated to improve the quality of meat, avoid encephalomalacia and white muscle disease and improve fertility in breeders.',
        viewmore: 'View Products Details',
        c1: 'Liquid calcium & phosphorus preparation for better animal health and productivity',
        h2: 'Indications:',
        c2: [
            {
                l1: 'Improves milk production to its maximum possible extent.'
            }
        ],
        h3:'Target species and dose:',
        subhead:'',
        c3:[
            {
                h1:' Poultry farming:',
                l1:' 1 mL/L of drinking water for 3 - 5 days.',
                h2:' Piglets: ',
                l2:'1 mL/L of drinking water for 5 days.',
                h3:'Breeding pigs and pigs for fattening:',
                l3:'0.5 mL/L of drinking water for 5 - 7 days. ',
                h4:'Cattle for fattening:',
                l4:'0.1 mL/L of drinking water for 5 days.',
                h5:' Sheep/goats:',
                l5:'1 mL/L of drinking water for 5 days.',
                h6:'',
                l6:''
            }
        ],
        h4:'Form and packaging:',
        c4:'Liquid. 1 L.'
    },
    {
        id: 2,
        slug: 'aa2',
        image: 'asap_b.png',
        title: 'APSA B COMPLEX + K3 + CHOLINE',
        berife: 'Nutritional supplement based on B-group and K3 vitamins and choline, indicated for recovery of liver function in case of serious affectation.',
        viewmore: 'View Products Details',
        c1: 'Liquid Concentrate Unique Combination of herbal ingrediants for use in Livestock and Poultry',
        h2: 'Indications:',
        c2: [
            {
                l1: 'Nutritional supplement based on B-group and K3 vitamins and choline, indicated for recovery of liver function in case of serious affectation..'
            }
        ],
        h3:'Target species and dose :',
        subhead:'',
        c3:[
            {
                h1:'Poultry farming, swine and rabbits: ',
                l1:'1 mL/L of drinking water for 5 - 7 days. ',
                h2:'Piglets and laying poultry: ',
                l2:' 2 mL/L of drinking water for 5 - 7 days.',
                h3:' Ruminants: ',
                l3:'1 - 2 mL/L of drinking water for 5 - 7 days',
                h4:'',
                l4:'',
                h5:'',
                l5:'',
                h6:'',
                l6:''
            }
        ],
        h4:'Form and packaging:',
        c4:'Liquid. 1 L.'
    },
    {
        id: 3,
        slug: 'aa3',
        image: 'aminofix.png',
        title: 'AMINOFIX',
        berife: 'AminoFIX vet helps in protein formation. It acts as building blocking agent for the development of meat production.',
        viewmore: 'View Products Details',
        c1: 'AminoFIX vet helps in protein formation. It acts as building blocking agent for the development of meat production. It also uses to increase egg & milk production. Supporting therapy on the operation & after operation, convalescing, dehydration, weakness, vomiting, diarrhea, imbalance of electrolytes, ketosis, anaphylaxis, acidosis and hypoproteinemia.',
        h2: '',
        c2: [
            {
                l1: ''
            }
        ],
        h3:'Dosage & Administration :',
        subhead:'',
        c3:[
            {
                h1:'Poultry :',
                l1:'Add 1ml per 1 litre water continue for 3-5 days. For Cattle, Buffalo, Horse, Goat, Sheep, Dog & other animals',
                h2:'Normal dose :',
                l2:'2-3 ml per 10 kg body weight in drinking water. ',
                h3:' Beef fattening dose : ',
                l3:'4-6 ml per 10kg body weight in drinking water.',
                h4:'Milk increaser dose : ',
                l4:' 4-6 ml per 10kg body weight in drinking water. Or as advised by the registered veterinarian.',
                h5:'',
                l5:'',
                h6:'',
                l6:''
            }
        ],
        h4:'PRESENTATION :',
        c4:'1 Ltr'
    },
    {
        id: 4,
        slug: 'aa4',
        image: 'pvs.png',
        title: 'PVS Long Liv',
        berife: 'Liquid Concentrate Unique Combination of herbal ingrediants for use in Livestock and Poultry.',
        viewmore: 'View Products Details',
        h2: 'BENEFITS :',
        c2: [
            {
                l1: '· Maintains optimum liver functions.',
                l2: '· Protects from aflatoxins and other toxins.',
                l3: '· Helps for better digestion and absorption of nutrients.',
                l4: '· Increases disease resistance, health, growth and body weights'
            }
        ],
        h3:'DOSAGE :',
        subhead:'LIVESTOCK : Through drinking water',
        c3:[
            {
                h1:'· Cattle, buffaloes, horses and camels :',
                l1:' 50 ml. twice daily',
                h2:'· Calves and foals :',
                l2:' 20-25 ml. twice daily.',
                h3:'· Sheep and Goats :',
                l3:'15-20 ml. twice daily Dosage may be doubled at the time of severe conditions (or) as advised by a veterinarian.',
                h4:'Usage Instructions :',
                l4:'(To be given preferably in morning water). To restore reduced feed intake and to stimulate and optimize affected liver functions.',
                h5:'',
                l5:'To improve feed conversion ratio, growth, production and livability. To counteract the damaging effects of aflatoxins. Use for 7 days or till condition persists',
                h6:'Use regularly :',
                l6:'Double the dose is recommended for 5 to 7 days.'
            }
        ],
        h4:'PRESENTATION :',
        c4:'1 Ltr'
    },
    {
        id: 5,
        slug: 'aasss4',
        image: 'cal_d.png',
        title: 'Cal D Phos',
        berife: 'Liquid calcium & phosphorus preparation for better animal health and productivity',
        viewmore: 'View Products Details',
        c1: '',
        h2: 'Benefits',
        c2: [
            {
                l1: 'Improves milk production to its maximum possible extent.',
                l2: 'Helps for better bone and skeletal strength.',
                l3: 'Prevents hypocalcemia and milk fever.',
                l4: 'Prevents complications like dystocia, retention of placenta, metritis, mastitis etc.',
                l5: 'Increases growth and body weights of calves.',
                l6: 'Improves health, vigor, appetite, bone strength and growth of dogs.'
            }
        ],
        h3:'DOSAGE :',
        subhead:'LIVESTOCK',
        c3:[
            {
                h1:'Cattle & Buffaloes / Horses :',
                l1:'100 ml./ day / 7-10 days in a month.',
                h2:'Calves :',
                l2:'40 ml./ day / 7 days in a month.',
                h3:'Dogs :',
                l3:'5 ml. / day.',
                h4:'Sheep & Goats :',
                l4:'10 ml. / day – 7 days in a month. (or) as advised by a veterinarian.',
                h5:'',
                l5:'',
                h6:'',
                l6:''
            }
        ],
        h4:'PRESENTATION :',
        c4:'500 ml., 1ltr. plastic container'
    },
    {
        id: 6,
        slug: 'aasss4',
        image: 'super_formula.png',
        title: 'Super Formula',
        berife: 'Mix of Vitamins, Minerals and Amino acids which are necessary for nutrition and normal growth leading to gain weight and help in fertility',
        viewmore: 'View Products Details',
        c1: 'Mix of Vitamins, Minerals and Amino acids which are necessary for nutrition and normal growth leading to gain weight and help in fertility',
        h2: 'Weight :',
        c2: [
            {
                l1: '10kg.',
            }
        ],
        h3:'',
        subhead:'',
        c3:[
            {
                h1:'',
                l1:'',
                h2:'',
                l2:'',
                h3:'',
                l3:'',
                h4:'',
                l4:'', 
                h5:'',
                l5:'',
                h6:'',
                l6:''
            }
        ],
        h4:'',
        c4:''

    },
    {
        id: 7,
        slug: 'aasss4',
        image: 'power_formula.png',
        title: 'Power Formula',
        berife: 'Mix of necessary Minerals, Vitamins and Probiotics to keep your animal healthy and immune.',
        viewmore: 'View Products Details',
        c1: 'Mix of necessary Minerals, Vitamins and Probiotics to keep your animal healthy and immune.',
        h2: 'Weight :',
        c2: [
            {
                l1: '10kg.',
            }
        ],
        h3:'',
        subhead:'',
        c3:[
            {
                h1:'',
                l1:'',
                h2:'',
                l2:'',
                h3:'',
                l3:'',
                h4:'',
                l4:'',
                h5:'',
                l5:'',
                h6:'',
                l6:''
            }
        ],
        h4:'',
        c4:''
    },
]